import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { HeaderProducts } from '../../../components/XcalaComponents';
import BodyDetailProduct from '../../../components/products/productsDetails/BodyDetailProduct';
import { useGetExchangeRate } from '../../../hooks/useGetExchangeRate';
import { useGetExchangeRateHistory } from '../../../hooks/useGetExchangeRateHistory';
import useGetPriceRange from '../../../hooks/useGetPriceRange';
import useGetSecurityDividends from '../../../hooks/useGetSecurityDividends';
import { AddCurrency } from '../../../utils/addCostEffectivemess';
import { getRiskWarningFunds } from '../../../utils/getRiskWarning';
import {
  getCurrentDateFormatYYYYMMDD,
  getFirstElement,
  numberWithPoint,
  setMonthsDateFormatYYMMDD,
} from '../../../utils/utils';

const ProductsDetails = () => {
  const [active, setActive] = useState(12);
  const [currency, setCurrency] = React.useState('dolares');
  const [chartData, setChartData] = React.useState([]);
  const navigate = useNavigate();
  const { securitySelected } = useSelector(state => state.simulation);
  const user = useSelector(state => state.auth.user);

  const custom = JSON.parse(securitySelected.Custom);
  const risk = custom.preferences.riesgo.description;
  const motivations = getFirstElement(custom.preferences.motivaciones);
  const interests = getFirstElement(custom.preferences.intereses);
  const motivationsId = custom.preferences.motivaciones[0].id;
  const interestsId = custom.preferences.intereses[0].id;
  const description = custom.fund_details.description_fund;
  const riskID = custom.preferences.riesgo;
  const showRiskWarning = getRiskWarningFunds(user['custom:profile'], riskID.id);

  const onlyNumber = custom.fund_details.min_investment_amount.replace(/[^\d]/g, '');

  const conditions = [];
  if (custom.fund_details.min_investment_amount) {
    conditions.push({
      data: 'Monto mínimo de inversión:',
      value: `$${numberWithPoint(Number.parseInt(onlyNumber))}`,
    });
  }
  if (custom.fund_details.Currency) {
    conditions.push({
      data: 'Moneda:',
      value: custom.fund_details.Currency,
    });
  }
  if (custom.fund_details.exit_fee) {
    conditions.push({
      data: 'Plazo de aporte:',
      value: custom.fund_details.exit_fee,
    });
  }
  if (custom.fund_details.dividend_payment) {
    conditions.push({
      data: 'Paga dividendos:',
      value: custom.fund_details.dividend_payment,
    });
  }
  if (custom.fund_details.plazo_rescates) {
    conditions.push({
      data: 'Plazo de rescate:',
      value: `${custom.fund_details.plazo_rescates}`,
    });
  }

  const documents = [];

  const documentTypes = {
    Fact_Sheet: 'Brochure',
    Reglamento: 'Reglamento',
    Folleto_Regulatorio: 'Folleto Informativo',
  };

  if (custom.documents) {
    for (const [key, value] of Object.entries(custom.documents)) {
      const documentType = documentTypes[key];
      if (documentType) {
        documents.push({
          data: documentType,
          value,
        });
      }
    }
  }

  const [
    doGetPriceRange,
    { data: dataPriceRange, error: errorGetPriceRange, loading: loadingGetPriceRange },
  ] = useGetPriceRange();
  const successGetPriceRange = dataPriceRange?.getPriceRange || [];
  const [doGetSecurityDividends, { data: dataSecurityDividends }] = useGetSecurityDividends();
  const successGetSecurityDividends = dataSecurityDividends?.getSecurityDividends;

  const { data: dataGetExchangeRate, loading: loadingGetExchangeRate } = useGetExchangeRate();
  const { data: dataGetExchangeRateHistory, loading: loadingGetExchangeRateHistory } =
    useGetExchangeRateHistory({
      rowEnd: 365,
    });

  const successGetExchangeRateHistory = dataGetExchangeRateHistory?.getExchangeRateHistory;

  const onChangeMonths = months => {
    setActive(months);
    doGetPriceRange({
      variables: {
        data: {
          securityId: securitySelected.ID,
          from: setMonthsDateFormatYYMMDD(-months),
          to: getCurrentDateFormatYYYYMMDD(),
        },
      },
    });
  };

  const updateChartData = (priceData, exchangeRates) => {
    const convertedData = priceData?.map(priceItem => {
      const matchingRate = exchangeRates?.find(
        rateItem => rateItem?.EffectiveDate === priceItem?.EffectiveDate,
      );

      // si encontramos una tasa de cambio para esa fecha, dividimos el closePrice por esa tasa
      const updatedClosePrice = matchingRate
        ? priceItem.ClosePrice / matchingRate.Rate
        : priceItem.ClosePrice; // Si no hay tasa de cambio, dejamos el closePrice sin cambios

      return {
        ...priceItem,
        ClosePrice: updatedClosePrice,
      };
    });
    setChartData(convertedData);
  };

  const handleInvestment = id => {
    navigate('/simulator');
  };

  const handleSimulation = id => {
    navigate('/simulator');
  };

  const fileAs = {
    currency: AddCurrency(securitySelected.Name),
    fileAs: 'FileAsName',
    fileAsComplete: securitySelected.Name.slice(4),
    risk: risk,
    motivation: motivations.split('-')[0],
    motivationId: motivationsId,
    interests: interests,
    interestsId: interestsId,
    products: true,
    profit: securitySelected.profit,
  };

  return (
    <>
      <HeaderProducts
        handleInvestMore={handleInvestment}
        handleRescueInvestment={handleSimulation}
        fileAs={fileAs}
        simulation='Simular'
        investmentMore='Invertir'
      />

      <BodyDetailProduct
        doGetSecurityDividends={doGetSecurityDividends}
        securitySelected={securitySelected}
        description={description}
        conditions={conditions}
        onChangeMonths={onChangeMonths}
        active={active}
        loadingGetPriceRange={loadingGetPriceRange}
        loadingGetExchangeRate={loadingGetExchangeRate}
        errorGetPriceRange={errorGetPriceRange}
        currency={currency}
        custom={custom}
        chartData={chartData}
        dividends={successGetSecurityDividends}
        showLabels={true}
        successGetPriceRange={successGetPriceRange}
        setCurrency={setCurrency}
        updateChartData={updateChartData}
        documents={documents}
        showRiskWarning={showRiskWarning}
        handleInvestment={handleInvestment}
        handleSimulation={handleSimulation}
        successGetExchangeRateHistory={successGetExchangeRateHistory}
        loadingGetExchangeRateHistory={loadingGetExchangeRateHistory}
      />
    </>
  );
};

export default ProductsDetails;
