import { Box, Typography } from '@mui/material';
import React from 'react';

export class ErrorBoundaryXcala extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    /* logErrorToMyService(error, errorInfo); */
    // biome-ignore lint/nursery/noConsole: <explanation>
    console.log({ error, errorInfo });
  }
  render() {
    if (this.state.hasError) {
      return (
        <Box
          display='flex'
          alignItems='center'
          justifyContent='center'
          width='100%'
          height='100%'
          color='text.primary'
          sx={{
            background: '#fff',
            padding: 2,
            textAlign: 'center',
            overflow: 'hidden',
            svg: {
              width: '50%',
              height: 'auto',
              maxWidth: '300px',
              maxHeight: '200px',
            },
          }}>
          <Typography variant='body1' sx={{ lineHeight: '100%' }}>
            Módulo en mantenimiento
          </Typography>
        </Box>
      );
    }
    // Si no hay hijos, renderiza algo por defecto o null
    return this.props.children || null;
  }
}
