import { useEffect } from 'react';
import {
  BoxMenu,
  BoxMenuItem,
  TypographyMenuItem,
  TypographyMenuItemAmount,
} from '../Layouts/StyledComponentsMenu';
import { MenuItem, Stack } from '@mui/material';
import Spinner from '../../../Spinner';
import { useGetCashBalancePortfolio } from '../../../../hooks/useGetCashBalancePortfolio';
import { FormatCurrency } from '../../../../utils/numbers';
import { useSelector } from 'react-redux';

export const WalletClosedDrawer = ({ menuItem, handleClose, handleNavigateWallet }) => {
  const { user } = useSelector(state => state.auth);

  const {
    data: balanceData,
    loading: loadingBalance,
    error: errorBalance,
    refetch: refetchBalance,
  } = useGetCashBalancePortfolio();

  const cashBalance = balanceData?.getCashBalancePortfolio;

  const walletAvailability = item => {
    if (item === 'Caja Disponible') {
      return cashBalance?.balance ? FormatCurrency(cashBalance?.balance) : '$0';
    }
    if (item === 'Dinero en Tránsito') {
      return cashBalance?.transitAmount ? FormatCurrency(cashBalance?.transitAmount) : '$0';
    }
  };

  useEffect(() => {
    if (user) {
      refetchBalance();
    }
  }, [user, refetchBalance]);

  if (loadingBalance) {
    return <Spinner />;
  }

  if (errorBalance) {
    throw new Error('Error component wallet drawer closed');
  }

  return (
    <BoxMenu sx={{ marginTop: '0px', padding: '0px' }}>
      {menuItem.subMenu
        .filter(
          subItem =>
            subItem.btnNameSub === 'Caja Disponible' || subItem.btnNameSub === 'Dinero en Tránsito',
        )
        .map(subItem => (
          <BoxMenuItem key={subItem.btnNameSub}>
            <MenuItem
              onClick={() => {
                handleNavigateWallet();
                handleClose();
              }}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
              }}>
              <Stack>
                <TypographyMenuItem marginLeft='0px'>{subItem.btnNameSub}</TypographyMenuItem>
                <TypographyMenuItemAmount>
                  {walletAvailability(subItem.btnNameSub)}
                </TypographyMenuItemAmount>
              </Stack>
            </MenuItem>
          </BoxMenuItem>
        ))}
    </BoxMenu>
  );
};
