import React from 'react';
import { Stack, Typography, styled } from '@mui/material';
import { ButtonsPrimary } from '../ComponentApp/buttoms/ButtonsXcala';

const SectionNoProduct = styled(Stack)({
  backgroundColor: 'rgba(255, 255, 255, 1)',
  boxShadow: '0px 0px 6.828890800476074px rgba(0, 0, 0, 0.12)',
  borderRadius: '4px',
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '2px 11.706669807434082px',
  boxSizing: 'border-box',
  alignSelf: 'stretch',
  flex: '1',
  margin: '0px',
  height: '100%',
  width: '100%',
  minHeight: '218px',
});

const ContentNoProduct = styled(Stack)({
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  padding: '0px',
  boxSizing: 'border-box',
  margin: '0px',
  height: '120px',
  width: '100%',
});
const IconsNoProduct = styled('img')({
  height: '120px',
  width: '140px',
  objectFit: 'cover',
  margin: '0px',
});
const BodyTextNoProduct = styled(Stack)({
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flex-start',
  padding: '0px',
  boxSizing: 'border-box',
  margin: '0px 0px 0px 0px',
  width: '100%',
  overflow: 'hidden',
});

const TitleNoProduct = styled(Typography)(({ theme }) => ({
  textAlign: 'left',
  whiteSpace: 'pre-wrap',
  fontSynthesis: 'none',
  color: theme.palette.primary.dark,
  fontWeight: theme.typography.h4.fontWeight,
  fontSize: theme.typography.title.fontSize,
  letterSpacing: '-0.39022237062454224px',
  textDecoration: 'none',
  lineHeight: '22px',
  textTransform: 'none',
  margin: '0px',
}));

const ParagraphNoProduct = styled(Typography)(({ theme }) => ({
  textAlign: 'left',
  whiteSpace: 'pre-wrap',
  fontSynthesis: 'none',
  color: theme.palette.textLight.main,
  fontWeight: theme.typography.titleText.fontWeight,
  fontSize: theme.typography.button.fontSize,
  letterSpacing: '-0.39022237062454224px',
  textDecoration: 'none',
  lineHeight: '12.962230682373047px',
  textTransform: 'none',
  margin: '7.804447174072266px 0px 0px 0px',
}));

const SectionNoMarketValue = ({ handleRedirect, tap }) => {
  return (
    <SectionNoProduct>
      <ContentNoProduct>
        <IconsNoProduct src='/assets/images/dashboard/noProducts.gif' alt={'Cargando Product'} />
        <BodyTextNoProduct>
          <Stack>
            <TitleNoProduct>Invierte como los que saben</TitleNoProduct>
          </Stack>
          <Stack sx={{ mt: '12px', mb: '22px' }}>
            <ParagraphNoProduct>
              En Xcala, los activos alternativos son para todos
            </ParagraphNoProduct>
          </Stack>
          <ButtonsPrimary
            sx={{ whiteSpace: 'nowrap' }}
            name={`Visita nuestros ${tap === 'fondos' ? 'Fondos' : 'Portfolios'}`}
            onClick={() => handleRedirect()}
            fullWidth
          />
        </BodyTextNoProduct>
      </ContentNoProduct>
    </SectionNoProduct>
  );
};

export default SectionNoMarketValue;
