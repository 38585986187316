import {
  Box,
  Container,
  InputAdornment,
  MenuItem,
  Select,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { ButtonsPrimary, ButtonsSecondary } from '../ComponentApp/buttoms/index.js';
import Spinner from '../Spinner';
import ChartDoughnutXcala from '../XcalaComponents/ChartDoughnutXcala';
import { ContributionsSectionTitle } from './SimulationComponents/ContributionsSectionTitle.js';
import { ScenarySection } from './SimulationComponents/ScenarySection';
import {
  BodyContributions,
  ContentBody,
  MainBody,
  TextStyled,
} from './SimulationComponents/SimulationMuiComponents.js';
import TextInput from './SimulationComponents/TextInput.js';
import { TitleResultSimulator } from './SimulationComponents/TitleResultSimulator.js';

const SimulatorResult = ({
  isSimulatorFund,
  formData,
  isLoadingSimulation,
  dataResultSimulation,
  handleInvesment,
  errorSimulation,
  dataChart,
  riskPortfolio,
  handleSubmit,
}) => {
  const navigate = useNavigate();
  const targetRoute = isSimulatorFund ? '/products' : '/portfolio';
  const handleNavigate = targetRoute => {
    navigate(targetRoute);
  };
  const theme = useTheme();
  let resultChartData;
  if (isSimulatorFund) {
    resultChartData = { labels: '', datasets: '' };
  } else {
    resultChartData = dataChart.dataChart;
  }

  const { labels, datasets } = resultChartData;

  const initialValues = {
    initialInvestment: '' || formData.initialInvestment,
    monthlyInvestment: '' || formData.monthlyInvestment,
    months: '' || formData.months,
  };

  const validationSchema = Yup.object({
    initialInvestment: Yup.string().required('El campo es requerido'),
    monthlyInvestment: Yup.string(),
    months: Yup.string().required('El campo es requerido'),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    validate: values => {
      const errors = {};
      if (isSimulatorFund) {
        if (
          !values.initialInvestment ||
          Number.parseInt(values.initialInvestment.replace(/\D/g, '')) < 10000 ||
          values.initialInvestment[0] === '0'
        ) {
          errors.initialInvestment = 'El monto debe ser mayor a $10.000';
        }
        if (Number.parseInt(values.monthlyInvestment?.replace(/\D/g, '')) < 10000) {
          errors.monthlyInvestment = 'El aporte mensual debe ser mayor a $10.000';
        }
      } else if (
        !values.initialInvestment ||
        Number.parseInt(values.initialInvestment.replace(/\D/g, '')) < 200000 ||
        values.initialInvestment[0] === '0'
      ) {
        errors.initialInvestment = 'El monto debe ser mayor a $200.000';
      }
      if (
        !isSimulatorFund &&
        Number.parseInt(values.monthlyInvestment?.replace(/\D/g, '')) < 200000
      ) {
        errors.monthlyInvestment = 'El aporte mensual debe ser mayor a $200.000';
      }

      return errors;
    },
  });

  const optionsYears = [
    isSimulatorFund ? { value: '6 meses' } : undefined,
    { value: '1 año' },
    isSimulatorFund ? { value: '2 años' } : undefined,
    { value: '3 años' },
    isSimulatorFund ? undefined : { value: '5 años' },
  ];

  let orderScenarios = ['ESCENARIO OPTIMISTA', 'ESCENARIO NORMAL', 'ESCENARIO PESIMISTA'];

  const titleColor = {
    'ESCENARIO OPTIMISTA': 'rgba(52, 120, 86, 1)',
    'ESCENARIO NORMAL': '#1E22AA',
    'ESCENARIO PESIMISTA': '#CD7E07',
  };

  const borderEscenari = {
    'ESCENARIO OPTIMISTA': 'rgba(90, 196, 177, 1)',
    'ESCENARIO NORMAL': 'rgba(150, 189, 228, 1)',
    'ESCENARIO PESIMISTA': 'rgba(247, 219, 82, 1)',
  };
  const bgColorEscenari = {
    'ESCENARIO OPTIMISTA': 'rgba(90, 196, 177, 0.1)',
    'ESCENARIO NORMAL': 'rgba(150, 189, 228, 0.1)',
    'ESCENARIO PESIMISTA': 'rgba(247, 219, 82, 0.1)',
  };

  const yearsToMonths = {
    '6 meses': 6,
    '1 año': 12,
    '2 años': 24,
    '3 años': 36,
    '5 años': 60,
  };
  if (isLoadingSimulation) {
    return (
      <>
        <Spinner />;
      </>
    );
  }

  if (errorSimulation) {
    return <></>;
  }

  const investmentForMonths = formData.monthlyInvestment;
  const monthsSelected = yearsToMonths[formData.months];
  const monthlyInvestment =
    typeof investmentForMonths === 'string' && investmentForMonths !== ''
      ? Number.parseInt(formData?.monthlyInvestment?.replace(/\./g, '')) * (monthsSelected - 1)
      : 0;

  const fullInvestment =
    Number.parseInt(formData.initialInvestment?.replace(/\./g, '')) + monthlyInvestment;

  return (
    <>
      <BodyContributions sx={{ marginBottom: '32px' }}>
        <MainBody>
          <ContributionsSectionTitle
            textTitle={
              isSimulatorFund
                ? 'Simula tu inversión en el fondo'
                : ' Simula tu inversión en el portfolio'
            }
          />

          <Container
            sx={{
              paddingY: '32px',
              padding: '20px 0',
              borderRadius: '16px',
              boxShadow: '0px 0px 8px rgba(61, 64, 75, 0.15)',
            }}>
            <Stack marginX={{ md: '2px', xs: '14px' }}>
              {!isSimulatorFund && <ChartDoughnutXcala dataChart={{ labels, datasets }} />}

              <TextInput
                name='initialInvestment'
                label='Inversión Inicial'
                helperText={formik.touched.initialInvestment && formik.errors.initialInvestment}
                InputProps={{
                  startAdornment: <InputAdornment position='start'>$</InputAdornment>,
                }}
                error={Boolean(formik.touched.initialInvestment && formik.errors.initialInvestment)}
                value={formik.values.initialInvestment}
                formik={formik}
              />

              <TextStyled
                sx={{
                  width: '100%',
                }}>
                {isSimulatorFund
                  ? 'Inversión mínima del fondo es de $10.000'
                  : 'Inversión mínima del portfolio es de $200.000'}
              </TextStyled>

              <TextInput
                name='monthlyInvestment'
                label='Aporte Mensual (Opcional)'
                helperText={formik.touched.monthlyInvestment && formik.errors.monthlyInvestment}
                error={Boolean(formik.touched.monthlyInvestment && formik.errors.monthlyInvestment)}
                value={formik.values.monthlyInvestment}
                formik={formik}
              />

              <Select
                sx={{
                  mt: '16px',
                  width: '100%',
                  fontSize: theme.typography.availableBalance.fontSize,
                  color: theme.palette.textLight.main,
                }}
                id='months'
                value={formik.values.months}
                variant='standard'
                onChange={e => {
                  formik.setFieldValue('months', e.target.value);
                }}>
                {optionsYears
                  ?.filter(item => item !== undefined)
                  .map(item => {
                    return (
                      <MenuItem key={item.value} value={item.value}>
                        {item.value}
                      </MenuItem>
                    );
                  })}
              </Select>

              {formik.touched.months && formik.errors.months ? (
                <Box sx={{ color: theme.palette.red.main, ml: 1 }}>{formik.errors.months}</Box>
              ) : null}

              <ButtonsPrimary
                sx={{
                  marginTop: '16px',
                  width: '100%',
                  fontSize: '17px',
                }}
                name='Calcular'
                disableElevation
                disabled={!(formik.dirty && formik.isValid)}
                onClick={() => handleSubmit(formik.values, riskPortfolio)}
                fullWidth
              />
            </Stack>
          </Container>
        </MainBody>

        <ContentBody>
          <Container sx={{ display: 'flex', flexDirection: 'column' }}>
            <TitleResultSimulator fullInvesment={fullInvestment} formData={formData} />
            {dataResultSimulation
              ?.sort(
                (a, b) =>
                  orderScenarios.indexOf(a.simulationType) -
                  orderScenarios.indexOf(b.simulationType),
              )
              ?.map(item => {
                return (
                  <ScenarySection
                    key={item.simulationType}
                    escenario={item.simulationType}
                    bgColor={bgColorEscenari[item.simulationType]}
                    lineColor={borderEscenari[item.simulationType]}
                    textColor={titleColor[item.simulationType]}
                    formData={formData}
                    amount={item.amount}
                    fullInvesment={fullInvestment}
                    profit={item.profit}
                  />
                );
              })}
          </Container>

          <Container sx={{ display: 'flex', flexDirection: 'column', gap: '32px' }}>
            <Stack>
              <Box
                sx={{
                  display: 'flex',
                  alignSelf: 'start',
                  alignItems: 'center',
                }}>
                <Box sx={{ mr: '10px' }}>
                  <img
                    width={'24px'}
                    height={'24px'}
                    src={'/assets/images/warning.svg'}
                    alt='warning'
                  />
                </Box>
                <Typography
                  sx={{
                    color: theme.palette.red.main,
                    fontWeight: theme.typography.button.fontWeight,
                    fontSize: theme.typography.customButton.fontSize,
                  }}>
                  Importante
                </Typography>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  ml: 2.5,
                  mt: 3,
                }}>
                <Typography
                  sx={{
                    color: theme.palette.primary.darkBlue,
                    fontWeight: theme.typography.littleText.fontWeight,
                    fontSize: theme.typography.smallText.fontSize,
                    padding: 0,
                  }}>
                  El resultado de esta simulación corresponde a escenarios proyectados y no
                  representan un compromiso de rentabilidad por parte de Xcala.
                </Typography>
                <Typography
                  sx={{
                    color: theme.palette.primary.darkBlue,
                    fontWeight: theme.typography.littleText.fontWeight,
                    fontSize: theme.typography.smallText.fontSize,
                    padding: 0,
                  }}>
                  Rentabilidades pasadas no garantizan rentabilidades futuras. El valor y
                  rendimiento de sus inversiones pueden tanto bajar como subir.
                </Typography>
              </Box>
            </Stack>
            <Stack gap={{ md: 5, xs: 3 }} flexDirection={{ md: 'row', xs: 'column' }}>
              <ButtonsSecondary
                name={`Ver otros ${isSimulatorFund ? 'fondos' : 'portfolios'}`}
                fullWidth
                onClick={() => handleNavigate(targetRoute)}
                sx={{ whiteSpace: 'nowrap' }}
              />
              <ButtonsPrimary name='Invertir' onClick={handleInvesment} fullWidth />
            </Stack>
          </Container>
        </ContentBody>
      </BodyContributions>
    </>
  );
};

export default SimulatorResult;
