import { Stack, Typography, styled } from '@mui/material';
import { useSelector } from 'react-redux';
import { handleRiskProfile, handleRiskProfilePortfolio } from '../../utils/riskProfile';
import {
  ComponentRescateCont,
  ContentAvatarRescue,
  IconosPerfiles,
  RescueAvatarDescript,
  RescueAvatarIcon,
  RescueAvatarPorfilio,
  RescueAvatarTitle,
  RescueIconsPorfolio,
  RescueMunt,
} from '../portfolio/rescuePortfolio/RescueComponent';
import { InterestLabel } from '../products/newProducts/InterestLabel';
import { MotivationLabel } from '../products/newProducts/MotivationLabel';

const HmcText = styled(Typography)(({ theme }) => ({
  textAlign: 'left',
  whiteSpace: 'pre-wrap',
  fontSynthesis: 'none',
  color: theme.palette.primary.main,
  fontWeight: theme.typography.button.fontWeight,
  fontSize: theme.typography.littleText.fontSize,
  letterSpacing: '0px',
  textDecoration: 'none',
  textTransform: 'none',
  margin: '0px',
}));
const CurrencyInvestment = styled(Stack)({
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'row',
  width: '100%',
  justifyContent: 'flex-start',
  alignItems: 'center',
  padding: '2px 0px',
  boxSizing: 'border-box',
});
const CurrencyInvestmentContent = styled(Stack)({
  borderRadius: '8px',
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  padding: '2px 4px',
  boxSizing: 'border-box',
  margin: '0px',
  gap: '4px',
});
const IconInvesment = styled('img')({
  height: '18px',
  width: '18px',
  margin: '0px',
});
const InversionesEnPesos = styled(Typography)(({ theme }) => ({
  textAlign: 'left',
  whiteSpace: 'pre-wrap',
  fontSynthesis: 'none',
  color: theme.palette.primary.textLightBlue,
  fontWeight: theme.typography.button.fontWeight,
  fontSize: theme.typography.button.fontSize,
  letterSpacing: '0px',
  textDecoration: 'none',
  lineHeight: '119.49999332427979%',
  textTransform: 'none',
  margin: '0px 0px 0px 3px',
}));
const StarContainer = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.text,
  borderRadius: '17px',
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '0px 7px',
  boxSizing: 'border-box',
  height: '22px',
  width: '36px',
}));

const StarIcons = styled('img')({
  height: '16px',
  width: '16px',
  margin: '0px',
});
const HeadChips = styled(Stack)({
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'row',
  flexWrap: 'wrap',
  gap: '8px',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  padding: '0px',
  boxSizing: 'border-box',
  margin: '0px',
  width: '100%',
});
const GeneralMotivations = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.tertiary.main,
  borderRadius: '14px',
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '2.5px 8px',
  boxSizing: 'border-box',
  height: '22px',
  margin: '0px',
  width: '42px',
}));

export const AvatarInvertedPortfolio = ({ porfolioDetailHeader, goToProductDetail, details }) => {
  const user = useSelector(state => state.auth.user);
  const userRisk = user?.['custom:profile'] ? user['custom:profile'].toLowerCase() : '';

  const fundPerfile = porfolioDetailHeader.risk.toLowerCase();
  return (
    <>
      <ComponentRescateCont>
        <ContentAvatarRescue flexDirection={{ md: 'row', xs: 'column' }}>
          <RescueAvatarIcon>
            <RescueIconsPorfolio>
              {porfolioDetailHeader.products === true ? (
                <IconosPerfiles src={handleRiskProfile(fundPerfile)} />
              ) : (
                <>
                  <IconosPerfiles src={handleRiskProfilePortfolio(fundPerfile)} />

                  <RescueAvatarPorfilio src={handleRiskProfile(fundPerfile)} />
                </>
              )}
            </RescueIconsPorfolio>
          </RescueAvatarIcon>
          <RescueAvatarDescript alignItems={{ md: 'flex-start', xs: 'center' }} gap={1}>
            <RescueAvatarTitle
              textAlign={{ md: 'left', xs: 'center' }}
              mt={{ md: '0px', xs: '16px' }}
              onClick={() => `${details ? goToProductDetail() : null}`}
              sx={{ ':hover': { cursor: `${details ? 'pointer' : ''}` } }}>
              {porfolioDetailHeader.fileAsComplete}
            </RescueAvatarTitle>

            <HeadChips
              sx={{
                justifyContent: {
                  md: 'start',
                  xs: 'center',
                  flexDirection: { md: 'row', sx: 'column' },
                },
              }}>
              <GeneralMotivations>
                <HmcText>HMC</HmcText>
              </GeneralMotivations>

              {fundPerfile && fundPerfile.toLowerCase() === userRisk && (
                <StarContainer>
                  <StarIcons src='/assets/images/start-icons.svg' alt='start-icons' />
                </StarContainer>
              )}

              {porfolioDetailHeader.interests && (
                <Stack
                  direction={'row'}
                  gap={1}
                  sx={{ flexWrap: 'wrap', justifyContent: 'center' }}>
                  <InterestLabel
                    intereses={porfolioDetailHeader.interests}
                    interestsId={porfolioDetailHeader.interestsId}
                  />
                  <MotivationLabel
                    motivation={porfolioDetailHeader.motivation}
                    motivationsId={porfolioDetailHeader.motivationId}
                  />
                </Stack>
              )}
            </HeadChips>

            <RescueMunt>
              <CurrencyInvestment>
                <CurrencyInvestmentContent>
                  <IconInvesment
                    src={`${
                      porfolioDetailHeader.currency === 'USD'
                        ? '/assets/images/USD-symbol.svg'
                        : '/assets/images/icons-cpl.svg'
                    }`}
                  />
                  <InversionesEnPesos>{`Inversión en ${
                    porfolioDetailHeader.currency === 'USD' ? 'USD' : 'Pesos'
                  }`}</InversionesEnPesos>
                </CurrencyInvestmentContent>
              </CurrencyInvestment>
            </RescueMunt>
          </RescueAvatarDescript>
        </ContentAvatarRescue>
      </ComponentRescateCont>
    </>
  );
};
