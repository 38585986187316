import { Check, Warning } from '@mui/icons-material';
import { alpha, Avatar, Box, Button, Container, Modal, Paper, Typography } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ButtonXcalaBlue } from '../../components/ComponentApp/buttoms/ButtonsXcala';

const modalStyles = {
  wrapper: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    p: 4,
  },
  inputFields: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '20px',
    marginBottom: '15px',
    '.MuiInput-root': {
      marginBottom: '20px',
    },
  },
  buttons: {
    display: 'flex',
    justifyContent: 'end',
  },
};
const ModalActions = ({ children, open, onClose, content }) => {
  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={modalStyles.wrapper}>{content ? content : children}</Box>
    </Modal>
  );
};

export default ModalActions;

// casos de errores nuevos
export const RequestFailed = ({ onClose }) => {
  const navigate = useNavigate();
  return (
    <Container maxWidth='sm'>
      <Paper elevation={12} sx={{ borderRadius: 4 }}>
        <Box
          sx={{
            display: 'flex',
            pb: 2,
            pt: 3,
            px: 3,
          }}>
          <Avatar
            sx={{
              backgroundColor: theme => alpha(theme.palette.success.main, 0.08),
              color: 'success.main',
              mr: 2,
            }}>
            <Warning fontSize='large' />
          </Avatar>

          <div>
            <Typography variant='h5'>¡Oh no! parece que algo salio mal</Typography>
            <Typography color='textSecondary' sx={{ mt: 1 }} variant='body2'>
              Hemos tenido problemas para validar tu solicitud.
            </Typography>
          </div>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            px: 3,
            py: 1.5,
          }}>
          <Button
            sx={{ mr: 2 }}
            variant='outlined'
            onClick={() => {
              onClose();
              navigate('/profilerisk');
            }}>
            Cancelar
          </Button>
          <ButtonXcalaBlue
            onClick={() => {
              onClose();
              navigate('/wallet');
            }}>
            ¡Reintentalo!
          </ButtonXcalaBlue>
        </Box>
      </Paper>
    </Container>
  );
};
export const ReturnToForm = ({ onClose }) => {
  const navigate = useNavigate();
  return (
    <Container maxWidth='sm'>
      <Paper elevation={12} sx={{ borderRadius: 4 }}>
        <Box
          sx={{
            display: 'flex',
            pb: 2,
            pt: 3,
            px: 3,
          }}>
          <Avatar
            sx={{
              backgroundColor: theme => alpha(theme.palette.success.main, 0.08),
              color: 'success.main',
              mr: 2,
            }}>
            <Warning fontSize='large' />
          </Avatar>

          <div>
            <Typography variant='h5'>¡Oh no! parece que algo salio mal</Typography>
            <Typography color='textSecondary' sx={{ mt: 1 }} variant='body2'>
              Hemos tenido problemas para validar tu transacción
            </Typography>
          </div>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            px: 3,
            py: 1.5,
          }}>
          <Button
            sx={{ mr: 2 }}
            variant='outlined'
            onClick={() => {
              onClose();
              navigate('/profilerisk');
            }}>
            Cancelar
          </Button>
          <ButtonXcalaBlue
            onClick={() => {
              onClose();
              navigate('/wallet');
            }}>
            ¡Reintentalo!
          </ButtonXcalaBlue>
        </Box>
      </Paper>
    </Container>
  );
};
export const ModalChooseTransfer = ({ onClose, fintocFailed }) => {
  const navigate = useNavigate();
  return (
    <Container maxWidth='sm'>
      <Paper elevation={12} sx={{ borderRadius: 4 }}>
        <Box
          sx={{
            display: 'flex',
            pb: 2,
            pt: 3,
            px: 3,
          }}>
          <Avatar
            sx={{
              backgroundColor: theme => alpha(theme.palette.success.main, 0.08),
              color: 'success.main',
              mr: 2,
            }}>
            <Warning fontSize='large' />
          </Avatar>

          <div>
            <Typography variant='h5'>¡Oh no! parece que algo salio mal</Typography>
            <Typography color='textSecondary' sx={{ mt: 1 }} variant='body2'>
              Hemos tenido problemas para completar tu transacción. Para seguir con la inversión,
              completa el próximo paso donde te explicaremos que hacer.
            </Typography>
          </div>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            px: 3,
            py: 1.5,
          }}>
          <Button
            sx={{ mr: 2 }}
            variant='outlined'
            onClick={async () => {
              onClose(true, fintocFailed);
              navigate('/profilerisk');
            }}>
            Cancelar
          </Button>
          <ButtonXcalaBlue
            onClick={async () => {
              onClose(false, fintocFailed);
            }}>
            ¡Continuar!
          </ButtonXcalaBlue>
        </Box>
      </Paper>
    </Container>
  );
};
export const InsufficientBalance = ({ onClose }) => {
  const navigate = useNavigate();
  return (
    <Container maxWidth='sm'>
      <Paper elevation={12} sx={{ borderRadius: 4 }}>
        <Box
          sx={{
            display: 'flex',
            pb: 2,
            pt: 3,
            px: 3,
          }}>
          <Avatar
            sx={{
              backgroundColor: theme => alpha(theme.palette.success.main, 0.08),
              color: 'success.main',
              mr: 2,
            }}>
            <Warning fontSize='large' />
          </Avatar>

          <div>
            <Typography variant='h5'>¡Oh no!</Typography>
            <Typography color='textSecondary' sx={{ mt: 1 }} variant='body2'>
              Parece que no dispones de saldo en tu caja
            </Typography>
          </div>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            px: 3,
            py: 1.5,
          }}>
          <ButtonXcalaBlue
            onClick={() => {
              onClose();
              navigate('/contact');
            }}>
            !Aceptar¡
          </ButtonXcalaBlue>
        </Box>
      </Paper>
    </Container>
  );
};
export const CompleteBankDetails = ({ onClose }) => {
  const navigate = useNavigate();
  return (
    <Container maxWidth='sm'>
      <Paper elevation={12} sx={{ borderRadius: 4 }}>
        <Box
          sx={{
            display: 'flex',
            pb: 2,
            pt: 3,
            px: 3,
          }}>
          <Avatar
            sx={{
              backgroundColor: theme => alpha(theme.palette.success.main, 0.08),
              color: 'success.main',
              mr: 2,
            }}>
            <Warning fontSize='large' />
          </Avatar>

          <div>
            <Typography variant='h5'>
              Necesitamos tus datos bancarios para completar el retiro. Por favor completalos.
            </Typography>
            <Typography color='textSecondary' sx={{ mt: 1 }} variant='body2'>
              Tu caja no dispone de saldo en este momento
            </Typography>
          </div>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            px: 3,
            py: 1.5,
          }}>
          <Button
            sx={{ mr: 2 }}
            variant='outlined'
            onClick={() => {
              onClose();
              navigate('/wallet');
            }}>
            Cancelar
          </Button>
          <ButtonXcalaBlue
            onClick={() => {
              onClose();
              navigate('/contact');
            }}>
            Completar mis datos
          </ButtonXcalaBlue>
        </Box>
      </Paper>
    </Container>
  );
};
// casos de exitos nuevos
export const WithdrawSuccess = ({ onClose }) => {
  const navigate = useNavigate();
  return (
    <Container maxWidth='sm'>
      <Paper elevation={12} sx={{ borderRadius: 4 }}>
        <Box
          sx={{
            display: 'flex',
            pb: 2,
            pt: 3,
            px: 3,
          }}>
          <Avatar
            sx={{
              backgroundColor: theme => alpha(theme.palette.success.main, 0.08),
              color: 'success.main',
              mr: 2,
            }}>
            <Check fontSize='large' />
          </Avatar>

          <div>
            <Typography variant='h5'>¡Solicitud Recibida!</Typography>
            <Typography color='textSecondary' sx={{ mt: 1 }} variant='body2'>
              Gestionaremos tu retiro y te avisaremos cuando vaya en camino a tu cuenta.
            </Typography>
          </div>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            px: 3,
            py: 1.5,
          }}>
          <ButtonXcalaBlue
            onClick={() => {
              onClose();
              navigate('/');
            }}>
            Aceptar
          </ButtonXcalaBlue>
        </Box>
      </Paper>
    </Container>
  );
};
export const SuccessfulRechargeAllYes = ({ onClose }) => {
  const navigate = useNavigate();
  return (
    <Container maxWidth='sm'>
      <Paper elevation={12} sx={{ borderRadius: 4 }}>
        <Box
          sx={{
            display: 'flex',
            pb: 2,
            pt: 3,
            px: 3,
          }}>
          <Avatar
            sx={{
              backgroundColor: theme => alpha(theme.palette.success.main, 0.08),
              color: 'success.main',
              mr: 2,
            }}>
            <Check fontSize='large' />
          </Avatar>

          <div>
            <Typography variant='h5'>¡Gracias por operar con Xcala!</Typography>
            <Typography color='textSecondary' sx={{ mt: 1 }} variant='body2'>
              Puedes ver tu dinero reflejado en caja!
            </Typography>
          </div>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            px: 3,
            py: 1.5,
          }}>
          <ButtonXcalaBlue
            onClick={() => {
              onClose();
              navigate('/wallet');
            }}>
            Aceptar
          </ButtonXcalaBlue>
        </Box>
      </Paper>
    </Container>
  );
};
export const SuccessfulRecharge = ({ onClose }) => {
  const navigate = useNavigate();
  return (
    <Container maxWidth='sm'>
      <Paper elevation={12} sx={{ borderRadius: 4 }}>
        <Box
          sx={{
            display: 'flex',
            pb: 2,
            pt: 3,
            px: 3,
          }}>
          <Avatar
            sx={{
              backgroundColor: theme => alpha(theme.palette.success.main, 0.08),
              color: 'success.main',
              mr: 2,
            }}>
            <Check fontSize='large' />
          </Avatar>

          <div>
            <Typography variant='h5'>¡Recarga Exitosa!</Typography>
          </div>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            px: 3,
            py: 1.5,
          }}>
          <ButtonXcalaBlue
            onClick={() => {
              onClose();
              navigate('/contact');
            }}>
            Gracias
          </ButtonXcalaBlue>
        </Box>
      </Paper>
    </Container>
  );
};
export const SuccessfulInvestment = ({ onClose, fundName, amountInvestment }) => {
  const navigate = useNavigate();
  return (
    <Container maxWidth='md'>
      <Paper elevation={12} sx={{ borderRadius: 4 }}>
        <Box
          sx={{
            display: 'flex',
            pb: 2,
            pt: 3,
            px: 3,
          }}>
          <Avatar
            sx={{
              backgroundColor: theme => alpha(theme.palette.success.main, 0.08),
              color: 'success.main',
              mr: 2,
            }}>
            <Check fontSize='large' />
          </Avatar>

          <div>
            <Typography variant='h5'>Inversión Exitosa</Typography>
            <Typography
              color='textSecondary'
              sx={{
                mt: 1,
                fontWeight: '600',
                fontFamily: 'Poppins',
                color: '#1e22aa',
                typography: { sm: 'h6', xs: 'h6', xl: 'h6', md: 'h6' },
              }}>
              {fundName}
            </Typography>
            <Typography variant='subtitle1' sx={{ color: '#49A197', fontFamily: 'Poppins' }}>
              Monto de la inversión
            </Typography>
            <Typography
              variant='h6'
              sx={{
                color: '#49A197',
                fontWeight: '600',
                fontFamily: 'Poppins',
              }}>
              {`$ ${amountInvestment}`}
            </Typography>
          </div>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            px: 3,
            py: 1.5,
          }}>
          <ButtonXcalaBlue
            onClick={() => {
              onClose();
              navigate('/');
            }}>
            Aceptar
          </ButtonXcalaBlue>
        </Box>
      </Paper>
    </Container>
  );
};
export const SuccessfulRechargeAllNo = ({ onClose }) => {
  return (
    <Container maxWidth='sm'>
      <Paper elevation={12} sx={{ borderRadius: 4 }}>
        <Box
          sx={{
            display: 'flex',
            pb: 2,
            pt: 3,
            px: 3,
          }}>
          <Avatar
            sx={{
              backgroundColor: theme => alpha(theme.palette.success.main, 0.08),
              color: 'success.main',
              mr: 2,
            }}>
            <Check fontSize='large' />
          </Avatar>

          <div>
            <Typography variant='h5'> ¡Gracias, por tu confianza,</Typography>
            <Typography color='textSecondary' sx={{ mt: 1 }} variant='body2'>
              verás tu dinero disponible en caja en un plazo no mayor a 24 horas!
            </Typography>
          </div>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            px: 3,
            py: 1.5,
          }}>
          <ButtonXcalaBlue
            onClick={() => {
              onClose();
            }}>
            ¡Aceptar!
          </ButtonXcalaBlue>
        </Box>
      </Paper>
    </Container>
  );
};
