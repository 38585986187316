import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Typography, useTheme } from '@mui/material';
import React from 'react';
import NewDetailItem from './NewDetailItem';

const ObjectivesAndInterestsPanel = ({
  toggleState,
  title,
  userPreference,
  data,
  id,
  handleToggleChange,
}) => {
  const theme = useTheme();
  return (
    <Accordion
      onChange={() => handleToggleChange(id)}
      sx={{
        boxSizing: 'border-box',
        width: '100%',
        boxShadow: 'none',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'start',
        alignItems: 'center',
        '& .MuiCollapse-root ': {
          width: '100%',
        },
      }}
      expanded={toggleState?.find(item => item.id === id)?.value}>
      <AccordionSummary
        sx={{
          width: '90%',
          padding: 0,
          pr: { md: '20px', xs: '12px' },
        }}
        expandIcon={<ExpandMoreIcon />}
        aria-controls='panel1a-content'
        id='panel1a-header'>
        <Typography
          sx={{
            color: theme.palette.primary.main,
            fontWeight: theme.typography.h1.fontWeight,
            fontSize: theme.typography.title.fontSize,
            [theme.breakpoints.down('sm')]: {
              fontSize: theme.typography.description.fontSize,
            },
          }}>
          {title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          width: '90%',
          margin: '0 auto',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          gap: 3,
        }}>
        {data?.map(value => {
          const texts = value.text.split('-');
          const checked = userPreference?.find(x => x.preference_detail_id == value.id);
          return (
            <NewDetailItem
              key={value.id}
              id={value.id}
              title={texts[0]}
              icon_name={value.icon_name}
              checked={!!checked}
            />
          );
        })}
      </AccordionDetails>
    </Accordion>
  );
};

export default ObjectivesAndInterestsPanel;
