import emailjs from '@emailjs/browser';
import HelpIcon from '@mui/icons-material/Help';
import { Checkbox, Stack, Typography, styled } from '@mui/material';
import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { updateAttributes } from '../../redux/onboarding';
import { ButtonsPrimary, ButtonsTertiary } from '../ComponentApp/buttoms/ButtonsXcala';
import DialogBox from '../UI/modal';

const TypographySpan = styled(Typography)({
  fontSize: '1rem',
  color: '#182849',
  fontWeight: '500',
});

const TypographyParagraph = styled(Typography)({
  color: '#182849',
  fontSize: '1rem',
  fontFamily: 'Poppins',
  fontWeight: '400',
  lineHeight: '1.5',
});

const HelpIconCustom = styled(HelpIcon)({
  color: '#1e22aa',
  cursor: 'pointer',
  fontSize: '1.5rem',
});

export const StepTwoFormOmboarding = ({ setstepper, setSteppercolor }) => {
  const dispatch = useDispatch();
  const onBoardingData = useSelector(state => state.onboarding.data);
  const { user } = useSelector(state => state.auth);

  const getValue = name => {
    const ops = onBoardingData?.checkOptions;
    return ops ? (ops[name] ? ops[name] : false) : false;
  };

  const [checkOpt, setCheckOpt] = useState({
    pep: getValue('pep'),
    usCitizen: getValue('usCitizen'),
    relationXcala: getValue('relationXcala'),
    dualNationality: getValue('dualNationality'),
    payTax: getValue('payTax'),
  });

  const [open, setOpen] = useState(false);
  const [openTwo, setOpenTwo] = useState(false);
  const [openThree, setOpenThree] = useState(false);
  const [openFour, setOpenFour] = useState(false);
  const [openFive, setOpenFive] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClickOpenTwo = () => {
    setOpenTwo(true);
  };
  const handleClickOpenThree = () => {
    setOpenThree(true);
  };
  const handleClickOpenFour = () => {
    setOpenFour(true);
  };
  const handleClickOpenFive = () => {
    setOpenFive(true);
  };
  const thirdStep = () => {
    let checkOptions = {};

    Object.keys(checkOpt).map(item => {
      if (checkOpt[item]) checkOptions[item] = checkOpt[item];
    });

    const data = {
      ...onBoardingData,
      checkOptions,
    };
    dispatch(updateAttributes(data));
    if (onBoardingData.detail[3].Value) {
      emailjs
        .send(
          'service_7utjird',
          'template_9tg538u',
          {
            message: JSON.stringify(onBoardingData),
            from_name: user?.name,
            reply_to: user?.email,
          },
          'EFAYuCkTVHLBFWEFQ',
        )
        .then(
          result => {
            toast.success('Solicitud enviada con exito');
          },
          error => {
            toast.error('Ups ha ocurrido un error');
          },
        );
    }
    setstepper(3);
    setSteppercolor(first => {
      return {
        ...first,
        comp3: true,
      };
    });
  };

  const goBefore = () => {
    setstepper(1);
    setSteppercolor(first => {
      return {
        ...first,
        comp1: true,
        comp2: false,
      };
    });
  };

  const pep = {
    title: '¿Eres una persona expuesta políticamente?',
    content:
      "El término PEP (Personas Expuestas Políticamente) designa a los chilenos o extranjeros que desempeñan o hayan desempeñado funciones públicas destacadas en un país, hasta a lo menos un año de finalizado el ejercicio de las mismas. <span class='break_content'></span>Se incluyen en esta categoría a jefes de Estado o de un Gobierno, políticos de lata jerarquía, funcionarios gubernamentales, judiciales o militares de alta jerarquía, altos ejecutivos de empresas estatales, así como sus cónyuges, sus parientes hasta el segundo grado de consanguinidad, y las personas naturales con las que hayan celebrado un pacto de actuación conjunta, mediante el cual tengan poder de voto suficiente para influir en sociedades constituidas de Chile.<span class='break_content'></span>La vinculación con un PEP considera al cónyuge o parientes de este hasta el segundo grado de consanguinidad, es decir, abuelo(a), padre, madre, hijo(a), nieto(a).",
    btnContent: 'Entendido',
  };
  const usCitizen = {
    title: '¿Eres ciudadano norteamericano?',
    content:
      'Persona Estadounidense: El término Persona Estadounidense (U.S. Person en inglés) aplica a ciudadanos americanos aún cuando no residan en Estados Unidos.<ul><li>Ciudadanos americanos que no residan en Estados Unidos. </li><li> Residentes de Estados Unidos. Es decir, por ejemplo, en los últimos 12 meses más de 183 días en Estados Unidos.</li><li>Personas con pasaporte americano.</li><li>Nacidos en Estados Unidos, salvo renuncia a la nacionalidad. </li><li>Residentes permanentes en Estados Unidos (Titular de Green Card). </li><li> Tener asociada a su cuenta bancaria una dirección en Estados Unidos.</li><li>Órdenes de transferencia periódicas a una cuenta estadounidense o que las ordenes se reciban desde una dirección de Estados Unidos. </li></ul>',
    btnContent: 'Entendido',
  };
  const relationXcala = {
    title: '¿Tienes alguna relación con Xcala?',
    content:
      "Se considera que una persona natural tiene relación con Xcala en el caso de ser un Director, Gerente, Administrador, Ejecutivos principales o Liquidadores de esta sociedad o bien de tener una relación de parentesco con uno de ellos.<span class='break_content'></span> La vinculación con los cargos mencionados considera al cónyuge o parientes hasta el segundo grado de consanguinidad. Es decir, abuelo(a), padre, madre, hijo(a), hermano(a), nieto(a).",
    btnContent: 'Entendido',
  };
  const dualNationality = {
    title: '¿Tienes doble nacionalidad?',
    content:
      'La doble nacionalidad es el estatus juridico que disfrutan ciertos individuos, al ser reconocidos como nacionales simultáneamente por dos estados.',
    btnContent: 'Entendido',
  };
  const payTax = {
    title: '¿Tributas en otro país?',
    content:
      "La residencia tributaria designa el país donde una persona natural o entidad es considerada como residente o contribuyente para efectos tributarios de dicha jurisdicción.<span class='break_content'></span> Si tienes dudas respecto de si puedes ser considerado o no como persona natural con residencia tributaria en otros paises, consultar aquí, donde encontrarás la definición de residencia tributaria proporciada por los países participantes.",
    btnContent: 'Entendido',
  };
  return (
    <>
      <Stack width={'100%'}>
        <Stack spacing={1}>
          <Stack direction={'row'} alignItems={'center'} spacing={1}>
            <Checkbox
              id='pep'
              checked={checkOpt.pep}
              onChange={e => setCheckOpt({ ...checkOpt, pep: e.target.checked })}
            />

            <TypographyParagraph>
              Declaro que soy una {''}
              <TypographySpan component={'span'}>
                Persona Políticamente Expuesta (PEP)
              </TypographySpan>
            </TypographyParagraph>
            <HelpIconCustom onClick={handleClickOpen} />
          </Stack>

          <Stack direction={'row'} alignItems={'center'} spacing={1}>
            <Checkbox
              id='usCitizen'
              checked={checkOpt.usCitizen}
              onChange={e => setCheckOpt({ ...checkOpt, usCitizen: e.target.checked })}
            />
            <TypographyParagraph>
              Declaro ser {''}
              <TypographySpan component={'span'}>ciudadano norteamericano</TypographySpan>
            </TypographyParagraph>
            <HelpIconCustom onClick={handleClickOpenTwo} />
          </Stack>

          <Stack direction={'row'} alignItems={'center'} spacing={1}>
            <Checkbox
              id='relationXcala'
              checked={checkOpt.relationXcala}
              onChange={e => setCheckOpt({ ...checkOpt, relationXcala: e.target.checked })}
            />

            <TypographyParagraph>
              Declaro tener una relación de {''}
              <TypographySpan component='span'>trabajo con Xcala</TypographySpan>
            </TypographyParagraph>
            <HelpIconCustom onClick={handleClickOpenThree} />
          </Stack>

          <Stack direction={'row'} alignItems={'center'} spacing={1}>
            <Checkbox
              id='dualNationality'
              checked={checkOpt.dualNationality}
              onChange={e =>
                setCheckOpt({
                  ...checkOpt,
                  dualNationality: e.target.checked,
                })
              }
            />

            <TypographyParagraph>
              Declaro tener {''}
              <TypographySpan component='span'>doble nacionalidad</TypographySpan>
            </TypographyParagraph>
            <HelpIconCustom onClick={handleClickOpenFour} />
          </Stack>

          <Stack direction={'row'} alignItems={'center'} spacing={1}>
            <Checkbox
              id='payTax'
              checked={checkOpt.payTax}
              onChange={e => setCheckOpt({ ...checkOpt, payTax: e.target.checked })}
            />

            <TypographyParagraph>
              Declaro {''}
              <TypographySpan component='span'>tributar en otro país</TypographySpan>
            </TypographyParagraph>
            <HelpIconCustom onClick={handleClickOpenFive} />
          </Stack>
        </Stack>
        <Stack
          width={'100%'}
          direction={'row'}
          sx={{
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <Stack
            justifyContent={'center'}
            direction={{ md: 'row', xs: 'column-reverse' }}
            width={{ md: '60%', xs: '100%' }}
            gap={2}
            sx={{ margin: '26px 0px 0px 0px' }}>
            <ButtonsTertiary fullWidth name='Atrás' onClick={goBefore} />
            <ButtonsPrimary name='Continuar' onClick={thirdStep} fullWidth />
          </Stack>
        </Stack>
      </Stack>

      <DialogBox
        setOpen={setOpen}
        open={open}
        modal_title={pep.title}
        modal_content={pep.content}
        action_btn={pep.btnContent}
      />
      <DialogBox
        setOpen={setOpenTwo}
        open={openTwo}
        modal_title={usCitizen.title}
        modal_content={usCitizen.content}
        action_btn={usCitizen.btnContent}
      />
      <DialogBox
        setOpen={setOpenThree}
        open={openThree}
        modal_title={relationXcala.title}
        modal_content={relationXcala.content}
        action_btn={relationXcala.btnContent}
      />
      <DialogBox
        setOpen={setOpenFour}
        open={openFour}
        modal_title={dualNationality.title}
        modal_content={dualNationality.content}
        action_btn={dualNationality.btnContent}
      />
      <DialogBox
        setOpen={setOpenFive}
        open={openFive}
        modal_title={payTax.title}
        modal_content={payTax.content}
        action_btn={payTax.btnContent}
      />
    </>
  );
};
