import { Box, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import {
  DataGrid,
  GridCsvExportMenuItem,
  GridToolbarContainer,
  GridToolbarExportContainer,
} from '@mui/x-data-grid';
import React from 'react';
import { formatChileanDate, numberWithPoint } from '../../../utils/utils';
import { CircleIcon } from '../../UI/icons/CircleIcon';

const csvOptions = { delimiter: ';' };

const CustomExportButton = props => {
  return (
    <GridToolbarExportContainer {...props}>
      <GridCsvExportMenuItem rintOptions={{ fileName: 'Descargar informe' }} options={csvOptions} />
    </GridToolbarExportContainer>
  );
};

const CustomToolbar = props => {
  return (
    <GridToolbarContainer {...props} sx={{ display: 'flex', justifyContent: 'start' }}>
      <CustomExportButton />
    </GridToolbarContainer>
  );
};

const NoRowsOverlay = () => {
  return (
    <Stack height='100%' alignItems='center' justifyContent='center'>
      No hay movimientos para mostrar
    </Stack>
  );
};

const TableInvertedProducts = ({ transactions, colores }) => {
  const isXsRender = useMediaQuery(theme => theme.breakpoints.only('xs'));
  const theme = useTheme();
  const column = [
    {
      field: 'fecha',
      headerName: 'Fecha',
      width: isXsRender ? 120 : 200,
      flex: '0.5 0 100px',
      align: 'left',
      renderCell: params => {
        return (
          <div>
            <Typography variant='subtitle2'>{formatChileanDate(params.formattedValue)}</Typography>
          </div>
        );
      },
    },
    {
      field: 'fondo',
      headerName: 'Fondo',
      width: isXsRender ? 250 : 400,
      flex: '0.6 0 80px',
      align: 'left',
      hide: true,
      renderCell: params => (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
          <CircleIcon strokeColors={colores[params.value]} />
          {params.value}
        </Box>
      ),
    },
    {
      field: 'movimiento',
      headerName: 'Movimiento',
      width: isXsRender ? 160 : 200,
      flex: '1 1 150px',
      align: 'left',
      hide: true,
    },
    {
      field: 'monto',
      headerName: 'Monto',
      width: isXsRender ? 120 : 230,
      align: 'left',
      flex: '0.6 0 auto',
      renderCell: params => {
        return (
          <div>
            <Typography variant='subtitle2'>
              {params.formattedValue === '0' || params.formattedValue === ''
                ? '-'
                : `$ ${params.formattedValue}`}
            </Typography>
          </div>
        );
      },
      sortComparator: (v1, v2) => Number(v1.replace(/\./g, '')) - Number(v2.replace(/\./g, '')),
    },
    {
      field: 'cuotas',
      headerName: 'Cuotas',
      width: isXsRender ? 130 : 230,
      align: 'left',
      flex: '0.6 0 auto',
      renderCell: params => {
        return (
          <div>
            <Typography variant='subtitle2'>
              {params.formattedValue === 0 || params.formattedValue === ''
                ? '---'
                : params.formattedValue.toFixed(4)}
            </Typography>
          </div>
        );
      },
      sortComparator: (v1, v2) => Number.parseFloat(v1) - Number.parseFloat(v2),
    },
    {
      field: 'valorCuota',
      headerName: 'Valor Cuota',
      width: isXsRender ? 160 : 230,
      flex: '0.6 0 auto',
      align: 'left',
      valueFormatter: params =>
        Number.parseFloat(params.value) !== 0
          ? `$ ${numberWithPoint(Number.parseFloat(params.value).toFixed(4))}`
          : '-',
    },
  ];

  const row = transactions.map((transaction, index) => {
    let amountValue;
    if (transaction.subType === 'SELL' && transaction.subTypeDetail === 'Rescate') {
      amountValue =
        numberWithPoint(transaction.amount) !== '0' || transaction.amount !== null
          ? `${numberWithPoint(-Math.round(Number.parseFloat(transaction.amount).toFixed(4)))}`
          : '-';
    } else {
      amountValue =
        numberWithPoint(transaction.amount) !== '0' || transaction.amount !== null
          ? `${numberWithPoint(Math.round(Number.parseFloat(transaction.amount).toFixed(4)))}`
          : '-';
    }

    return {
      id: index + 1,
      fecha: transaction.tradeDate,
      fondo: transaction.securityName,
      movimiento: transaction.subTypeDetail,
      monto: amountValue,
      cuotas: Number.parseFloat(transaction.units),
      valorCuota: Number.parseFloat(transaction.price).toFixed(4),
    };
  });

  return (
    <>
      <Box
        sx={{
          height: '400px',
          width: '100%',
          '& .font-tabular-nums': {
            fontVariantNumeric: 'tabular-nums',
          },
        }}>
        <DataGrid
          rowHeight={50}
          columns={column}
          rows={row}
          sx={{
            '.MuiDataGrid-columnHeaders': {
              backgroundColor: theme.palette.primary.main,
              color: theme.palette.white.main,
              fontSize: theme.typography.button.fontSize,
              [theme.breakpoints.down('sm')]: {
                fontSize: theme.typography.customButton.fontSize,
              },
            },
            '.MuiDataGrid-toolbarContaine, .css-128fb87-MuiDataGrid-toolbarContainer': {
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'end',
            },
            '.MuiDataGrid-toolbarContainer>button': {
              color: theme.palette.textLight.main,
              backgroundColor: theme.palette.white.main,
              '&:hover': {
                backgroundColor: '#f5f5f5',
              },
            },

            '.MuiDataGrid-columnSeparator': {
              display: 'none',
            },
            '&.MuiDataGrid-root': {
              borderRadius: '8px',
            },
            '.MuiDataGrid-row': {
              fontSize: theme.typography.button.fontSize,
              textAlign: 'left',
              justifyContent: 'center',
              [theme.breakpoints.down('sm')]: {
                fontSize: theme.typography.customButton.fontSize,
              },
            },

            '& .super-app-theme--cell': {
              width: { md: '100%', xs: '100px' },
              color: theme.palette.primary.main,
              fontWeight: theme.typography.button.fontWeight,
              display: 'flex',
              justifyContent: 'start',
              alignItems: 'center',
            },
            '.MuiDataGrid-footerContainer': {
              fontSize: theme.typography.button.fontSize,
              color: '#ddd',
              [theme.breakpoints.down('sm')]: {
                fontSize: theme.typography.customButton.fontSize,
              },
            },
            '.MuiButtonBase-root': {
              color: theme.palette.white.main,
            },

            '.MuiDataGrid-footerContainer  .MuiButtonBase-root ': {
              color: '#2141aa',
              width: { xs: '30px' },
              '&.Mui-disabled': {
                color: '#6c6c6c94',
              },
            },
          }}
          localeText={{
            toolbarDensity: 'Size',
            toolbarDensityLabel: 'Size',
            toolbarDensityCompact: 'Small',
            toolbarDensityStandard: 'Medium',
            toolbarDensityComfortable: 'Large',
            toolbarExportCSV: 'Exportar a CSV',
            toolbarExport: 'EXPORTAR',
          }}
          slots={{
            toolbar: CustomToolbar,
            noRowsOverlay: NoRowsOverlay,
          }}
          slotProps={{
            pagination: { labelRowsPerPage: 'Filas por página' },
          }}
        />
      </Box>
    </>
  );
};

export default TableInvertedProducts;
