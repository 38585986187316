import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import {
  Box,
  Button,
  Collapse,
  ListItem,
  ListItemButton,
  ListItemText,
  Stack,
  SwipeableDrawer,
  Typography,
  useTheme,
} from '@mui/material';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import * as React from 'react';
import { useMenuHandlerMobile } from '../../../../hooks/menu/useMenuHandlerMobile';
import { ItemListBtn } from '../components/ItemListButton';
import { LogoXcalaWhite } from '../components/LogoXcalaWhite';
import { AcountItem } from './AcountItem';
import {
  BoxContainerMobile,
  BoxNavigation,
  ButtonReferrals,
  CardContainer,
  ContentActions,
  ContentTitleSection,
  DownArrowIcon,
  Footer,
  IconMoodOutlined,
  Internal,
  ListContainer,
  MyInvestment,
  UpArrowIcon,
} from './StyledComponentsMenu';

export const LayoutMenuMobile = ({ children }) => {
  const {
    subMenu,
    contentMenu,
    myAccountBtn,
    value,
    openMovil,
    open,
    openReferidos,
    user,
    setOpen,
    setOpenReferidos,
    handleChange,
    handleOpen,
    handleOpenMovil,
    handleNavigateSubMenu,
    handleClickHome,
    handleNavigateMyAccount,
    handleTouchStart,
    handleTouchEnd,
  } = useMenuHandlerMobile();
  const theme = useTheme();

  const MenuMobile = menu => {
    if (!Array.isArray(menu)) {
      return null;
    }

    return (
      <BoxContainerMobile
        role='presentation'
        onTouchStart={handleTouchStart}
        onTouchEnd={handleTouchEnd}>
        <ListContainer>
          <Box sx={{ alignSelf: 'center' }}>
            <LogoXcalaWhite handleClickHome={handleClickHome} />
          </Box>
          {menu.map(item => {
            return (
              <ListItem disablePadding key={item.id}>
                <ListItemButton>
                  <ListItemText
                    primary={
                      <Typography
                        onClick={() => handleNavigateSubMenu(item.url)}
                        fontSize={theme.typography.customButton.fontSize}
                        color={theme.palette.white.main}>
                        {item.label}
                      </Typography>
                    }
                  />
                </ListItemButton>
              </ListItem>
            );
          })}

          <Footer>
            <ContentActions>
              <ContentTitleSection
                onClick={() => {
                  setOpenReferidos(!openReferidos);
                  openReferidos === false && setOpen(false);
                }}>
                <Stack direction='row' alignItems='center'>
                  <IconMoodOutlined />
                  <MyInvestment>Referidos</MyInvestment>
                </Stack>
                <Stack>{openReferidos ? <UpArrowIcon /> : <DownArrowIcon />}</Stack>
              </ContentTitleSection>
              <Collapse
                appear={openReferidos}
                in={openReferidos}
                timeout={500}
                sx={{
                  width: '100%',
                }}>
                <CardContainer>
                  <ButtonReferrals>Próximamente</ButtonReferrals>
                </CardContainer>
              </Collapse>
            </ContentActions>

            <ContentTitleSection onClick={handleOpen}>
              <Stack direction='row' alignItems='center'>
                <SettingsOutlinedIcon sx={{ color: theme.palette.white.main }} />
                <MyInvestment>Mi cuenta</MyInvestment>
              </Stack>
              <Stack>{open ? <UpArrowIcon /> : <DownArrowIcon />}</Stack>
            </ContentTitleSection>
            <Collapse
              appear={open}
              in={open}
              timeout={500}
              sx={{
                width: '100%',
              }}>
              <Internal>
                {myAccountBtn.subMenu.map(item => (
                  <ItemListBtn
                    url={item.url}
                    key={item.id}
                    btnNameSub={item.btnNameSub}
                    fontSize={theme.typography.littleText.fontSize}
                    onClick={() => handleNavigateMyAccount(item.url)}
                  />
                ))}
              </Internal>
            </Collapse>
          </Footer>
          <Box>
            <AcountItem user={user} />
            <Box
              sx={{
                marginTop: '32px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
              }}>
              <Button
                onClick={handleOpenMovil(false)}
                onKeyDown={handleOpenMovil(false)}
                sx={{
                  color: theme.palette.white.main,
                  backgroundColor: 'inherit',
                }}>
                <ExpandLessIcon />
              </Button>
            </Box>
          </Box>
        </ListContainer>
      </BoxContainerMobile>
    );
  };

  return (
    <>
      <Box>
        <SwipeableDrawer
          anchor='top'
          open={openMovil}
          onOpen={() => handleOpenMovil(true)}
          onClose={() => handleOpenMovil(false)}>
          {MenuMobile(subMenu)}
        </SwipeableDrawer>
      </Box>
      {children}
      <BoxNavigation>
        <BottomNavigation
          sx={{
            width: '100%',
            height: '80px',
          }}
          value={value}
          onChange={handleChange}>
          {contentMenu.map(item => (
            <BottomNavigationAction
              key={item.id}
              sx={{ fontSize: theme.typography.littleText.fontSize }}
              label={item.label}
              value={item.value}
              icon={item.icon}
              onClick={item.action}
            />
          ))}
        </BottomNavigation>
      </BoxNavigation>
    </>
  );
};
