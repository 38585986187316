import { Button, Stack, Typography, useTheme } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { TextDescription } from './ErrorStepSign';
import { useSelector } from 'react-redux';

export const SuccessStepSign = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const { user } = useSelector(state => state.auth);

  console.log('estado del user en SuccessStepSign:', user);

  return (
    <Stack
      textAlign={'center'}
      width={'90%'}
      gap={1}
      justifyContent={'center'}
      alignItems={'center'}>
      <Stack width={'100px'} height={'100px'}>
        <img
          src='/assets/images/sucess-gif.gif'
          alt='error'
          style={{ width: '100%', height: '100%', display: 'block' }}
        />
      </Stack>
      <Typography
        component='h3'
        sx={{
          fontSize: '1.9rem',
          fontWeight: theme.typography.button.fontWeight,
          color: theme.palette.secondary.main,
        }}>
        ¡Felicidades, completaste tu registro!
      </Typography>

      <TextDescription>Ya puedes ver las recomendaciones que tenemos para ti</TextDescription>

      <Stack width={{ md: '80%', xs: '100%' }} marginTop={3}>
        <Button
          variant='contained'
          disableElevation
          sx={{
            padding: '12px 32px',
            textTransform: 'none',
          }}
          fullWidth
          onClick={() => navigate('/myprofilerisk')}>
          Ver tus recomendaciones
        </Button>
      </Stack>
    </Stack>
  );
};
