import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Spinner from '../../../components/Spinner';
import { HeaderProducts } from '../../../components/XcalaComponents';
import { NotificationsXcala } from '../../../components/XcalaComponents/Notifications/NotificationsXcala';
import BodyInvertedProducts from '../../../components/products/productsInvestment/BodyInvertedProducts';
import { useGetPriceRangeProducts } from '../../../hooks/useGetPriceRangeProducts';
import { useGetSecurityPriceRangeProducts } from '../../../hooks/useGetSecurityPriceRangeProducts';
import { useGetSecurityTransactionsProducts } from '../../../hooks/useGetSecurityTransactionsProducts';
import { useGetUserDividend } from '../../../hooks/useGetUserDividend';
import { setMyInvestmentSelected, setRescueType } from '../../../redux/investments';
import { setSecuritySelected } from '../../../redux/simulation';
import { AddCurrency } from '../../../utils/addCostEffectivemess';
import {
  getCurrentDateFormatMMDDYYYY,
  getCurrentDateFormatYYYYMMDD,
  getFirstElement,
  setMonthsDateFormatMMDDYYYY,
  setMonthsDateFormatYYMMDD,
} from '../../../utils/utils';

import useModal from '../../../hooks/custom/useModal';
import { onRefresh } from '../../../utils/refreshPage';

const ProductsInvestmentDetail = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isShowing, toggle } = useModal();
  const { investmentSelected } = useSelector(state => state.investments);
  const [active, setActive] = useState(12);
  const [valorCuota, setValorCuota] = useState(null);
  const [visible, setVisible] = useState(false);
  const [dataCharts, setDataCharts] = useState({
    data: [],
    dividends: null,
  });

  const custom = JSON.parse(investmentSelected.custom);
  const risk = custom.preferences.riesgo.description;
  const motivations = getFirstElement(custom.preferences.motivaciones);
  const interests = getFirstElement(custom.preferences.intereses);
  const motivationsId = custom.preferences.motivaciones[0].id;
  const interestsId = custom.preferences.intereses[0].id;

  const {
    data: statusDividend,
    loading: loadingStatusDividend,
    error: errorStatusDividend,
    refetch,
  } = useGetUserDividend();

  const variablesPriceRange = {
    securityId: investmentSelected.id,
    from: setMonthsDateFormatYYMMDD(-12),
    to: getCurrentDateFormatYYYYMMDD(),
  };
  const {
    data: getPriceRange,
    error: errorGetPriceRange,
    loading: loadingGetPriceRange,
  } = useGetPriceRangeProducts({
    data: variablesPriceRange,
  });

  const successGetPriceRange = getPriceRange?.getPriceRange || [];

  const variablesSecurityPriceRange = {
    securityId: investmentSelected.id,
    startDate: setMonthsDateFormatMMDDYYYY(-active).replaceAll('-', '/'),
    endDate: getCurrentDateFormatMMDDYYYY(),
  };
  const {
    data: getSecurityPriceRange,
    error: errorGetSecurityPriceRange,
    loading: loadingGetSecurityPriceRange,
    refetch: refetchGetSecurityPriceRange,
  } = useGetSecurityPriceRangeProducts({ data: variablesSecurityPriceRange });
  const successGetSecurityPriceRange = getSecurityPriceRange?.getSecurityPriceRange;

  const variablesSecurityTransactions = {
    securityId: investmentSelected.id,
    status: '2,4',
  };
  const {
    data: getSecurityTransactions,
    error: errorGetSecurityTransactions,
    loading: loadingGetSecurityTransactions,
  } = useGetSecurityTransactionsProducts({
    data: variablesSecurityTransactions,
  });

  const successGetSecurityTransactions = getSecurityTransactions?.getSecurityTransactions || [];

  const handleInvestMore = id => {
    const security = {
      ID: investmentSelected.id,
      Name: investmentSelected.name,
      Custom: investmentSelected.custom,
    };

    dispatch(setSecuritySelected(security));
    navigate('/simulator');
  };

  const handleRescueInvestment = id => {
    dispatch(setRescueType('Rescatartodo'));
    dispatch(setMyInvestmentSelected(investmentSelected));
    navigate('/rescue');
  };

  const goToProductDetail = () => {
    const security = {
      ID: investmentSelected.id,
      Name: investmentSelected.name,
      Custom: investmentSelected.custom,
    };

    dispatch(setSecuritySelected(security));
    navigate('/productdetail');
  };

  const onChangeMonths = months => {
    setActive(months);
    const data = {
      securityId: investmentSelected.id,
      startDate: setMonthsDateFormatMMDDYYYY(-months).replaceAll('-', '/'),
      endDate: getCurrentDateFormatMMDDYYYY(),
    };
    refetchGetSecurityPriceRange(data);
  };

  const handleOpen = () => {
    setVisible(true);
  };
  const handleClose = () => {
    setVisible(false);
  };

  const statusDividendFinfolio = statusDividend?.getUserDividendFinfolio?.Custom.filter(item => {
    return item.Description === investmentSelected.id.toLowerCase();
  });

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    onChangeMonths(12);
  }, []);

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    if (successGetPriceRange && !loadingGetPriceRange) {
      if (active === 12) {
        const valoresCuota = successGetPriceRange.sort(
          (a, b) => new Date(b.EffectiveDate) - new Date(a.EffectiveDate),
        );
        setValorCuota(valoresCuota[0]);
      }
    }
  }, [successGetPriceRange, loadingGetPriceRange]);

  useEffect(() => {
    if (successGetSecurityPriceRange && !loadingGetSecurityPriceRange) {
      const dataOrdered = successGetSecurityPriceRange
        .map(item => {
          return {
            ...item,
            ClosePrice: item.closePrice,
            EffectiveDate: new Date(item.effectiveDate),
          };
        })
        .sort((a, b) => new Date(b.EffectiveDate) - new Date(a.EffectiveDate));
      const dividendsFiltered = dataOrdered.filter(item => item.dividend > 0);

      const dividends = dividendsFiltered.map(item => {
        return {
          quotaFactor: item.dividend,
          provisionDate: new Date(item.effectiveDate),
        };
      });

      setDataCharts({
        data: dataOrdered,
        dividends: dividends,
      });
    }
  }, [successGetSecurityPriceRange, loadingGetSecurityPriceRange]);

  if (loadingStatusDividend || loadingGetPriceRange) {
    return <Spinner />;
  }

  if (
    errorStatusDividend ||
    errorGetPriceRange ||
    errorGetSecurityTransactions ||
    errorGetSecurityPriceRange
  ) {
    return (
      <NotificationsXcala
        activation={!isShowing}
        message='Lo sentimos, ha ocurrido un error inesperado....'
        nameBtnTwo='Enviar error'
        toggle={toggle}
        refresh={onRefresh}
        typeNotification='fetch'
        error={
          errorStatusDividend ||
          errorGetPriceRange ||
          errorGetSecurityTransactions ||
          errorGetSecurityPriceRange
        }
      />
    );
  }

  const fileAs = {
    currency: AddCurrency(investmentSelected.name),
    fileAs: investmentSelected.name,
    fileAsComplete: investmentSelected.name.slice(4),
    risk: risk,
    motivation: motivations.split('-')[0],
    motivationId: motivationsId,
    interests: interests,
    interestsId: interestsId,
    products: true,
  };

  return (
    <>
      <HeaderProducts
        handleInvestMore={handleInvestMore}
        handleRescueInvestment={handleRescueInvestment}
        fileAs={fileAs}
        visible={visible}
        statusDividendFinfolio={statusDividendFinfolio}
        handleOpen={handleOpen}
        handleClose={handleClose}
        goToProductDetail={goToProductDetail}
        details={true}
      />
      <BodyInvertedProducts
        dataCharts={dataCharts.data}
        dividends={dataCharts.dividends}
        valorCuota={valorCuota}
        myInvestment={investmentSelected}
        onChangeMonths={onChangeMonths}
        loadingGetSecurityPriceRange={loadingGetSecurityPriceRange}
        successGetSecurityTransactions={successGetSecurityTransactions}
        loadingGetSecurityTransactions={loadingGetSecurityTransactions}
        active={active}
        statusDividendFinfolio={statusDividendFinfolio}
        refetch={refetch}
        handleClose={handleClose}
        visible={visible}
      />
    </>
  );
};

export default ProductsInvestmentDetail;
