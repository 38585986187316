import emailjs from '@emailjs/browser';
import { useTheme } from '@emotion/react';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Box, Container, Dialog, Stack, Typography } from '@mui/material';
import { toast } from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  ButtonsPrimary,
  ButtonsSecondary,
} from '../../components/ComponentApp/buttoms/ButtonsXcala';
import capitalize from '../../utils/capitalize';

const ModalErrorFetch = ({ open, onClose, refresh, message = '', error }) => {
  const { user } = useSelector(state => state.auth);
  const navigator = window.navigator.appVersion;
  const platform = window.navigator.platform;

  const paternalName = user?.['custom:paternal-surname'] || '';
  const maternalName = user?.['custom:maternal-surname'] || '';
  const fullName =
    user && `${capitalize(user.name)} ${capitalize(paternalName)} ${capitalize(maternalName)}`;
  const navigate = useNavigate();
  const location = useLocation();
  const currentTime = new Date().toLocaleString();
  const theme = useTheme();

  const report = {
    name: fullName || '',
    securityId: user?.username || 'error de login',
    email: user?.email || '',
    currentTime: currentTime,
    errorMessage: error,
    location: location.pathname,
    navigator: navigator,
    platform: platform,
  };

  const handleSubmitReport = () => {
    emailjs
      .send(
        'service_7utjird',
        'template_v0a6xsc',
        {
          message: JSON.stringify(report),
          from_name: fullName,
          reply_to: user?.email,
        },
        'EFAYuCkTVHLBFWEFQ',
      )
      .then(
        result => {
          toast.success('Reporte enviado con exito');
        },
        error => {
          toast.error('Ups ha ocurrido un error');
        },
      );

    onClose();
    navigate('/profilerisk');
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <Container>
        <Box
          sx={{
            display: 'flex',
            justifyContent: { xs: 'center' },
            px: 3,
            marginBottom: 1,
          }}>
          <ErrorOutlineIcon
            sx={{
              color: 'error.main',
              fontSize: { xs: '60px', sm: '100px' },
            }}
          />
        </Box>

        <Box
          sx={{
            display: 'flex',
            pb: 2,
            px: 3,
          }}>
          <Box>
            <Typography
              sx={{
                fontSize: theme.typography.button.fontSize,
                marginBottom: '5px',
                [theme.breakpoints.down('sm')]: {
                  fontSize: theme.typography.customButton.fontSize,
                },
              }}>
              {message}
            </Typography>
            {error && (
              <details>
                <summary style={{ color: theme.palette.primary.dark }}>Detalles del error</summary>
                {error.message}
              </details>
            )}
          </Box>
        </Box>
        <Stack
          direction={{
            xs: 'column',
            sm: 'row',
          }}
          justifyContent='center'
          alignItems='center'
          spacing={1}
          sx={{
            px: 3,
            py: 1.5,
          }}>
          <ButtonsSecondary
            name='Enviar Error'
            fullWidth={true}
            onClick={handleSubmitReport}
            sx={{ whiteSpace: 'nowrap' }}
          />
          <ButtonsPrimary name='Reintentar' fullWidth={true} onClick={() => refresh()} />
        </Stack>
      </Container>
    </Dialog>
  );
};

export default ModalErrorFetch;
