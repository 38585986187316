import { Grid, Stack } from '@mui/material';
import React from 'react';
import { ErrorBoundaryXcala } from '../../../components/ErrorBoundaryXcala';
import {
  BackgroundDashboard,
  CarouselContent,
  GreetingDashboard,
  SectionHeader,
  WalletSection,
} from '../../../components/XcalaComponents';
import CarouselDashboard from '../../../components/carouselDashboard/CarouselDashboard';
import { WalletDashboard } from '../../../components/dashboard/WalletDashboard';
import { CardPortfolioWrapper } from '../../../components/portfolio/detailsPortfolio/CardPortfolioWrapper';
import { useGetCarousel } from '../../../hooks/useCarousel';
import { useGetCashBalanceWithFallback } from '../../../hooks/useGetCashBalanceWithFallback';
import useGetModelsPortfolio from '../../../hooks/useGetModelsPortfolio';
import { modelsPortfolioChart } from '../../../utils/modelsPortfolioChart';

const Portfolio = () => {
  const { data: carouselImg, isLoading: isLoadingCarouselImg } = useGetCarousel();

  const {
    data: dataModelsPortfolio,
    loading: loadingModelsPortfolio,
    error: errorModelsPortfolio,
  } = useGetModelsPortfolio();

  const {
    data: balanceData,
    loading: loadingBalance,
    error: errorBalance,
  } = useGetCashBalanceWithFallback();

  const successDataModelsPortfolio = dataModelsPortfolio?.getModels;

  const dataPortfolio = modelsPortfolioChart(successDataModelsPortfolio);

  return (
    <BackgroundDashboard>
      <Stack
        width={'90%'}
        alignItems={'center'}
        gap={2}
        justifyContent={{
          xs: 'center',
          ms: 'center',
          md: 'flex-start',
          lg: 'flex-start',
        }}>
        <GreetingDashboard />
        <SectionHeader
          gap={'8px'}
          flexWrap={{ md: 'no-wrap', xs: 'wrap' }}
          width={'100%'}
          justifyContent={'space-between'}
          flexDirection={'row'}>
          <CarouselContent
            m={0}
            justifyContent={'center'}
            alignItems={'center'}
            minWidth={{ lg: '78%', md: '100%', sm: '100%', xs: '100%' }}
            height={'100%'}
            sx={{
              '@media (max-width: 1493px)': {
                flexDirection: 'column',
                alignItems: 'center',
                width: '100%',
              },
            }}>
            <Stack width={'100%'} justifyContent={'center'} alignItems={'center'}>
              <CarouselDashboard
                carouselImg={carouselImg}
                isLoadingCarouselImg={isLoadingCarouselImg}
              />
            </Stack>
          </CarouselContent>
          <WalletSection
            flexBasis={'1'}
            minWidth={{ lg: '21%', md: '100%', sm: '100%', xs: '100%' }}
            style={{ marginTop: 0 }}
            sx={{
              '@media (max-width: 1493px)': {
                flexDirection: 'column',
                alignItems: 'center',
                width: '100%',
              },
            }}>
            <ErrorBoundaryXcala>
              <WalletDashboard
                loadingBalance={loadingBalance}
                balanceData={balanceData}
                errorBalance={errorBalance}
              />
            </ErrorBoundaryXcala>
          </WalletSection>
        </SectionHeader>
        <Grid
          container
          width={'100%'}
          rowSpacing={2}
          columnSpacing={2}
          columns={{ xs: 4, sm: 12, md: 6, lg: 12 }}
          marginBottom={8}
          marginTop={2}
          sx={{
            '@media (max-width: 1724px)': {
              '& > *': {
                flexBasis: '33.33%',
                width: '33.33%',
              },
            },
            '@media (max-width: 1420px)': {
              '& > *': {
                flexBasis: '50%',
                width: '50%',
              },
            },
            '@media (max-width: 1150px)': {
              '& > *': {
                flexBasis: '100%',
                width: '100%',
              },
            },
          }}>
          <ErrorBoundaryXcala>
            <CardPortfolioWrapper
              loadingModelsPortfolio={loadingModelsPortfolio}
              errorModelsPortfolio={errorModelsPortfolio}
              dataPortfolio={dataPortfolio}
            />
          </ErrorBoundaryXcala>
        </Grid>
      </Stack>
    </BackgroundDashboard>
  );
};

export default Portfolio;
