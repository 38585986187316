import { Container } from '@mui/material';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import CardChangePassword from '../../../components/newchangepassword/CardChangePassword';
import HeaderChangePassword from '../../../components/newchangepassword/HeaderChangePassword';
import { changePassword, resetChangePassword } from '../../../redux/auth';

const NewChangePassword = () => {
  const dispatch = useDispatch();

  const { user, loadingChangePassword, successChangePassword, errorChangePassword } = useSelector(
    state => state.auth,
  );

  const userRisk = user?.['custom:profile'] ? user['custom:profile'].toLowerCase() : '';

  const [step, setStep] = useState(1);
  const [currentPassword, setCurrentPassword] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  //States to validate form inpunts
  const [containsUL, setContainsUL] = useState(false); // uppercase letter
  const [containsLL, setContainsLL] = useState(false); // lowercase letter
  const [containsN, setContainsN] = useState(false); // number
  const [contains8C, setContains8C] = useState(false); // min 8 characters

  let isSamePassword =
    password.trim() !== '' && confirmPassword.trim() !== '' && password === confirmPassword;

  const handleNewPassword = e => {
    if (step === 1) {
      if (currentPassword.trim() === '') {
        toast.error('Por favor, completa todos los campos.');
        return;
      }

      setStep(2);
    }
    if (step === 2) {
      if (password !== confirmPassword) {
        toast.error('Las contraseñas no coinciden');
        return;
      } else {
        dispatch(
          changePassword({
            oldPassword: currentPassword,
            newPassword: password,
          }),
        );
      }
    }
  };

  const handleConfirmPassword = value => {
    setConfirmPassword(value);
  };

  const handleCurretPassword = value => {
    setCurrentPassword(value);
  };

  //password validor
  const mustContainData = [
    ['Letras y números', containsLL, containsN],
    ['Al menos una letra mayúscula', containsUL],
    ['Mínimo 8 caracteres (Máx. 16)', contains8C],
    ['Las contraseñas deben coincidir', isSamePassword],
  ];

  const validatePassword = value => {
    setPassword(value);

    let temp_all_valid = true;

    // has lowercase letter
    if (value.toLowerCase() !== value) {
      setContainsUL(true);
    } else {
      setContainsUL(false);
      temp_all_valid = false;
    }
    if (value.toUpperCase() !== value) {
      setContainsLL(true);
      setContainsN(vall => vall);
    } else {
      setContainsLL(false);
      setContainsN(vall => vall);
      temp_all_valid = false;
    }
    // has number
    if (/\d/.test(value)) {
      setContainsN(true);
      setContainsLL(vall => vall);
    } else {
      setContainsN(false);
      setContainsLL(vall => vall);
      temp_all_valid = false;
    }

    // has 8 characters
    if (value.length >= 8 && value.length <= 16) {
      setContains8C(true);
    } else {
      setContains8C(false);
      temp_all_valid = false;
    }
    // all validations passed
    if (temp_all_valid) {
    }
  };

  //Error way of reset password
  useEffect(() => {
    if (errorChangePassword && !loadingChangePassword) {
      dispatch(resetChangePassword());
      toast.error(errorChangePassword);
      setStep(1);
      setPassword('');
      setConfirmPassword('');
      setCurrentPassword('');
      setContainsUL(false);
      setContainsLL(false);
      setContainsN(false);
      setContains8C(false);
    }
  }, [loadingChangePassword, errorChangePassword]);

  // Succes way of reset password
  useEffect(() => {
    if (successChangePassword && !loadingChangePassword) {
      dispatch(resetChangePassword());
      setStep(3);
    }
  }, [successChangePassword, loadingChangePassword]);

  return (
    <>
      <HeaderChangePassword userRisk={userRisk} user={user} />
      <Container
        maxWidth='1380px'
        sx={{
          top: '0',
          display: 'flex',
          background: '#F2F2F2',
          paddingY: '60px',
        }}>
        <CardChangePassword
          step={step}
          currentPassword={currentPassword}
          mustContainData={mustContainData}
          password={password}
          confirmPassword={confirmPassword}
          loadingChangePassword={loadingChangePassword}
          handleNewPassword={handleNewPassword}
          validatePassword={validatePassword}
          handleCurretPassword={handleCurretPassword}
          handleConfirmPassword={handleConfirmPassword}
        />
      </Container>
    </>
  );
};

export default NewChangePassword;
