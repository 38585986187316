import { Stack } from '@mui/material';
import React from 'react';
import { ButtonsPrimary } from '../../ComponentApp/buttoms';
import {
  RescueContentContent,
  SectionSelectRescue,
  SelectContent,
  TitleSectionRescue,
} from './RescueComponent';
import SelectRescueItem from './SelectRescueItem';

const CardsContinersRescue = ({
  handleAmountRescue,
  amountRescue,
  valueSelect,
  handleSubmitRescue,
}) => {
  return (
    <Stack
      sx={{
        width: '90%',
        margin: '0 auto',
        alignItems: 'center',
        mb: '25px',
        mt: '-35px',
      }}>
      <RescueContentContent
        width={{ md: '50%', sx: '80%' }}
        minHeight={{ md: '380px', sx: '400' }}
        minWidth={{ md: '740px', sx: '' }}
        mb={9}>
        <SelectContent>
          <TitleSectionRescue>¿Cuánto deseas rescatar?</TitleSectionRescue>
          <SectionSelectRescue>
            <SelectRescueItem
              valueSelect={valueSelect}
              amountRescue={amountRescue}
              handleAmountRescue={handleAmountRescue}
            />
          </SectionSelectRescue>
        </SelectContent>
        <ButtonsPrimary
          sx={{
            mt: 5,
            mr: 1,
          }}
          name='Rescate fondos de inversión'
          onClick={() => handleSubmitRescue()}
        />
      </RescueContentContent>
    </Stack>
  );
};

export default CardsContinersRescue;
