import { Box, Stack, Typography, styled, useTheme } from '@mui/material';
import { ImgComponent } from '../../SignInSignUp/ImgComponent';
import { ImgGreenComponent } from '../../SignInSignUp/ImgGreenComponent';

export const BackgroundImg = styled(Box)(({ theme }) => ({
  boxSizing: 'border-box',
  minHeight: '100%',
  background: theme.palette.primary.main,
  position: 'absolute',
  padding: '12px',
  top: '0',
  left: '0',
}));
export const ImgYellow = styled(ImgComponent)({
  boxSizing: 'border-box',
});

export const ImgGreen = styled(ImgGreenComponent)({
  boxSizing: 'border-box',
});

const SignInSignUpLayout = ({ children, questionResult }) => {
  const backgroundQuery = 'clamp(55%, 65vh, 100%)';
  const theme = useTheme();

  return (
    <>
      <Stack
        sx={{
          boxSizing: 'border-box',
          width: '100%',
          minHeight: '100vh',
          position: 'relative',
          overflow: 'hidden',
          backgroundColor: {
            sm: theme.palette.primary.main,
            md: `${questionResult ? theme.palette.primary.main : theme.palette.white.main}`,
          },
          justifyContent: `${questionResult ? 'flex-start' : 'center'}`,
          alignItems: 'center',
        }}>
        {!questionResult && (
          <Stack
            sx={{
              isolation: 'isolate',
              flexDirection: 'column',
              justifyContent: 'start',
              alignItems: 'start',
              padding: '0px',
              boxSizing: 'border-box',
              zIndex: 9,
              width: '90%',
              marginTop: 'clamp(0.75rem, 13.4821rem + -20.1905vw, 1.625rem)',
            }}>
            <Stack
              sx={{
                width: { md: '40%' },
              }}>
              <Typography
                variant='h2'
                sx={{
                  textAlign: 'left',
                  whiteSpace: 'pre-wrap',
                  fontSynthesis: 'none',
                  letterSpacing: '0px',
                  textDecoration: 'none',
                  textTransform: 'none',
                  margin: '0px',
                  color: theme.palette.white.main,
                  fontSize: theme.typography.signInWelcome.fontSize,
                  fontFamily: theme.typography.signInWelcome.fontFamily,
                  [theme.breakpoints.down('sm')]: {
                    fontSize: theme.typography.profile.fontSize,
                  },
                }}>
                ¡Bienvenido al mundo de las inversiones!
              </Typography>
              <Typography
                sx={{
                  textAlign: 'left',
                  whiteSpace: 'pre-wrap',
                  fontSynthesis: 'none',
                  letterSpacing: '0px',
                  textDecoration: 'none',
                  textTransform: 'none',
                  margin: '4px 0px 0px 0px',
                  color: theme.palette.white.main,
                  fontWeight: theme.typography.littleText.fontWeight,
                  fontSize: theme.typography.button.fontSize,
                  [theme.breakpoints.down('sm')]: {
                    fontSize: theme.typography.customButton.fontSize,
                  },
                }}>
                Invierte seguro, invierte como los que saben...
              </Typography>
            </Stack>
          </Stack>
        )}

        {children}
        <BackgroundImg
          width={{ xs: '100%', sm: '100%', md: backgroundQuery }}
          borderRadius={{ md: '0px 24px 24px 0px' }}
        />
        {questionResult ? (
          <>
            <ImgYellow />
            <ImgGreen />
          </>
        ) : (
          <>
            <ImgYellow />
            <ImgGreen />
          </>
        )}
      </Stack>
    </>
  );
};

export default SignInSignUpLayout;
