import { Button, Stack, Typography, styled } from '@mui/material';

export const TitleSectionContainer = styled(Stack)(({ theme }) => ({
  background: theme.palette.primary.newGradient,
  borderRadius: theme.shape.borderRadius,
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'row',
  width: '100%',
  height: '33px',
  justifyContent: 'flex-start',
  alignItems: 'center',
  padding: '4px 10px',
  boxSizing: 'border-box',
}));
export const TitleSection = styled(Typography)(({ theme }) => ({
  color: theme.palette.white.main,
  fontWeight: theme.typography.h1.fontWeight,
  fontSize: theme.typography.fontSize,
  textAlign: 'left',
  whiteSpace: 'pre-wrap',
  textDecoration: 'none',
  lineHeight: '126.5%',
  textTransform: 'none',
  margin: '0px',
}));

export const Card = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.white.main,
  boxShadow: '0px 0px 12px rgba(61, 64, 75, 0.18)',
  borderRadius: theme.shape.borderRadius,
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'column',
  height: '135.63px',
  width: '100%',
  minWidth: '140px',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '8px 20px',
  boxSizing: 'border-box',
  overflow: 'hidden',
}));

export const CardBody = styled(Stack)({
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flex-start',
  padding: '0px',
  boxSizing: 'border-box',
  alignSelf: 'stretch',
  margin: '0px',
  height: '97px',
  width: '100%',
});
export const Icons = styled('img')(({ theme }) => ({
  position: 'relative',
  isolation: 'isolate',
  padding: '0px',
  boxSizing: 'content-box',
  minHeight: '50px',
  width: '50px',
  margin: '0px',
  borderRadius: theme.shape.borderRadius,
}));

export const CardBodyText = styled(Stack)(({ theme }) => ({
  display: 'flex',
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  padding: '0px',
  boxSizing: 'border-box',
  margin: '8px 0px 0px 0px',
  height: '100%',
  width: '100%',
  backgroundColor: theme.palette.white.main,
}));

export const Title = styled(Typography)(({ theme }) => ({
  color: theme.palette.textLight.main,
  fontWeight: theme.typography.h1.fontWeight,
  fontSize: theme.typography.smallText.fontSize,
  textAlign: 'left',
  whiteSpace: 'pre-wrap',
  fontSynthesis: 'none',
  letterSpacing: '-0.4742029309272766px',
  textDecoration: 'none',
  lineHeight: '105%',
  textTransform: 'none',
  margin: '0px',
}));

export const AmountText = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.dark,
  fontWeight: theme.typography.h4.fontWeight,
  fontSize: theme.typography.title.fontSize,
  textAlign: 'left',
  whiteSpace: 'pre-wrap',
  letterSpacing: '-0.4742029309272766px',
  textDecoration: 'none',
  lineHeight: '26.081161499023438px',
  textTransform: 'none',
  margin: '4px 0px 0px 0px',
  [theme.breakpoints.down('sm')]: {
    fontSize: theme.typography.mediumText.fontSize,
  },
}));
export const ListProduct = styled(Stack)({
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  padding: '4px',
  boxSizing: 'border-box',
  margin: '0px 0px 0px 0px',
  overflow: 'hidden',
  width: '100%',
  borderRadius: '8px',
  overflowY: 'scroll',
});

export const IteractionAssetsFilt = styled(Stack)({
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  alignItems: 'center',
  padding: '0px',
  boxSizing: 'border-box',
  margin: '0px',
  height: '20px',
  width: '172px',
});
export const BtnFilterFound = styled(Button)(({ theme }) => ({
  fontSize: theme.typography.button.fontSize,
  fontWeight: theme.typography.h1.fontWeight,
  textAlign: 'left',
  whiteSpace: 'pre-wrap',
  letterSpacing: '0px',
  textDecoration: 'none',
  lineHeight: '126.49999856948853%',
  textTransform: 'none',
  margin: '0px',
  backgroundColor: theme.palette.transparent.main,
  '&:hover': {
    color: theme.palette.primary.blueSky,
    backgroundColor: theme.palette.transparent.main,
  },
}));
export const Divider = styled(Typography)(({ theme }) => ({
  color: theme.palette.white.main,
  fontWeight: theme.typography.h1.fontWeight,
  fontSize: theme.typography.button.fontSize,
  textAlign: 'left',
  whiteSpace: 'pre-wrap',
  fontSynthesis: 'none',
  letterSpacing: '0px',
  textDecoration: 'none',
  lineHeight: '126.49999856948853%',
  textTransform: 'none',
}));
