import { EmailRounded } from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  Input,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { message } from "antd";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import isEmail from "validator/lib/isEmail";
import SignInSignUpLayout from "../../components/Layout/SignInSignUpLayout/SignInSignUpLayout";
import {
  forgotPassword,
  resetForgotPassword,
  updateEmailTemp,
} from "../../redux/auth";

const ForgotPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const theme = useTheme();
  const { successForgotPassword, errorForgotPassword, loadingForgotPassword } =
    useSelector((state) => state.auth);

  const onFinish = () => {
    dispatch(updateEmailTemp(email));
    dispatch(forgotPassword({ username: email }));
  };

  React.useEffect(() => {
    if (errorForgotPassword && !loadingForgotPassword) {
      message.error(errorForgotPassword);
      dispatch(resetForgotPassword());
    }
  }, [loadingForgotPassword, errorForgotPassword]);

  React.useEffect(() => {
    if (successForgotPassword && !loadingForgotPassword) {
      dispatch(resetForgotPassword());
      navigate("/forgotchangepassword");
    }
  }, [loadingForgotPassword, successForgotPassword]);

  const disabled = !isEmail(email);

  return (
    <Stack
      sx={{
        boxSizing: "border-box",
        width: "100%",
        minHeight: "100vh",
        position: "relative",
        overflow: "hidden",
        padding: 0,
      }}
    >
      <SignInSignUpLayout>
        <Stack
          sx={{
            background: theme.palette.white.main,
            boxSizing: "border-box",
            borderRadius: "24px",
            padding: "32px",
            position: "relative",
            boxShadow: "0px 0px 16px rgba(0, 0, 0, 0.15)",
            minHeight: "300px",
            zIndex: 4,
            justifyContent: "center",
            alignItems: "center",
            gap: 5,
          }}
          width={{ xs: "90%", sm: "80%", md: "80%" }}
          marginTop="clamp(8.375rem, 14.3617rem + -21.2766vw, 1rem)"
          maxWidth={{ xs: "90%", md: "400px" }}
          minWidth={{ xs: "40%" }}
        >
          <Stack textAlign={"center"} width={"90%"} gap={1}>
            <Typography
              component="h3"
              sx={{
                fontSize: theme.typography.title.fontSize,
                fontWeight: theme.typography.button.fontWeight,
                color: theme.palette.primary.main,
              }}
            >
              ¿Olvidaste tu contraseña?
            </Typography>
            <Typography
              variant="body1"
              sx={{
                fontWeight: theme.typography.smallText.fontWeight,
                color: theme.palette.primary.main,
              }}
            >
              No te preocupes, a todos nos pasa...
            </Typography>
            <Typography
              textAlign={"left"}
              variant="body1"
              sx={{
                color: theme.palette.primary.darkBlue,
                fontWeight: theme.typography.smallText.fontWeight,
                fontSize: theme.typography.customButton.fontSize,
              }}
            >
              Ingresa tu email y enviaremos un correo a tu email registrado para
              que puedas configurar una nueva contraseña.
            </Typography>
          </Stack>

          <Stack
            sx={{
              width: "100%",
              background: theme.palette.white.main,
              justifyContent: "center",
              alignItems: "center",
              padding: "16px",
              gap: 3,
            }}
            minWidth={"80%"}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "flex-end",
                width: "100%",
              }}
            >
              <EmailRounded sx={{ color: "action.active", mr: 1, my: 0.5 }} />
              <Input
                fullWidth
                id="standard-basic"
                variant="standard"
                type="email"
                value={email}
                onChange={(event) => setEmail(event.target.value)}
                placeholder="Ingrese su correo electrónico"
              />
            </Box>
          </Stack>
          <Stack
            direction={{ xs: "column-reverse", md: "row" }}
            width={"100%"}
            gap={3}
          >
            <Button
              variant="outlined"
              disableElevation
              sx={{
                padding: "12px 32px",
                textTransform: "none",
              }}
              fullWidth
              onClick={() => navigate(-1)}
            >
              Volver
            </Button>
            <Button
              variant="contained"
              disableElevation
              sx={{
                padding: "12px 32px",
                textTransform: "none",
              }}
              fullWidth
              onClick={onFinish}
              disabled={disabled || loadingForgotPassword}
              endIcon={loadingForgotPassword && <CircularProgress size={20} />}
            >
              {loadingForgotPassword ? "Enviando Correo" : "Enviar correo"}
            </Button>
          </Stack>
        </Stack>
      </SignInSignUpLayout>
    </Stack>
  );
};

export default ForgotPassword;
