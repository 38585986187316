import { Stack, Typography, styled, useTheme } from '@mui/material';
import React, { useState } from 'react';

const SelectorTimeInvertio = styled(Stack)(({ theme }) => ({
  background: theme.palette.white.main,
  backdropFilter: 'blur(48px)',
  WebkitBackdropFilter: 'blur(48px)',
  boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.25)',
  borderRadius: '8px',
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'row',
  width: '100%',
  justifyContent: 'flex-start',
  alignItems: 'center',
  boxSizing: 'border-box',
  color: theme.palette.primary.darkBlue,
  transition: 'ease-in-out',
  transitionDuration: '100ms',
  cursor: 'pointer',
  '&:hover': {
    background: theme.palette.primary.profileGradient,
    backdropFilter: 'blur(48px)',
    WebkitBackdropFilter: 'blur(48px)',
    boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.25)',
    borderRadius: '8px',
    color: theme.palette.white.main,
    transition: 'ease-in-out',
    transitionDuration: '100ms',
  },
}));
const TextTitle = styled(Typography)(({ theme }) => ({
  textAlign: 'left',
  whiteSpace: 'pre-wrap',
  fontSynthesis: 'none',
  fontWeight: theme.typography.littleText.fontWeight,
  letterSpacing: '0px',
  textDecoration: 'none',
  textTransform: 'none',
  flex: '1',
  margin: '0px',
  color: 'inherit',
  [theme.breakpoints.down('sm')]: {
    fontSize: theme.typography.returnText.fontSize,
  },
  '&:hover': {
    color: theme.palette.white.main,
  },
}));
const SelectorImput = ({ TitleContent, contentProfile, selected, onSelect }) => {
  const theme = useTheme();
  const [isHovered, setIsHovered] = useState(false);
  const handleClick = (title, content) => {
    onSelect(title, content);
  };
  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <>
      <SelectorTimeInvertio
        sx={{
          padding:
            'clamp(1.25rem, -0.8333rem + 8.8889vw, 2.5rem) clamp(1rem, -0.4167rem + 4.4444vw, 1.25rem)',
        }}
        onClick={() => handleClick(TitleContent, contentProfile)}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        style={{
          background: selected
            ? theme.palette.primary.profileGradient
            : isHovered
              ? theme.palette.primary.profileGradient
              : theme.palette.white.main,
          color: selected
            ? theme.palette.white.main
            : isHovered
              ? 'rgba(255, 255, 255, .5)'
              : theme.palette.primary.darkBlue,
          boxShadow:
            selected || isHovered
              ? '0px 0px 8px rgba(0, 0, 0, 0.25)'
              : '0px 0px 8px rgba(0, 0, 0, 0.25)',
        }}>
        <TextTitle fontSize={theme.typography.headerText.fontSize}>{TitleContent}</TextTitle>
      </SelectorTimeInvertio>
    </>
  );
};

export default SelectorImput;
