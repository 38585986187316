import DoneIcon from '@mui/icons-material/Done';
import { Box, Stack, Typography, styled, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import TagManager from 'react-gtm-module';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import LayoutProfile from '../../../components/Layout/InternalLayout/LayoutProfile';
import Spinner from '../../../components/Spinner';
import ModalErrorFetch from '../../../components/UI/ModalErrorFetch';
import useModal from '../../../hooks/custom/useModal';
import useGetConstants from '../../../hooks/useGetConstants';
import { resetUpdateUserAttributesSubmit } from '../../../redux/auth';
import {
  setCivilStatus,
  setGenders,
  setMoneyIncomes,
  setMoneyOrigin,
  setMonths,
  setNationalities,
  setProfessions,
  setRegions,
  setcountryPhones,
} from '../../../redux/constants';
import { onRefresh } from '../../../utils/refreshPage';
import { ButtonsPrimary, ButtonsTertiary } from '../../../components/ComponentApp/buttoms';

const ContainerRiskProfile = styled(Box)({
  display: 'flex',
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '0px',
  boxSizing: 'border-box',
  flex: '1',
  margin: '0px',
  height: '361px',
  width: '100%',
});
const HeaderCardRiskProf = styled(Stack)({
  position: 'relative',
  isolation: 'isolate',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '0px',
  boxSizing: 'border-box',
  margin: '0px',
  width: '100%',
});
const TitleRiskProfile = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.dark,
  fontWeight: theme.typography.littleText.fontWeight,
  fontSize: theme.typography.h1.fontSize,
  [theme.breakpoints.down('sm')]: {
    fontSize: theme.typography.mediumText.fontSize,
  },
  lineHeight: '105%',
  textDecoration: 'none',
  textTransform: 'none',
}));
const TitleRiskProfileSpan = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.dark,
  fontWeight: theme.typography.smallText.fontWeight,
  fontSize: theme.typography.mediumText.fontSize,
  [theme.breakpoints.down('sm')]: {
    fontSize: theme.typography.smallText.fontSize,
  },
  letterSpacing: '0px',
  textDecoration: 'none',
  textTransform: 'none',
}));
export const IconsRiskProfile = styled('img')({
  objectFit: 'cover',
});
const BtnsRiskProfile = styled(Stack)({
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '0px',
  boxSizing: 'border-box',
  margin: '32px 0px 0px 0px',
  maxWidth: { md: '60%' },
});

const Profile = () => {
  const { isShowing, toggle } = useModal();
  const theme = useTheme();
  const {
    loadingUpdateUserAttributes,
    successUpdateUserAttributes,
    errorUpdateUserAttributes,
    user,
  } = useSelector(state => state.auth);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { data: constants, error: errorConstants, loading: loadingConstants } = useGetConstants();

  useEffect(() => {
    if (successUpdateUserAttributes && !loadingUpdateUserAttributes) {
      dispatch(resetUpdateUserAttributesSubmit());
      navigate('/myprofilerisk');
    }
  }, [loadingUpdateUserAttributes, successUpdateUserAttributes]);

  useEffect(() => {
    if (constants && !loadingConstants) {
      dispatch(setcountryPhones(constants.getCountryPhones));
      dispatch(setGenders(constants.getGenders));
      dispatch(setMonths(constants.getMonths));
      dispatch(setCivilStatus(constants.getCivilStatus));
      dispatch(setProfessions(constants.getProfessions));
      dispatch(setMoneyIncomes(constants.getMoneyIncomes));
      dispatch(setMoneyOrigin(constants.getMoneyOrigin));
      dispatch(setNationalities(constants.getNationalities));
      dispatch(setRegions(constants.getRegions));
    }
  }, [constants, loadingConstants]);

  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'onboarding profile',
        path: '/profile',
        link: {
          url: '/profile',
          title: 'onboarding',
        },
      },
    });
  }, []);

  if (loadingUpdateUserAttributes) {
    return <Spinner />;
  }
  if (errorUpdateUserAttributes) {
    return (
      <ModalErrorFetch
        message='Oh! parece que esto no funcionó bien...'
        error={errorUpdateUserAttributes}
        open={!isShowing}
        onClose={toggle}
        refresh={onRefresh}
      />
    );
  }

  return (
    <>
      <Stack
        sx={{
          boxSizing: 'border-box',
          width: '100%',
          minHeight: '100vh',
          position: 'relative',
          overflow: 'hidden',
          padding: 0,
          margin: 0,
        }}>
        <LayoutProfile user={user} profile={true}>
          <Stack
            sx={{
              width: '100%',
              boxSizing: 'border-box',
              flexDirection: 'column',
              padding: 'clamp(1rem, -1.8571rem + 12.1905vw, 3rem);',
              bgcolor: theme.palette.white.main,
              borderRadius: '1rem',
              boxShadow: '0px 0px 1rem 0px rgba(0, 0, 0, 0.1)',
            }}>
            <ContainerRiskProfile>
              <HeaderCardRiskProf
                flexDirection={{
                  xs: 'column-reverse',
                  md: 'row',
                  gap: '0.6rem',
                }}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    md: '80%',
                    gap: { xs: '0.6rem', md: '0.3rem' },
                  }}>
                  <TitleRiskProfile>
                    En este proceso aprenderás a definir cual es el perfil de riesgo que se adecua a
                    tus necesidades.
                  </TitleRiskProfile>
                  <TitleRiskProfileSpan>
                    Puedes volver a realizar el cuestionario en cualquier momento dentro de Xcala.
                  </TitleRiskProfileSpan>
                </Box>
                <Box maxWidth={{ md: '20%' }}>
                  <IconsRiskProfile
                    sx={{
                      width: 'clamp(5.1875rem, 1.4375rem + 16vw, 10.4375rem);',
                    }}
                    src={'/assets/images/perfil-noDefined.svg'}
                  />
                </Box>
              </HeaderCardRiskProf>
              <BtnsRiskProfile sx={{ gap: { xs: '2rem', md: '2rem' } }}>
                <ButtonsPrimary
                  name='Definir mi perfil de Riesgo'
                  onClick={() => navigate('/questions')}
                  icon={<DoneIcon />}
                />
                <ButtonsTertiary
                  name='Ya se cual es mi perfil de riesgo'
                  onClick={() => navigate('/profileselect')}
                />
              </BtnsRiskProfile>
            </ContainerRiskProfile>
          </Stack>
        </LayoutProfile>
      </Stack>
    </>
  );
};

export default Profile;
