import { Box, Stack, Typography, styled, useTheme } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import capitalize from '../../../utils/capitalize';
import TruncatedText from '../../UI/TruncatedText';
import { BtnGroup } from '../../portfolio/detailsPortfolio/distributionFunds/BtnGroup';
import ChartnvestmentContainerProductsDetail from './ChartnvestmentContainerProductsDetail';
import SectionGeneralConditionDetail from './SectionGeneralConditionDetail';

const CardsContentDocumentacion = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.white.main,
  boxShadow: '0px 0px 8px rgba(61, 64, 75, 0.15)',
  borderRadius: '16px',
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'column',
  minWidth: '310px',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  padding: '0px',
  boxSizing: 'border-box',
  overflow: 'hidden',
  minHeight: '188px',
}));
const HeaderComponent = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  isolation: 'isolate',
  justifyContent: 'center',
  alignItems: 'flex-start',
  padding: '8px 16px',
  boxSizing: 'border-box',
  alignSelf: 'stretch',
  margin: '0px',
  height: '80px',
  width: '100%',
  flexDirection: 'column',
}));
const TitleCardsDocumentation = styled(Typography)(({ theme }) => ({
  whiteSpace: 'pre-wrap',
  color: theme.palette.white.main,
  fontWeight: theme.typography.button.fontWeight,
  fontSize: theme.typography.h1.fontSize,
  letterSpacing: '0px',
  textDecoration: 'none',
  lineHeight: '105%',
  textTransform: 'none',
}));

const BodyComponent = styled(Stack)({
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flex-start',
  boxSizing: 'border-box',
  alignSelf: 'stretch',
  minHeight: '104px',
  margin: '8px 0px 0px 0px',
  width: '100%',
});

const BodyDetailProduct = ({
  doGetSecurityDividends,
  securitySelected,
  description,
  conditions,
  active,
  onChangeMonths,
  loadingGetPriceRange,
  currency,
  custom,
  chartData,
  dividends,
  showLabels,
  successGetPriceRange,
  setCurrency,
  updateChartData,
  documents,
  showRiskWarning,
  handleInvestment,
  handleSimulation,
  successGetExchangeRateHistory,
  loadingGetExchangeRateHistory,
  loadingGetExchangeRate,
  errorGetPriceRange,
}) => {
  const theme = useTheme();
  const user = useSelector(state => state.auth.user);
  return (
    <Stack
      sx={{
        width: '90%',
        borderRadius: '16px',
        position: 'relative',
        marginX: 'auto',
        isolation: 'isolate',
        marginBottom: '4rem',
      }}
      gap={4}>
      <Stack>
        <Typography
          sx={{
            color: theme.palette.primary.dark,
            fontSize: theme.typography.title.fontSize,
            fontWeight: theme.typography.titleText.fontWeight,
            marginBottom: 1,
          }}>
          Descripción
        </Typography>
        <TruncatedText seeMore='[+]' seeLess='[-]' text={description} limit={100} />
      </Stack>

      <Stack sx={{ width: '100%' }} gap={2} flexDirection={{ md: 'row', xs: 'column' }}>
        <Stack
          flex={'1 0.5 auto'}
          sx={{
            width: '100%',
            borderRadius: '8px',
          }}>
          <ChartnvestmentContainerProductsDetail
            onChangeMonths={onChangeMonths}
            active={active}
            loadingGetPriceRange={loadingGetPriceRange}
            currency={currency}
            custom={custom}
            chartData={chartData}
            dividends={dividends}
            showLabels={showLabels}
            successGetPriceRange={successGetPriceRange}
            setCurrency={setCurrency}
            updateChartData={updateChartData}
            successGetExchangeRateHistory={successGetExchangeRateHistory}
            loadingGetExchangeRateHistory={loadingGetExchangeRateHistory}
            doGetSecurityDividends={doGetSecurityDividends}
            securitySelected={securitySelected}
            loadingGetExchangeRate={loadingGetExchangeRate}
            errorGetPriceRange={errorGetPriceRange}
          />
        </Stack>
        <Stack
          flex={'1 1 auto'}
          sx={{
            backgroundColor: theme.palette.white.main,
            width: '100%',
            minHeight: { md: '551px', xs: 0 },
            minWidth: { md: '400px', xs: '100%' },
          }}>
          <SectionGeneralConditionDetail conditions={conditions} />
        </Stack>
      </Stack>
      <Stack
        width={'100%'}
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
          paddingY: { md: '40px', xs: '20px' },
        }}>
        <BtnGroup
          goToSimulator={() => handleSimulation()}
          goToInvestment={() => handleInvestment()}
        />
      </Stack>

      {showRiskWarning && (
        <Box
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          <img src='/assets/images/warning.svg' alt='risk' style={{ display: 'inline' }} />
          <Typography style={{ color: theme.palette.red.main, marginLeft: '10px' }}>
            Este fondo supera el riesgo definido en tu perfil {capitalize(user['custom:profile'])}
          </Typography>
        </Box>
      )}

      <Stack
        width={'100%'}
        borderRadius={2}
        flexDirection={'row'}
        gap={2}
        flexWrap={'wrap'}
        justifyContent={'flex-start'}
        alignItems={'center'}>
        <CardsContentDocumentacion
          width={{
            xs: '100%',
            sm: '100%',
            md: '100%',
            lg: '100%',
          }}>
          <HeaderComponent flexDirection={'column'} gap={0} p={2}>
            <TitleCardsDocumentation>Documentación de interés</TitleCardsDocumentation>
          </HeaderComponent>

          <BodyComponent
            sx={{
              flexDirection: { xs: 'column', md: 'row' },
              justifyContent: 'space-evenly',
              alignItems: 'center',
              padding: { xs: '10px' },
            }}>
            {documents.map((document, index) => {
              return (
                <Stack
                  key={document.id || index}
                  direction={'row'}
                  sx={{
                    alignItems: 'center',
                    width: { xs: '100%', md: '20%' },
                    padding: { xs: '5px' },
                  }}
                  onClick={() => window.open(document.value, '_blank')}>
                  <Stack sx={{ height: '25px', width: '25px', cursor: 'pointer' }}>
                    <img src='/assets/images/pdf.svg' alt='' width={'100%'} height={'100%'} />
                  </Stack>
                  <Typography
                    component={'span'}
                    sx={{
                      marginLeft: '10px',
                      color: theme.palette.primary.dark,
                      cursor: 'pointer',
                    }}>
                    {document.data}
                  </Typography>
                </Stack>
              );
            })}
          </BodyComponent>
        </CardsContentDocumentacion>
      </Stack>
    </Stack>
  );
};

export default BodyDetailProduct;
