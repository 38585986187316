import { Checkbox, Stack, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import useGetRegistrationType from '../../hooks/useGetRegistrationType';
import { updateAttributes } from '../../redux/onboarding';
import { ButtonsPrimary } from '../ComponentApp/buttoms/ButtonsXcala';
import { CivilStatusSelectOB } from './InputsSelect/CivilStatusSelectOB';
import { CommuneSelectOB } from './InputsSelect/CommuneSelectOB';
import { GenderSelectOB } from './InputsSelect/GenderSelectOB';
import { MoneyIncomeSelectOB } from './InputsSelect/MoneyIncomeSelectOB';
import { MoneyOriginSelectOB } from './InputsSelect/MoneyOriginSelectOB';
import { NationalitySelectOB } from './InputsSelect/NationalitySelectOB';
import { ProfesionSelectOB } from './InputsSelect/ProfesionSelectOB';
import { RegionSelectOB } from './InputsSelect/RegionSelectOB';

export const StepOneFormOmboarding = ({ setstepper, setSteppercolor }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const onBoardingData = useSelector(state => state.onboarding.data);
  const { user } = useSelector(state => state.auth);
  const [touched, setTouched] = useState(false);
  const [typeId, setTypeId] = React.useState(0);
  const [checked, setChecked] = React.useState(false);

  const [
    doGetRegistrationType,
    { data, error: errorGetRegistrationType, loading: loadingGetRegistrationType },
  ] = useGetRegistrationType();

  const successGetRegistrationType = data?.getRegistrationType;

  const getValue = name => {
    const obj = onBoardingData?.detail?.find(item => item.Name === name);
    return obj?.Value ? obj.Value : '';
  };

  const [userData, setUserData] = React.useState({
    gender: getValue('gender'),
    $nationality: getValue('custom:nationality'),
    $dni: user['custom:dni'],
    $expiration: getValue('custom:expiration'),
    $city: getValue('custom:city'),
    $commune: getValue('custom:commune'),
    $street: getValue('custom:street'),
    $department: getValue('custom:department'),
    $civil_status: getValue('custom:civil-status'),
    $profession: getValue('custom:profession'),
    $money_income: getValue('custom:money-income'),
    $money_origin: getValue('custom:money-origin'),
  });

  React.useEffect(() => {
    doGetRegistrationType({ variables: { data: 'creation' } });
  }, []);

  React.useEffect(() => {
    if (successGetRegistrationType && !loadingGetRegistrationType) {
      setTypeId(successGetRegistrationType);
    }
  }, [successGetRegistrationType, loadingGetRegistrationType]);

  React.useEffect(() => {
    if (errorGetRegistrationType && !loadingGetRegistrationType) {
      navigate('/error');
    }
  }, [errorGetRegistrationType, loadingGetRegistrationType]);
  useEffect(() => {
    if (userData.$nationality.name === 'Chile' || !userData.$expiration) {
      setUserData({
        ...userData,
        $expiration: false,
      });
    }
  }, [userData.$nationality]);

  const handleChange = e => {
    setUserData({ ...userData, $street: e.target.value });
    if (!touched) {
      setTouched(true);
    }
  };

  const handleBlur = e => {
    if (!touched) {
      setTouched(true);
    }
  };

  const secondStep = () => {
    let attributes = [];
    Object.keys(userData).map((item, index, arr) => {
      const name = item.replace('$', 'custom:').replace('_', '-');
      const value = userData[item] !== '' ? userData[item] : null;

      if (value !== null) {
        attributes.push({ Name: name, Value: value });
      }
    });

    const department = userData.$department ? userData.$department : '';
    const address = `${userData.$street} ${department}, ${userData.$commune.name}, ${userData.$city.name}`;
    attributes.push({ Name: 'address', Value: address });

    const data = {
      registration_type_id: typeId,
      detail: attributes,
    };

    dispatch(updateAttributes(data));

    setstepper(2);
    setSteppercolor(first => {
      return {
        ...first,
        comp1: true,
        comp2: true,
      };
    });
  };

  const getShowDisclaimer = () => {
    if (userData.$civil_status.name === 'Casado/a' && userData.gender.name === 'Femenino') {
      return true;
    }
    return false;
  };
  const showDisclaimer = getShowDisclaimer();

  let disabled =
    !userData.gender ||
    !userData.$nationality ||
    !userData.$city ||
    !userData.$commune ||
    !userData.$street ||
    !userData.$civil_status ||
    !userData.$profession ||
    !userData.$money_income ||
    !userData.$money_origin ||
    loadingGetRegistrationType ||
    typeId === 0;

  disabled = showDisclaimer ? !checked || disabled : disabled;

  return (
    <>
      <Stack width={'100%'}>
        <form style={{ width: '100%' }}>
          <Stack gap={{ md: 2, xs: 4 }}>
            <Stack
              direction={{ md: 'row', xs: 'column' }}
              gap={4}
              width={'100%'}
              justifyContent={'space-between'}>
              <Stack width={{ md: '50%', xs: '100%' }}>
                <TextField
                  fullWidth
                  disabled
                  id='dni'
                  label='RUT'
                  variant='standard'
                  type='text'
                  value={userData.$dni}
                />
              </Stack>

              <Stack width={'50%'} sx={{ display: { md: 'flex', xs: 'none' } }} />
            </Stack>

            <Stack
              direction={{ md: 'row', xs: 'column' }}
              gap={4}
              width={'100%'}
              justifyContent={'space-between'}>
              <GenderSelectOB
                selected={userData.gender}
                setSelected={value => setUserData({ ...userData, gender: value })}
              />

              <NationalitySelectOB
                selected={userData.$nationality}
                setSelected={value => {
                  setUserData({ ...userData, $nationality: value });
                }}
              />
            </Stack>

            <Stack
              direction={{ md: 'row', xs: 'column' }}
              gap={4}
              width={'100%'}
              justifyContent={'space-between'}>
              <RegionSelectOB
                selected={userData.$city}
                setSelected={value =>
                  setUserData({
                    ...userData,
                    $city: value,
                    $commune: '',
                  })
                }
              />

              <CommuneSelectOB
                selected={userData.$commune}
                setSelected={value => setUserData({ ...userData, $commune: value })}
                items={userData.$city.communes || []}
              />
            </Stack>
            <Stack
              direction={{ md: 'row', xs: 'column' }}
              gap={4}
              width={'100%'}
              justifyContent={'space-between'}>
              <TextField
                fullWidth
                id='Calle'
                label='Calle y número'
                variant='standard'
                type='text'
                value={userData.$street}
                onChange={handleChange}
                onBlur={handleBlur}
                helperText={
                  touched && userData.$street === '' ? (
                    <span style={{ color: '#f44336' }}>Completa tu dirección</span>
                  ) : (
                    ''
                  )
                }
              />
              <TextField
                fullWidth
                id='Casa-Dpto.'
                label='Casa/Dpto.'
                variant='standard'
                type='text'
                value={userData.$department}
                onInput={e => setUserData({ ...userData, $department: e.target.value })}
              />
            </Stack>
            <Stack
              direction={{ md: 'row', xs: 'column' }}
              gap={4}
              width={'100%'}
              justifyContent={'space-between'}>
              <CivilStatusSelectOB
                selected={userData.$civil_status}
                setSelected={value => setUserData({ ...userData, $civil_status: value })}
              />

              <ProfesionSelectOB
                selected={userData.$profession}
                setSelected={value => setUserData({ ...userData, $profession: value })}
              />
            </Stack>
            <Stack
              direction={{ md: 'row', xs: 'column' }}
              gap={4}
              width={'100%'}
              justifyContent={'space-between'}>
              <MoneyIncomeSelectOB
                selected={userData.$money_income}
                setSelected={value => setUserData({ ...userData, $money_income: value })}
              />
              <MoneyOriginSelectOB
                selected={userData.$money_origin}
                setSelected={value => setUserData({ ...userData, $money_origin: value })}
              />
            </Stack>

            <Stack spacing={{ md: 0, xs: 2 }}>
              {showDisclaimer && (
                <Stack direction={'row'} alignItems={'center'}>
                  <Checkbox checked={checked} onChange={e => setChecked(e.target.checked)} />

                  <Typography sx={{ fontSize: '1rem', color: '#182849' }}>
                    Declaro hacer uso de los{' '}
                    <Typography
                      component={'span'}
                      sx={{
                        fontSize: '1rem',
                        color: '#49a197',
                        fontWeight: '600',
                      }}>
                      fondos asociados a mi patrimonio reservado
                    </Typography>
                  </Typography>
                </Stack>
              )}
            </Stack>

            <Stack
              width={'100%'}
              sx={{
                justifyContent: 'center',
                alignItems: 'center',
              }}>
              <Stack width={{ md: '40%', xs: '100%' }}>
                <ButtonsPrimary
                  name='Continuar'
                  disabled={disabled}
                  onClick={secondStep}
                  fullWidth
                />
              </Stack>
            </Stack>
          </Stack>
        </form>
      </Stack>
    </>
  );
};
