import { Box, Stack, Typography, useTheme } from '@mui/material';
import { hideNumbers } from '../../utils/hideNumbers';
import WrapperBackgroundBanner from '../XcalaComponents/WrapperBackgroundBanner';
import WrapperContentApp from '../XcalaComponents/WrapperContentApp';
import AvatarSection from '../myTransactions/AvatarSection';
import { HeadTitle } from '../myTransactions/MyNewTransactionsMuiComponents';
import { memo } from 'react';

const $minHeigth = '407px';

const fileAs = {
  currency: 'pesos',
  fileAs: 'file as',
  fileAsComplete: 'file as complete',
  risk: 'agresivo',
  profit: '10',
};

const HeaderChangePassword = memo(({ userRisk, user }) => {
  const theme = useTheme();
  const {
    name: nameUser,
    email: emailUser,
    'custom:dni': userDni,
    'custom:paternal-surname': lastNameUser,
  } = user;
  return (
    <>
      <WrapperBackgroundBanner $minHeigth={$minHeigth}>
        <WrapperContentApp $minHeigth={$minHeigth} fileAs={fileAs}>
          <Stack width={'100%'} direction={'column'}>
            <Stack
              direction={{ md: 'row', xs: 'column' }}
              justifyContent={'center'}
              alignItems={{ md: 'center', xs: 'center' }}
              width={{ md: '100%', xs: '100%' }}
              gap={1}>
              <AvatarSection userRisk={userRisk} />
              <Box>
                <HeadTitle textAlign={{ md: 'left', xs: 'center' }}>
                  {`${nameUser[0]?.toUpperCase() + nameUser.slice(1)} ${
                    lastNameUser[0]?.toUpperCase() + lastNameUser.slice(1)
                  } `}
                </HeadTitle>
                <Typography
                  color={theme.palette.white.main}
                  fontWeight={'100'}
                  textAlign={{ md: 'left', xs: 'center' }}
                  fontSize={theme.typography.mediumText.fontSize}>
                  {emailUser}
                </Typography>
                <Typography
                  color={theme.palette.white.main}
                  fontWeight={'100'}
                  textAlign={{ md: 'left', xs: 'center' }}
                  fontSize={theme.typography.mediumText.fontSize}>
                  {hideNumbers(userDni)}
                </Typography>
              </Box>
            </Stack>
          </Stack>
        </WrapperContentApp>
      </WrapperBackgroundBanner>
    </>
  );
});

export default HeaderChangePassword;
