import { Container } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import BodyTransactionsWallet from '../../../components/wallet/BodyTransactionsWallet';
import BtnGroupWallet from '../../../components/wallet/BtnGroupWallet';
import HeaderWallet from '../../../components/wallet/HeaderWallet';
import { useGetCashBalancePortfolio } from '../../../hooks/useGetCashBalancePortfolio';
import { useGetClientTransactionsWallet } from '../../../hooks/useGetClientTransactionsWallet';
import capitalize from '../../../utils/capitalize';

const currencyFormatter = new Intl.NumberFormat('es-CL', {
  style: 'currency',
  currency: 'CLP',
});

const clpPrice = {
  type: 'number',
  width: 130,
  valueFormatter: ({ value }) => currencyFormatter.format(value),
  cellClassName: 'font-tabular-nums',
};
const column = [
  {
    field: 'fecha',
    headerName: 'Fecha',
    width: 120,
    flex: '0.5 1 120px',
    hide: true,
  },
  {
    field: 'movimiento',
    headerName: 'Movimientos',
    flex: '0.6 2 80px',
    width: 160,
    cellClassName: 'super-app-theme--cell',
  },
  {
    field: 'monto',
    ...clpPrice,
    headerName: 'Monto',
    flex: '0.5 1 60',
    width: 120,
  },
];

const Wallet = () => {
  const { user } = useSelector(state => state.auth);

  const paternalName = user?.['custom:paternal-surname'] || '';
  const maternalName = user?.['custom:maternal-surname'] || '';
  const fullName =
    user && `${capitalize(user.name)} ${capitalize(paternalName)} ${capitalize(maternalName)}`;

  const userRisk = user?.['custom:profile'] ? user['custom:profile'].toLowerCase() : '';

  const { 'custom:dni': userDni, 'custom:paternal-surname': lastNameUser } = user;

  const {
    data: transactionsData,
    loading: loadingTransactions,
    error: errorTransactions,
    refetch: refetchTransactions,
  } = useGetClientTransactionsWallet({ status: '2,4' });

  const transactions = transactionsData?.getClientTransactions;

  const {
    data: balanceData,
    loading: loadingBalance,
    error: errorBalance,
    refetch: refetchBalanceData,
  } = useGetCashBalancePortfolio();

  const balanceClient = balanceData?.getCashBalancePortfolio;

  return (
    <>
      <HeaderWallet
        balanceClient={balanceClient}
        fullName={fullName}
        userRisk={userRisk}
        isWallet={true}
        loadingBalance={loadingBalance}
        errorBalance={errorBalance}
      />
      <Container
        maxWidth='1380px'
        sx={{
          marginTop: '0',
          alignItems: 'center',
          background: '#F2F2F2',
          paddingY: '60px',
        }}>
        <BtnGroupWallet
          refetchTransactions={refetchTransactions}
          refetchBalanceData={refetchBalanceData}
          balanceData={balanceData}
          transactions={transactions}
        />
        <BodyTransactionsWallet
          column={column}
          titleText={'Transacciones'}
          transactions={transactions}
          loadingTransactions={loadingTransactions}
          errorTransactions={errorTransactions}
        />
      </Container>
    </>
  );
};

export default Wallet;
