import { gql, useLazyQuery, useQuery } from '@apollo/client';

const GET_SECURITY_LIST_QUERY = gql`
  query getSecurityList {
    getSecurityList {
      ID
      SubType
      Name
      FileAs
      Symbol
      Custom
    }
  }
`;
{
  /*Trae lista de security de los fondos con Reglamento y el Folleto Regulatorio para cada fondo**/
}
export default () =>
  useQuery(GET_SECURITY_LIST_QUERY, { fetchPolicy: 'no-cache', errorPolicy: 'all' });
