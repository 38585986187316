import {
  FormControl,
  FormControlLabel,
  InputAdornment,
  Radio,
  RadioGroup,
  TextField,
  useTheme,
} from '@mui/material';
import React from 'react';

const SelectRescueItem = ({ handleAmountRescue, amountRescue, valueSelect }) => {
  const theme = useTheme();
  return (
    <form style={{ width: '100%' }}>
      <FormControl sx={{ width: '100%' }} variant='standard'>
        <RadioGroup
          sx={{
            color: theme.palette.textLight.main,
            '& .MuiSvgIcon-root': {
              color: theme.palette.primary.main,
            },
          }}
          aria-labelledby='demo-error-radios'
          name='quiz'
          onChange={e => handleAmountRescue(e)}
          value={valueSelect}>
          <FormControlLabel
            sx={{ color: theme.palette.textLight.main }}
            value={'50'}
            control={<Radio />}
            label='Rescate parcial 50% del valor del Portfolio'
          />
          <FormControlLabel
            sx={{ color: theme.palette.textLight.main }}
            value={'100'}
            control={<Radio />}
            label='Rescate total'
          />
        </RadioGroup>

        <TextField
          InputProps={{
            startAdornment: <InputAdornment position='start'>$</InputAdornment>,
          }}
          disabled
          hiddenLabel
          id='filled-hidden-label-normal'
          variant='standard'
          value={amountRescue}
        />
      </FormControl>
    </form>
  );
};

export default SelectRescueItem;
