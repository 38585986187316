import {
  Box,
  Button,
  Checkbox,
  Stack,
  TextField,
  Typography,
  styled,
  useTheme,
} from '@mui/material';
import { useState } from 'react';
import { numberWithPoint } from '../../../utils/numbers';

const ContainerModal = styled(Stack)({
  position: 'absolute',
  top: '0px',
  left: '0px',
  width: '100%',
  height: '100%',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: '999',
  backgroundColor: 'rgba(0, 0, 0, 0.8)',
  backdropFilter: 'blur(4px)',
  WebkitBackdropFilter: 'blur(4px)',
});
const MuntRescueModal = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.white.main,
  borderRadius: '16px',
  display: 'flex',
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '34px',
  boxSizing: 'border-box',
}));

const ModalContainer = styled(Stack)({
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  padding: '0px',
  boxSizing: 'border-box',
  flex: '1',
  margin: '0px',
  width: '100%',
});
const TitleContainer = styled(Stack)({
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  padding: '0px',
  boxSizing: 'border-box',
  alignSelf: 'stretch',
  margin: '0px',
  width: '100%',
});
const NameFundContainer = styled(Stack)({
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  padding: '0px',
  boxSizing: 'border-box',
  alignSelf: 'stretch',
  margin: '0px',
  width: '100%',
});
const FundText = styled(Typography)(({ theme }) => ({
  textAlign: 'left',
  whiteSpace: 'pre-wrap',
  fontSynthesis: 'none',
  color: theme.palette.primary.darkBlue,
  fontWeight: theme.typography.littleText.fontWeight,
  fontSize: theme.typography.mediumText.fontSize,
  letterSpacing: '0px',
  textDecoration: 'none',
  textTransform: 'none',
  margin: '0px',
}));
const FundTitle = styled(Typography)(({ theme }) => ({
  textAlign: 'left',
  whiteSpace: 'pre-wrap',
  fontSynthesis: 'none',
  color: theme.palette.primary.darkBlue,
  fontWeight: theme.typography.button.fontWeight,
  letterSpacing: '0px',
  textDecoration: 'none',
  textTransform: 'none',
  margin: '0px',
}));
const MuntCashContiner = styled(Stack)(({ theme }) => ({
  background: theme.palette.primary.gradient,
  borderRadius: '8px',
  display: 'flex',
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  padding: '16px',
  boxSizing: 'border-box',
  alignSelf: 'stretch',
  margin: '4px 0px 0px 0px',
  width: '100%',
}));
const MuntContainer = styled(Stack)({
  display: 'flex',
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '0px',
  boxSizing: 'border-box',
  alignSelf: 'stretch',
  margin: '0px',
  height: '17px',
  width: '100%',
});
const MuntContext = styled(Typography)(({ theme }) => ({
  textAlign: 'left',
  whiteSpace: 'pre-wrap',
  fontSynthesis: 'none',
  color: theme.palette.white.main,
  fontWeight: theme.typography.littleText.fontWeight,
  letterSpacing: '0px',
  textDecoration: 'none',
  textTransform: 'none',
  margin: '0px',
}));
const ValueMunt = styled(Typography)(({ theme }) => ({
  textAlign: 'left',
  whiteSpace: 'pre-wrap',
  fontSynthesis: 'none',
  color: theme.palette.white.main,
  fontWeight: theme.typography.button.fontWeight,
  letterSpacing: '0px',
  textDecoration: 'none',
  textTransform: 'none',
  margin: '0px',
}));
const TypeChange = styled(Typography)(({ theme }) => ({
  textAlign: 'left',
  whiteSpace: 'pre-wrap',
  fontSynthesis: 'none',
  color: theme.palette.textLight.main,
  fontWeight: theme.typography.littleText.fontWeight,
  letterSpacing: '0px',
  textDecoration: 'none',
  textTransform: 'none',
  margin: '5px 0px 0px 0px',
}));

const SubtitleModal = styled(Typography)(({ theme }) => ({
  textAlign: 'left',
  whiteSpace: 'pre-wrap',
  fontSynthesis: 'none',
  color: theme.palette.primary.main,
  fontWeight: theme.typography.button.fontWeight,
  letterSpacing: '0px',
  textDecoration: 'none',
  textTransform: 'none',
  alignSelf: 'stretch',
  margin: '8px 0px 0px 0px',
}));
const BankCantainer = styled('div')({
  display: 'flex',
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flex-start',
  padding: '0px',
  boxSizing: 'border-box',
  width: '100%',
  margin: '8px 0px 16px 0px',
});

const BtnContainer = styled(Stack)({
  position: 'relative',
  isolation: 'isolate',
  justifyContent: 'center',
  padding: '0px',
  boxSizing: 'border-box',
  margin: '8px 0px 0px 0px',
  width: '80%',
});

const MsnAlert = styled(Stack)(({ theme }) => ({
  border: `1px solid ${theme.palette.textLightBlue}`,
  boxSizing: 'border-box',
  borderRadius: '8px',
  display: 'flex',
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'column',
  width: '100%',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  padding: '10px 8px',
  gap: '16px',
  backgroundColor: theme.palette.primary.textLightBlue,
}));

const TitleAlert = styled(Stack)({
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  padding: '0px',
  boxSizing: 'border-box',
  alignSelf: 'stretch',
  margin: '0px',
  height: '24px',
  width: '100%',
});

const WarningAmber = styled(Box)({
  width: '24px',
  height: '24px',
  margin: '0px',
});

const TextImportante = styled(Typography)({
  textAlign: 'left',
  whiteSpace: 'pre-wrap',
  fontSynthesis: 'none',
  color: 'rgb(1, 67, 97)',
  fontWeight: '600',
  letterSpacing: '0px',
  textDecoration: 'none',
  textTransform: 'none',
  width: '115.5px',
  margin: '0px 0px 0px 16px',
});

const IconsWarnning = () => {
  return (
    <svg width='25' height='24' viewBox='0 0 25 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g id='warning_amber'>
        <g id='Vector'>
          <path
            d='M12.3044 6.49L19.8344 19.5H4.77444L12.3044 6.49ZM12.3044 2.5L1.30444 21.5H23.3044L12.3044 2.5Z'
            fill='rgb(1, 67, 97)'
          />
          <path d='M13.3044 16.5H11.3044V18.5H13.3044V16.5Z' fill='rgb(1, 67, 97)' />
          <path d='M13.3044 10.5H11.3044V15.5H13.3044V10.5Z' fill='rgb(1, 67, 97)' />
        </g>
      </g>
    </svg>
  );
};

const ParrafoAlert = styled(Typography)(({ theme }) => ({
  textAlign: 'left',
  whiteSpace: 'pre-wrap',
  fontSynthesis: 'none',
  color: theme.palette.primary.darkBlue,
  fontWeight: theme.typography.littleText.fontWeight,
  letterSpacing: '0px',
  textDecoration: 'none',
  textTransform: 'none',
  alignSelf: 'stretch',
  margin: '0px auto',
}));

const ModalWithdrawal = ({
  sectionTitle = false,
  typeFund = 'Fondo',
  titleFund = 'Xcala Balanceado Global',
  valorCuota = 0,
  handleCloseModal,
  amountRescue,
  clientBankDetail,
  handleSubmit,
}) => {
  const theme = useTheme();
  const [isChecked, setIsChecked] = useState(false);
  const handleChangeCheckBox = e => {
    setIsChecked(e.target.checked);
  };
  const openTC = () => {
    var win = window.open(
      'https://sign-documents-staging.s3.amazonaws.com/documents/TyC.pdf',
      '_blank',
    );
    win.focus();
  };
  return (
    <ContainerModal>
      <MuntRescueModal
        sx={{
          width: {
            xs: 'clamp(80%, -23.0357rem + 160.9524vw, 50%)',
            sm: '50%',
          },
        }}>
        <ModalContainer>
          {sectionTitle && (
            <TitleContainer>
              <NameFundContainer>
                <FundText>{typeFund}</FundText>
                <FundTitle
                  sx={{
                    fontSize: 'clamp(1.1rem, 0.0313rem + 1.5vw, 2rem)',
                  }}>
                  {titleFund}
                </FundTitle>
              </NameFundContainer>
            </TitleContainer>
          )}
          <MuntCashContiner>
            <MuntContainer>
              <MuntContext
                sx={{
                  fontSize: theme.typography.availableBalance.fontSize,
                  [theme.breakpoints.down('sm')]: {
                    fontSize: theme.typography.customButton.fontSize,
                  },
                }}>
                Monto a retirar
              </MuntContext>
              <ValueMunt
                sx={{
                  fontSize: theme.typography.headerText.fontSize,
                  [theme.breakpoints.down('sm')]: {
                    fontSize: theme.typography.customButton.fontSize,
                  },
                }}>
                $ {numberWithPoint(amountRescue)}
              </ValueMunt>
            </MuntContainer>
            {valorCuota > 0 && (
              <TypeChange
                sx={{
                  fontSize: 'clamp(0.625rem, 0.5357rem + 0.381vw, 0.75rem)',
                }}>
                {`*Tipo de cambio al 30/10/21 = $${valorCuota}`}
              </TypeChange>
            )}
          </MuntCashContiner>
          <SubtitleModal
            sx={{
              fontSize: 'clamp(1.1rem, 0.0313rem + 1.5vw, 2rem)',
            }}>
            ¿Dónde hacemos el depósito?
          </SubtitleModal>

          <BankCantainer sx={{ gap: 2 }}>
            <TextField
              inputProps={{
                style: { fontSize: theme.typography.customButton.fontSize },
              }}
              fullWidth
              label='Banco'
              name='bank'
              disabled
              variant='standard'
              defaultValue={`${clientBankDetail.bank}`}
            />
            <TextField
              inputProps={{
                style: { fontSize: theme.typography.customButton.fontSize },
              }}
              fullWidth
              label='Cuenta'
              name='bank'
              disabled
              variant='standard'
              defaultValue={`N° ${clientBankDetail.accountNumber}`}
            />
          </BankCantainer>

          <MsnAlert>
            <TitleAlert>
              <WarningAmber>
                <IconsWarnning />
              </WarningAmber>
              <TextImportante>Importante</TextImportante>
            </TitleAlert>
            <ParrafoAlert
              sx={{
                fontSize: 'clamp(0.75rem, 0.5673rem + 0.7692vw, 1rem)',
                width: '90%',
                margin: '0 auto',
              }}>
              El plazo de retiro comienza a correr el día hábil siguiente si tu solcitud fue
              realizada antes de las 4:00 PM y el día hábil subsiguiente si la realizas luego de esa
              hora.
            </ParrafoAlert>
          </MsnAlert>
          <Stack
            direction={'row'}
            justifyContent={'center'}
            alignItems={'center'}
            width={'100%'}
            marginTop={{ xs: '16px', md: '8px' }}>
            <Checkbox checked={isChecked} onChange={handleChangeCheckBox} />
            <Typography
              sx={{
                fontSize: 'clamp(0.75rem, 0.5673rem + 0.7692vw, 1rem)',
              }}>
              Declaro entender los{' '}
              <Typography
                onClick={openTC}
                sx={{
                  fontSize: 'clamp(0.75rem, 0.5673rem + 0.7692vw, 1rem)',
                  cursor: 'pointer',
                }}
                component={'span'}
                color={theme.palette.secondary.main}
                fontWeight={theme.typography.titleText.fontWeight}>
                términos y condiciones del retiro
              </Typography>{' '}
              que estoy a punto de solicitar
            </Typography>
          </Stack>
          <BtnContainer
            gap={2}
            mt={50}
            sx={{
              flexDirection: { xs: 'column', md: 'row' },
            }}>
            <Button
              onClick={() => handleCloseModal()}
              sx={{
                width: { md: '45%' },
                padding: '16px',
                background: '#F4F4FB',
                color: theme.palette.primary.main,
                textTransform: 'none',
              }}
              disableElevation>
              No por ahora
            </Button>
            <Button
              sx={{
                width: { md: '55%' },
                padding: '16px',
                textTransform: 'none',
              }}
              variant='contained'
              disableElevation
              disabled={!isChecked}
              onClick={() => handleSubmit()}>
              Confirmar solicitud de retiro
            </Button>
          </BtnContainer>
        </ModalContainer>
      </MuntRescueModal>
    </ContainerModal>
  );
};

export default ModalWithdrawal;
