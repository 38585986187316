import React, { useState } from 'react';
import { numberWithPoint } from '../../../utils/utils';
import CardsContinersRescue from './CardsContinersRescue';
import CardsResultRescue from './CardsResultRescue';

const RenderPortfolioRescue = ({ amountPortfolio, portfolioFileAsComplete }) => {
  const [valueSelect, setValueSelect] = useState('50');
  const [showCardsResult, setShowCardsResult] = useState(false);

  const handleAmountRescue = e => {
    const value = e.target.value;
    setValueSelect(value);
  };

  const calculateAmount = () => {
    if (valueSelect === '50') {
      return numberWithPoint(Math.round(amountPortfolio * 0.5));
    }
    return numberWithPoint(Math.round(amountPortfolio));
  };

  const handleSubmitRescue = () => {
    setShowCardsResult(!showCardsResult);
  };

  const selectedRescueType = valueSelect === '50' ? 'Parcial' : 'Total';

  const amountRescue = calculateAmount();
  return (
    <>
      {showCardsResult ? (
        <CardsResultRescue
          handleSubmitRescue={handleSubmitRescue}
          amountRescue={amountRescue}
          portfolioFileAsComplete={portfolioFileAsComplete}
          selectedRescueType={selectedRescueType}
        />
      ) : (
        <CardsContinersRescue
          handleAmountRescue={handleAmountRescue}
          mountPortfolio={amountPortfolio}
          amountRescue={amountRescue}
          valueSelect={valueSelect}
          handleSubmitRescue={handleSubmitRescue}
        />
      )}
    </>
  );
};

export default RenderPortfolioRescue;
