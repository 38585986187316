import { Stack, Typography, styled } from '@mui/material';
import { numberWithPoint } from '../../utils/numbers';
import { handleRiskProfilePortfolio } from '../../utils/riskProfile';

const ItemsPaymentBox = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.white.main,
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'row',
  width: '100%',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '4px 16px',
  boxSizing: 'border-box',
  height: '48px',
  boxShadow: '0px 0px 12px rgba(61, 64, 75, 0.28)',
  cursor: 'pointer',
}));

const ContainerCard = styled(Stack)({
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  padding: '0px',
  boxSizing: 'border-box',
  margin: '0px',
  height: '40px',
  width: '60%',
});

const ListProduct = styled(Stack)({
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flex-start',
  padding: '0px',
  boxSizing: 'border-box',
  height: '90%',
  width: '100%',
  overflow: 'hidden',
  maxWidth: '493px',
});

const MyPortfolio = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.dark,
  fontSize: theme.typography.customButton.fontSize,
  fontWeight: theme.typography.h4.fontWeight,
  textAlign: 'left',
  whiteSpace: 'pre-wrap',
  fontSynthesis: 'none',
  letterSpacing: '0px',
  textDecoration: 'none',
  textTransform: 'none',
  margin: '0px',
}));

const CoinValueBox = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.dark,
  fontSize: theme.typography.description.fontSize,
  fontWeight: theme.typography.h4.fontWeight,
  textAlign: 'left',
  whiteSpace: 'pre-wrap',
  letterSpacing: '-0.39022237062454224px',
  textDecoration: 'none',
  lineHeight: '21.462230682373047px',
  textTransform: 'none',
  margin: '0px',
}));
const PortfolioProfile = styled('img')({
  height: 'auto',
  width: '35px',
});

const PaymentCardPortfolio = ({
  perfil,
  account,
  marketValue,
  handleDetailsInvestmentPortfolio,
  portfolioId,
  fileAs,
}) => {
  return (
    <>
      <ItemsPaymentBox onClick={() => handleDetailsInvestmentPortfolio(portfolioId)}>
        <ContainerCard gap={1}>
          <PortfolioProfile src={handleRiskProfilePortfolio(fileAs.risk)} alt={`${perfil}`} />
          <ListProduct>
            <MyPortfolio>{account}</MyPortfolio>
          </ListProduct>
        </ContainerCard>

        <CoinValueBox>$ {numberWithPoint(Math.round(marketValue))}</CoinValueBox>
      </ItemsPaymentBox>
    </>
  );
};

export default PaymentCardPortfolio;
