import { Button, Stack, Typography, styled, useTheme } from '@mui/material';
import { ErrorBoundaryXcala } from '../ErrorBoundaryXcala';
import { TableTransactions } from './TableTransactions';

const BodyTransaction = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.white.main,
  boxShadow: '0px 0px 8px rgba(61, 64, 75, 0.15)',
  borderRadius: '16px',
  display: 'flex',
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  padding: '32px 24px',
  boxSizing: 'border-box',
  minHeight: '486px',
  width: '100%',
  maxWidth: '1200px',
}));

const AssetsTitle = styled(Stack)({
  borderRadius: '4px',
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '4px 0px',
  boxSizing: 'border-box',
  width: '100%',
  margin: '0px',
});
const TitleAssets = styled(Typography)(({ theme }) => ({
  textAlign: 'left',
  whiteSpace: 'pre-wrap',
  fontSynthesis: 'none',
  color: theme.palette.primary.main,
  fontWeight: theme.typography.button.fontWeight,
  fontSize: theme.typography.button.fontSize,
  letterSpacing: '0px',
  textDecoration: 'none',
  lineHeight: '126.49999856948853%',
  textTransform: 'none',
  margin: '0px',
}));
const IteractionAssetsFilt = styled(Stack)({
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  padding: '0px',
  boxSizing: 'border-box',
  margin: '0px',
});

const FundSelectBtn = styled(Button)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  whiteSpace: 'pre-wrap',
  fontSynthesis: 'none',
  color: theme.palette.textLight.main,
  fontWeight: theme.typography.button.fontWeight,
  fontSize: theme.typography.button.fontSize,
  letterSpacing: '0px',
  textDecoration: 'none',
  lineHeight: '126.49999856948853%',
  textTransform: 'none',
  margin: '0px',
  backgroundColor: theme.palette.transparent.main,
  '&:disabled': {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.transparent.main,
  },
  '&:hover': {
    backgroundColor: theme.palette.textLight.main,
  },
}));
const Divider = styled(Typography)(({ theme }) => ({
  textAlign: 'center',
  whiteSpace: 'pre-wrap',
  fontSynthesis: 'none',
  color: theme.palette.primary.dark,
  fontWeight: theme.typography.button.fontWeight,
  fontSize: theme.typography.button.fontSize,
  letterSpacing: '0px',
  textDecoration: 'none',
  lineHeight: '126.49999856948853%',
  textTransform: 'none',
}));

export const BodyTransactions = ({
  loadingGetClientTransactionsPortfolio,
  tap,
  handleTaps,
  dataTransactions,
  errorGetClientTransactionsPortfolio,
}) => {
  const theme = useTheme();

  return (
    <BodyTransaction marginTop='40px'>
      <AssetsTitle gap={1} flexDirection={{ md: 'row', xs: 'column' }}>
        <TitleAssets>Mis Transacciones</TitleAssets>
        <IteractionAssetsFilt gap={1}>
          <FundSelectBtn
            sx={{
              '&.Mui-disabled': {
                color: theme.palette.primary.main,
              },
            }}
            disabled={tap === 'Fondos'}
            onClick={() => handleTaps('Fondos')}>
            Fondos
          </FundSelectBtn>
          <Divider>|</Divider>
          <FundSelectBtn
            disabled={tap === 'Portfolio'}
            onClick={() => handleTaps('Portfolio')}
            sx={{
              '&.Mui-disabled': {
                color: theme.palette.primary.main,
              },
            }}>
            Portfolios
          </FundSelectBtn>
        </IteractionAssetsFilt>
      </AssetsTitle>
      <Stack width={'100%'}>
        <ErrorBoundaryXcala>
          <TableTransactions
            tap={tap}
            loadingGetClientTransactionsPortfolio={loadingGetClientTransactionsPortfolio}
            dataTransactions={dataTransactions}
            errorGetClientTransactionsPortfolio={errorGetClientTransactionsPortfolio}
          />
        </ErrorBoundaryXcala>
      </Stack>
    </BodyTransaction>
  );
};
