import { Stack, Typography, styled, useTheme } from '@mui/material';
import React from 'react';
import Spinner from '../Spinner';

const ContentDisponible = styled(Stack)({
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'column',
  padding: '0px',
  boxSizing: 'border-box',
  textDecoration: 'none',
  heigth: '100%',
  width: '100%',
  gap: '4px',
});

const TitelDisponible = styled(Stack)({
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'row',
  padding: '0px',
  boxSizing: 'border-box',
  margin: '0px',
  width: '100%',
  gap: '4px',
});

const IconsCaja = styled('img')({
  height: '10px',
  width: '10px',
});

const MountDisponible = styled(Typography)(({ theme }) => ({
  whiteSpace: 'pre-wrap',
  fontSynthesis: 'none',
  color: theme.palette.primary.dark,
  fontSize: '1.2rem',
  letterSpacing: '- 0.4742029309272766px',
  textDecoration: 'none',
  lineHeight: '26.081161499023438px',
  textTransform: 'none',
  alignSelf: 'stretch',
  margin: '8px 0px 0px 0px',
  fontWeight: 'Bold',
}));

const WalletAmount = ({
  direction,
  coinValue = 250,
  title,
  textAlign,
  loadingBalance,
  errorBalance,
}) => {
  const theme = useTheme();

  if (loadingBalance) {
    return <Spinner />;
  }

  if (errorBalance) {
    throw new Error('Error component my wallet');
  }

  return (
    <ContentDisponible alignItems={direction} sx={{ cursor: 'pointer' }}>
      <TitelDisponible justifyContent={direction} alignItems='center'>
        <Typography
          fontSize={'0.85rem'}
          fontWeight={theme.typography.titleText.fontWeight}
          sx={{ letterSpacing: -0.7, lineHeight: '0px' }}>
          {title}
        </Typography>
        <IconsCaja src='./assets/images/elipseFund.svg' loading='lazy' alt={'Ellipse 55'} />
      </TitelDisponible>
      <MountDisponible textAlign={textAlign}>{`${coinValue}`}</MountDisponible>
    </ContentDisponible>
  );
};

export default WalletAmount;
