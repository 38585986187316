import React from 'react';
import { CardBody, ListProduct } from './SectionInteractionComponent';
import Spinner from '../Spinner';
import SectionNoMarketValue from './SectionNoMarketValue';
import PaymentCards from './PaymentCards';
import SectionNoProductComponent from './SectionNoProduct';
import PaymentCardPortfolio from './PaymentCardPortfolio';
import { portfolioNameAndDetails } from '../../utils/portfolioNameAndDetails';

export const MyassetSCards = ({
  selectedFilter,
  loadingGetClientInvestments,
  successGetClientInvestments,
  loadingInvestmentPortfolio,
  successDataInvestmentPortfolio,
  handleRedirect,
  handleDetails,
  handleDetailsInvestmentPortfolio,
  navigate,
  errorInvesments,
  errorInvestmentPortfolio,
}) => {
  if (loadingGetClientInvestments || loadingInvestmentPortfolio) {
    return <Spinner />;
  }
  if (
    errorInvesments ||
    errorInvestmentPortfolio ||
    successDataInvestmentPortfolio === null ||
    successGetClientInvestments === null
  ) {
    throw new Error('Error component my investment detail');
  }

  return (
    <CardBody gap={0.1} padding={1} sx={{ height: '100%' }}>
      {selectedFilter === 'fondos' ? (
        loadingGetClientInvestments ? (
          <Spinner />
        ) : successGetClientInvestments?.length >= 1 &&
          successGetClientInvestments
            ?.filter(item => item.name !== 'Caja')
            ?.every(item => Number.parseFloat(Number.parseInt(item.marketValue)) <= 0) ? (
          <SectionNoMarketValue tap={selectedFilter} handleRedirect={() => navigate('/products')} />
        ) : successGetClientInvestments && successGetClientInvestments?.length > 1 ? (
          <ListProduct gap={0.5} overflow={'hidden'} maxHeight={'180px'}>
            {successGetClientInvestments
              .filter(item => item.id !== process.env.REACT_APP_CASH_ID && item.marketValue >= 1)
              .map((investment, index) => {
                const custom = JSON.parse(investment.custom);
                const riskProfile = custom.preferences.riesgo.description;

                return (
                  <PaymentCards
                    // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
                    key={index}
                    investment={investment}
                    handleDetails={handleDetails}
                    riskProfile={riskProfile}
                  />
                );
              })}
          </ListProduct>
        ) : (
          <SectionNoProductComponent handleRedirect={handleRedirect} />
        )
      ) : loadingInvestmentPortfolio ? (
        <Spinner />
      ) : successDataInvestmentPortfolio?.length >= 1 &&
        successDataInvestmentPortfolio
          ?.filter(item => item.account !== '' && item.account.includes('@') === false)
          ?.map(item => Number.parseFloat(Number.parseInt(item.marketValue.replace(/\$/g, ''))))
          ?.every(item => item <= 0) ? (
        <SectionNoMarketValue tap={selectedFilter} handleRedirect={() => navigate('/portfolio')} />
      ) : successDataInvestmentPortfolio && successDataInvestmentPortfolio?.length > 1 ? (
        <ListProduct gap={0.5} overflow={'hidden'} maxHeight={'180px'}>
          {successDataInvestmentPortfolio
            .filter(
              portfolio =>
                portfolio.account !== '' &&
                portfolio.account.includes('@') === false &&
                Number.parseFloat(
                  portfolio.marketValue.replace(/[^0-9,]/g, '').replace(',', '.'),
                ) >= 1,
            )
            .map(portfolio => {
              const { account, marketValue, id: portfolioId } = portfolio;
              const marketValueformated = marketValue.replace(/[^0-9,]/g, '').replace(',', '.');
              const fileAs = portfolioNameAndDetails(account);
              return (
                <PaymentCardPortfolio
                  key={portfolioId}
                  handleDetailsInvestmentPortfolio={handleDetailsInvestmentPortfolio}
                  fileAs={fileAs}
                  account={fileAs?.fileAsComplete.slice(10)}
                  marketValue={marketValueformated}
                  portfolioId={portfolioId}
                />
              );
            })}
        </ListProduct>
      ) : (
        <SectionNoProductComponent handleRedirect={handleRedirect} />
      )}
    </CardBody>
  );
};
