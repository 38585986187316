import { Stack } from '@mui/material';
import { portfolioNameAndDetails } from '../../utils/portfolioNameAndDetails';
import { AvatarInvertedPortfolio } from '../XcalaComponents/AvatarInvertedPortfolio';
import WrapperBackgroundBanner from '../XcalaComponents/WrapperBackgroundBanner';
import WrapperContentApp from '../XcalaComponents/WrapperContentApp';

const $minHeigth = '407px';

const SimulationHeader = ({ fileAs, isSimulatorFund, dataChart }) => {
  const { fundName } = dataChart;

  let result;
  if (!isSimulatorFund) {
    result = portfolioNameAndDetails(fundName) || {};
  }

  return (
    <WrapperBackgroundBanner $minHeigth={$minHeigth}>
      <WrapperContentApp
        $minHeigth={$minHeigth}
        fileAs={fileAs}
        direction={{ md: 'row', xs: 'column' }}
        justifyContent='center'
        padding={'40px 0'}
        display='flex'>
        <Stack
          sx={{
            width: '100%',
          }}
          justifyContent={'center'}
          alignItems={'center'}>
          {!isSimulatorFund ? (
            <AvatarInvertedPortfolio porfolioDetailHeader={result} />
          ) : (
            <AvatarInvertedPortfolio porfolioDetailHeader={fileAs} />
          )}
        </Stack>
      </WrapperContentApp>
    </WrapperBackgroundBanner>
  );
};

export default SimulationHeader;
