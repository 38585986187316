import React from 'react';
import { portfolioNameAndDetails } from '../../../utils/portfolioNameAndDetails';
import Spinner from '../../Spinner';
import { CardPorfolio } from '../CardPorfolio';

export const CardPortfolioWrapper = ({
  dataPortfolio,
  loadingModelsPortfolio,
  errorModelsPortfolio,
}) => {
  if (loadingModelsPortfolio) {
    return <Spinner />;
  }

  if (errorModelsPortfolio) {
    throw new Error('Error cardPortfolio');
  }

  return (
    <>
      {dataPortfolio?.map(dataChart => {
        const portfolioDetailsFileAs = portfolioNameAndDetails(dataChart?.fundName);
        return (
          <CardPorfolio
            key={dataChart.idModels}
            dataChart={dataChart}
            portfolioDetailsFileAs={portfolioDetailsFileAs}
          />
        );
      })}
    </>
  );
};
