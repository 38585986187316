import { gql, useQuery } from '@apollo/client';

const GET_MODELS_PORTFOLIO = gql`
query GetModels {
  getModels {
    id
    fileAs
    name
    code
    legacyKey
    currency
    Targets {
      name
      percentage
      ID
    }
  }
}
`;

export default () => useQuery(GET_MODELS_PORTFOLIO, { errorPolicy: 'all' });
