import { Box, Checkbox, Grid, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import TagManager from 'react-gtm-module';
import useSignCreateTransactions from '../../hooks/useSignCreateTransactions';
import { ButtonsPrimary, ButtonsTertiary } from '../ComponentApp/buttoms/ButtonsXcala';

export const CompleteOmboardingFour = ({ setstepper, setSteppercolor }) => {
  const [checkedAgreement, setCheckedAgreement] = useState(false);

  const [
    doSignCreateTransactions,
    { data, error: errorSignCreateTransactions, loading: loadingSignCreateTransactions },
  ] = useSignCreateTransactions();

  const successSignCreateTransactions = data?.SignCreateTransactions;

  const signDocument = () => {
    const data = {
      documents: ['Contrato-ADC-Xcala-V2.pdf'],
    };

    doSignCreateTransactions({
      variables: { data },
    });
  };

  useEffect(() => {
    if (successSignCreateTransactions && !loadingSignCreateTransactions) {
      window.open(successSignCreateTransactions.redirectUrl, '_self');
    }
  }, [successSignCreateTransactions, loadingSignCreateTransactions]);

  const openContract = () => {
    const { REACT_APP_CONTRACT } = process.env;
    var win = window.open(REACT_APP_CONTRACT, '_blank');
    win.focus();
  };

  const goBefore = () => {
    setstepper(3);
    setSteppercolor(first => {
      return {
        ...first,
        comp1: true,
        comp2: true,
        comp3: true,
        comp4: false,
      };
    });
  };
  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'stepper Validating your identity',
        path: '/completeregistration',
        link: {
          url: '/completeregistration',
          title: 'stepper Validating your identity',
        },
      },
    });
  }, []);
  return (
    <>
      <Grid container maxWidth='sm' spacing={2}>
        <Grid item md={12} className='stepper-grid-registration'>
          <Typography variant='h6' className='blue_text fw-600 font-lg-24'>
            Firma electrónica contrato Xcala
          </Typography>
        </Grid>
      </Grid>

      <Stack spacing={2}>
        <Box>
          <Typography sx={{ mb: 1, color: '#808080', fontSize: '1rem' }}>
            A continuación deberás completar el proceso de firma del contrato de servicio bajo el
            cual se rige la plataforma.
          </Typography>
          <ul
            style={{
              color: '#808080',
            }}>
            <li> Recuerda leerlo completo antes de firmarlo.</li>
            <li> Te enviaremos una copia a tu correo eletrónico al finalizar el proceso.</li>
            <li>
              {' '}
              Sin la firma de este documento, no podrás invertir con nosotros. Eso nos pondría muy
              triste.
            </li>
          </ul>
        </Box>

        <Stack direction={'row'} alignItems={'center'}>
          <Checkbox
            checked={checkedAgreement}
            onChange={e => setCheckedAgreement(e.target.checked)}
          />

          <Typography sx={{ fontSize: '1rem', color: '#182849' }}>
            He leído y acepto las condiciones del
            <Typography
              onClick={openContract}
              component={'span'}
              sx={{
                fontSize: '1rem',
                color: '#49a197',
                fontWeight: '600',
                cursor: 'pointer',
              }}>
              {' '}
              Contrato de Servicio Xcala.
            </Typography>
          </Typography>
        </Stack>

        <Stack
          width={'100%'}
          direction={'row'}
          sx={{
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <Stack
            direction={{ md: 'row', xs: 'column-reverse' }}
            width={'70%'}
            spacing={2}
            marginTop={3}>
            <ButtonsTertiary name='Cancelar' fullWidth onClick={goBefore} />

            <ButtonsPrimary
              name='Firmar mandato'
              fullWidth
              disabled={loadingSignCreateTransactions || !checkedAgreement}
              onClick={() => {
                signDocument();
                TagManager.dataLayer({
                  dataLayer: {
                    event: 'signDocument',
                    path: '/completeregistration',
                    link: {
                      url: '/completeregistration',
                      title: 'signDocument',
                    },
                  },
                });
              }}
            />
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};
