import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { numberWithPoint } from '../../utils/numbers';
import { useTheme } from '@mui/material';
import Spinner from '../Spinner';

const ChartsDashboard = ({
  data,
  height = 200,
  loadingDataInvestedAndMarketValue,
  errorDataInvestedAndMarketValue,
}) => {
  const theme = useTheme();
  const marketValueSeries = data?.map(item =>
    Number.parseInt(item.MarketValue.replace(/\$|\.+/g, '')),
  );
  const cumulativeInvestedSeries = data?.map(item =>
    Number.parseFloat(item.CumulativeNetInvested.replace(/\$|\.+/g, '')),
  );

  const getMonthName = dateString => {
    const date = new Date(dateString.split(' - ')[0]);
    return date.toLocaleString('default', { month: 'short' });
  };

  const categories = data?.map(item => getMonthName(item.Date));

  const addSymbolDollarChart = value => {
    if (Number.parseInt(value) > 50) {
      if (typeof value === 'string') {
        return `${numberWithPoint(Number.parseInt(value))}`;
        // biome-ignore lint/style/noUselessElse: <explanation>
      } else {
        return `$${numberWithPoint(Number.parseInt(value))}`;
      }
      // biome-ignore lint/style/noUselessElse: <explanation>
    } else {
      let valueParse = 0;
      if (typeof value === 'string') {
        valueParse = `${Number.parseFloat(value).toFixed(2)}`;
      } else {
        valueParse = `$${value.toFixed(2)}`;
      }
      return valueParse;
    }
  };

  const stateOptionsChart = {
    options: {
      xaxis: {
        categories: categories,
      },
      yaxis: {
        show: 'nada',
        labels: {
          formatter: addSymbolDollarChart,
        },
      },

      stroke: {
        curve: 'smooth',
        width: 2,
        colors: ['#49A197', '#7F56D9'],
      },
      colors: [theme.palette.secondary.main, theme.palette.violet.main],
      dataLabels: {
        enabled: true,
        hideOverflowingLabels: true,
        textAnchor: 'start',
        style: {
          fontSize: '20px',
          fontWeight: 'bold',
          colors: ['#5AC4B1'],
        },
        background: {
          enabled: true,
          foreColor: 'white',
          borderRadius: '50%',
          padding: 10,
          opacity: 0.9,
          borderWidth: 1,
          borderColor: '#fff',
        },
        formatter: (val, opt) => {
          const goals = opt.w.config.series[opt.seriesIndex].data[opt.dataPointIndex].goals;
          if (goals?.length) {
            return '$';
          }
          return '';
        },
      },
      legend: {
        horizontalAlign: 'left',
      },
    },
    series: [
      {
        name: 'Balance',
        data: marketValueSeries,
      },
      {
        name: 'Depositado',
        data: cumulativeInvestedSeries,
      },
    ],
  };

  if (loadingDataInvestedAndMarketValue || !marketValueSeries || !cumulativeInvestedSeries) {
    return <Spinner />;
  }

  if (errorDataInvestedAndMarketValue) {
    throw new Error('Error component wallet dashboard');
  }

  return (
    <>
      <ReactApexChart
        options={stateOptionsChart.options}
        series={stateOptionsChart.series}
        type='area'
        height={height}
      />
    </>
  );
};

export default ChartsDashboard;
