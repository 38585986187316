import { Stack, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setMyInvestmentSelected } from '../../redux/investments';
import { setPortfolioSelected } from '../../redux/portfolio';
import { numberWithPoint } from '../../utils/numbers';
import { formatLossGainValueWithSign } from '../../utils/utils';
import { ErrorBoundaryXcala } from '../ErrorBoundaryXcala';
import Spinner from '../Spinner';
import CardChartsDashboard from './CardChartsDashboard';
import GoToCompleteObAndInvestment from './GoToCompleteObAndInvestment';
import { MiInvestmentCardsDetails } from './MiInvestmentCardsDetails';
import {
  BtnFilterFound,
  Card,
  Divider,
  IteractionAssetsFilt,
  TitleSection,
  TitleSectionContainer,
} from './SectionInteractionComponent';
import { MyassetSCards } from './MyassetCardsDetail';

const SectionInteraction = ({
  loadingProfit,
  successGetClientProfit,
  successGetClientInvestments,
  loadingGetClientInvestments,
  loadingBalance,
  balanceData,
  successDataInvestmentPortfolio,
  loadingInvestmentPortfolio,
  errorProfit,
  errorInvesments,
  errorInvestmentPortfolio,
}) => {
  const [selectedFilter, setSelectedFilter] = useState('fondos');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector(state => state.auth);
  const theme = useTheme();

  const handleShowProducts = filterType => {
    setSelectedFilter(filterType);
  };
  const handleDetails = item => {
    dispatch(setMyInvestmentSelected(item));
    navigate('/myinvestmentdetail');
  };

  const handleDetailsInvestmentPortfolio = item => {
    dispatch(setPortfolioSelected(item));
    navigate('/details-investment-portfolio');
  };

  const [access, setAccess] = useState(false);

  const handleRedirect = () => {
    const url = 'https://www.blog.xcala.com/';

    if (url.includes('https')) {
      window.open(url, '_blank');
    } else {
      navigate(access ? url : '/completeregistration');
    }
  };

  useEffect(() => {
    if (
      user?.['custom:checkid-sign-doc'] &&
      user['custom:checkid-sign-doc'] === 'signature_success'
    ) {
      setAccess(true);
    }
  }, [user]);
  useEffect(() => {
    if (
      successGetClientInvestments?.length >= 1 &&
      successGetClientInvestments
        ?.filter(item => item.name !== 'Caja')
        ?.every(item => Number.parseFloat(Number.parseInt(item.marketValue)) <= 0)
    ) {
      setSelectedFilter('portfolio');
    } else {
      setSelectedFilter('fondos');
    }
  }, [successGetClientInvestments]);

  return (
    <Stack
      width={'100%'}
      borderRadius={'8px'}
      height={'100%'}
      minHeight={'284px'}
      gap={2}
      flexDirection={{ xs: 'column', sm: 'column', lg: 'row', md: 'row' }}>
      <Stack
        sx={{
          width: { ls: '60%', md: '80%' },
          height: '100%',
          minHeight: '414px',
          backgroundColor: theme.palette.white.main,
          boxShadow: '0px 0px 12px rgba(61, 64, 75, 0.28)',
          borderRadius: '8px',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        {loadingGetClientInvestments ? (
          <Spinner />
        ) : successGetClientInvestments?.length === 0 ? (
          <GoToCompleteObAndInvestment loadingBalance={loadingBalance} balanceData={balanceData} />
        ) : (
          <ErrorBoundaryXcala>
            <CardChartsDashboard />
          </ErrorBoundaryXcala>
        )}
      </Stack>
      <Stack
        sx={{
          width: { lg: '40%', md: '100%' },
          backgroundColor: theme.palette.action.hover,
        }}
        gap={1}>
        <TitleSectionContainer>
          <TitleSection>Mi Inversión </TitleSection>
        </TitleSectionContainer>

        <Stack gap={2} flexDirection={'row'}>
          <ErrorBoundaryXcala>
            <Card>
              <MiInvestmentCardsDetails
                loadingProfit={loadingProfit}
                errorProfit={errorProfit}
                icon={'/assets/images/dashboard/iconsMoney.svg'}
                title={'Total invertido'}
                amount={numberWithPoint(
                  Math.round(Number.parseFloat(successGetClientProfit?.marketValue)),
                )}
              />
            </Card>
            <Card>
              <MiInvestmentCardsDetails
                loadingProfit={loadingProfit}
                errorProfit={errorProfit}
                icon={'/assets/images/dashboard/IconsRentability.svg'}
                title={'Ganancia / Pérdida'}
                amount={formatLossGainValueWithSign(successGetClientProfit?.capitalAppreciation)}
              />
            </Card>
          </ErrorBoundaryXcala>
        </Stack>

        <TitleSectionContainer sx={{ marginTop: '4px' }}>
          <TitleSection variant='h6' sx={{ flexGrow: 1 }}>
            Mis Activos
          </TitleSection>

          <IteractionAssetsFilt>
            <BtnFilterFound
              sx={{
                color:
                  selectedFilter === 'fondos'
                    ? theme.palette.primary.blueSky
                    : theme.palette.white.main,
              }}
              onClick={() => handleShowProducts('fondos')}>
              Fondos
            </BtnFilterFound>
            <Divider>|</Divider>
            <BtnFilterFound
              sx={{
                color:
                  selectedFilter === 'portfolio'
                    ? theme.palette.primary.blueSky
                    : theme.palette.white.main,
              }}
              onClick={() => handleShowProducts('portfolio')}>
              Portfolio
            </BtnFilterFound>
          </IteractionAssetsFilt>
        </TitleSectionContainer>

        <Stack>
          <ErrorBoundaryXcala>
            <MyassetSCards
              selectedFilter={selectedFilter}
              loadingGetClientInvestments={loadingGetClientInvestments}
              successGetClientInvestments={successGetClientInvestments}
              loadingInvestmentPortfolio={loadingInvestmentPortfolio}
              successDataInvestmentPortfolio={successDataInvestmentPortfolio}
              handleRedirect={handleRedirect}
              handleDetails={handleDetails}
              handleDetailsInvestmentPortfolio={handleDetailsInvestmentPortfolio}
              navigate={navigate}
              errorInvesments={errorInvesments}
              errorInvestmentPortfolio={errorInvestmentPortfolio}
            />
          </ErrorBoundaryXcala>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default SectionInteraction;
