import { Stack, Typography, styled } from '@mui/material';

const HeadCards = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  padding: '16px 32px',
  boxSizing: 'border-box',
  flex: '1',
  margin: '0px',
  width: '100%',
  minHeight: '136px',
}));
const HeadChips = styled(Stack)({
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  padding: '0px',
  boxSizing: 'border-box',
  margin: '0px',
  height: '22.9px',
  width: '100%',
});

const GeneralMotivations = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.tertiary.main,
  borderRadius: '14px',
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '2.5px 8px',
  boxSizing: 'border-box',
  height: '22px',
  margin: '0px',
  width: '42px',
}));
const HmcText = styled(Typography)(({ theme }) => ({
  textAlign: 'left',
  whiteSpace: 'pre-wrap',
  fontSynthesis: 'none',
  color: theme.palette.primary.main,
  fontWeight: theme.typography.button.fontWeight,
  fontSize: theme.typography.littleText.fontSize,
  letterSpacing: '0px',
  textDecoration: 'none',
  textTransform: 'none',
  margin: '0px',
}));

const StarContainer = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.text,
  borderRadius: '17px',
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '0px 7px',
  boxSizing: 'border-box',
  margin: '0px 0px 0px 8px',
  height: '22px',
  width: '36px',
}));

const StarIcons = styled('img')({
  height: '16px',
  width: '16px',
  margin: '0px',
});
const HeadTitle = styled(Typography)(({ theme }) => ({
  textAlign: 'left',
  whiteSpace: 'pre-wrap',
  fontSynthesis: 'none',
  color: theme.palette.tertiary.main,
  fontWeight: theme.typography.button.fontWeight,
  fontSize: theme.typography.headerText.fontSize,
  letterSpacing: '0px',
  textDecoration: 'none',
  lineHeight: '119.49999332427979%',
  textTransform: 'none',
  alignSelf: 'stretch',
  margin: '18px 0px 0px 0px',
}));

export const HeadContainerCards = ({ fundName, risk, userRisk }) => {
  return (
    <>
      <HeadCards>
        <HeadChips>
          <GeneralMotivations>
            <HmcText>HMC</HmcText>
          </GeneralMotivations>

          {risk && risk.toLowerCase() === userRisk && (
            <StarContainer>
              <StarIcons src='/assets/images/start-icons.svg' alt='start-icons' />
            </StarContainer>
          )}
        </HeadChips>
        <HeadTitle>{fundName}</HeadTitle>
      </HeadCards>
    </>
  );
};
