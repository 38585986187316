import { Stack, Typography, styled } from '@mui/material';
import React from 'react';
import { ErrorBoundaryXcala } from '../ErrorBoundaryXcala';
import TabletGridWallet from './TabletGridWallet';

const BodyTransaction = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.white.main,
  boxShadow: '0px 0px 8px rgba(61, 64, 75, 0.15)',
  borderRadius: '16px',
  display: 'flex',
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  padding: '16px 12px',
  boxSizing: 'border-box',
  minHeight: '486px',
  width: '100%',
  maxWidth: 600,
  margin: '0px auto',
  paddingTop: '16px',
}));

const AssetsTitle = styled(Stack)({
  borderRadius: '4px',
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '4px 0px',
  boxSizing: 'border-box',
  width: '100%',
  margin: '0px',
});
const TitleAssets = styled(Typography)(({ theme }) => ({
  textAlign: 'left',
  whiteSpace: 'pre-wrap',
  fontSynthesis: 'none',
  color: theme.palette.primary.main,
  fontWeight: theme.typography.button.fontWeight,
  fontSize: theme.typography.button.fontSize,
  letterSpacing: '0px',
  textDecoration: 'none',
  lineHeight: '126.49999856948853%',
  textTransform: 'none',
  margin: '0px',
}));
const IteractionAssetsFilt = styled(Stack)({
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  padding: '0px',
  boxSizing: 'border-box',
  margin: '0px',
});

const BodyTransactionsWallet = ({
  titleText = 'Mis Transacciones',
  column,
  transactions,
  loadingTransactions,
  errorTransactions,
}) => {
  const filterStatusTransactions = transactions?.filter(({ status }) => status === 4);

  const sortedArrayDateStatusTransactions = filterStatusTransactions
    ?.filter(({ subType }) => subType === 'CONT' || subType === 'WTHD' || subType === 'DIV')
    .sort((a, b) => {
      const prevDate = new Date(a.tradeDate);
      const currentDate = new Date(b.tradeDate);
      return currentDate.getTime() - prevDate.getTime();
    });
  return (
    <BodyTransaction>
      <AssetsTitle gap={1} flexDirection={{ md: 'row', xs: 'column' }}>
        <TitleAssets>{titleText}</TitleAssets>
        <IteractionAssetsFilt gap={1} />
      </AssetsTitle>
      <Stack width={'100%'}>
        <ErrorBoundaryXcala>
          <TabletGridWallet
            column={column}
            filterStatusTransactions={filterStatusTransactions}
            sortedArrayDateStatusTransactions={sortedArrayDateStatusTransactions}
            loadingTransactions={loadingTransactions}
            errorTransactions={errorTransactions}
          />
        </ErrorBoundaryXcala>
      </Stack>
    </BodyTransaction>
  );
};

export default BodyTransactionsWallet;
