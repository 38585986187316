import {
  Box,
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
} from '@mui/material';
import { ButtonsPrimary } from '../../ComponentApp/buttoms/ButtonsXcala';
import { ContentFilter, FilterSection } from '../../XcalaComponents';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const nameFilter = 'Filtrar por perfil';
const nameProdut = 'Filtrar por producto';

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
};

const filterByRisk = ['Conservador', 'Moderado', 'Agresivo'];

const motivations = [
  'Renta inmobiliaria',
  'Deuda privada',
  'Venture Capital/Startups',
  'Hedge funds',
  'Criptoactivos',
];

export const FilterShearch = ({
  riskProfile,
  motivationsFilters,
  handleChangeProfileRisk,
  handleChangeMotivations,
  handleCleanFilters,
}) => {
  return (
    <FilterSection
      sx={{
        padding: { md: 0, xs: '10px 0px' },
        flexDirection: { md: 'row', sm: 'column', xs: 'column' },
        width: '100%',
      }}>
      <ContentFilter
        sx={{
          width: { md: '60%', xs: '100%' },
          flexWrap: { md: 'nowrap', xs: 'wrap' },
        }}>
        <FormControl sx={{ m: 1, width: { md: '50%', xs: '100%' }, flex: '1 1 auto' }}>
          <InputLabel id='risk-profile'>{`${nameFilter}`}</InputLabel>
          <Select
            labelId='risk-profile'
            id='risk-profile'
            value={riskProfile}
            onChange={handleChangeProfileRisk}
            input={<OutlinedInput label={`${nameFilter}`} />}
            renderValue={selected => selected?.join('')}
            MenuProps={MenuProps}
            sx={{ height: '52px' }}>
            {filterByRisk.map(fund => {
              return (
                <MenuItem key={fund} value={fund}>
                  <ListItemText primary={fund} />
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <FormControl sx={{ m: 1, width: { md: '50%', xs: '100%' }, flex: '1 1 auto' }}>
          <InputLabel id='motivations'>{`${nameProdut}`}</InputLabel>
          <Select
            labelId='motivations'
            id='motivations'
            multiple
            value={motivationsFilters}
            onChange={handleChangeMotivations}
            input={<OutlinedInput label={`${nameProdut}`} />}
            renderValue={selected => selected.join(', ')}
            MenuProps={MenuProps}
            sx={{ height: '52px' }}>
            {motivations.map(fund => {
              return (
                <MenuItem key={fund} value={fund}>
                  <Checkbox checked={motivationsFilters?.indexOf(fund) > -1} />
                  <ListItemText primary={fund} />
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </ContentFilter>

      <Box
        sx={{
          flex: '1 1 auto',
          mr: { md: '6px', sm: 0 },
          width: { md: '15%', xs: '96%' },
        }}>
        <ButtonsPrimary
          name='Limpiar filtros'
          onClick={handleCleanFilters}
          fullWidth
          sx={{
            whiteSpace: 'nowrap',
            minWidth: '120px',
          }}
        />
      </Box>
    </FilterSection>
  );
};
