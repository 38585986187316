import React from 'react';
import { Stack, Typography, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ButtonsPrimary } from '../ComponentApp/buttoms';

const Onboarding = () => {
  const navigate = useNavigate();
  const theme = useTheme();

  return (
    <Stack
      spacing={3}
      sx={{
        backgroundColor: theme.palette.primary.blueLight,
        padding: '20px',
        borderRadius: '8px',
        boxShadow: '0px 0px 12px rgba(61, 64, 75, 0.18)',
      }}
      direction={{ md: 'row', xs: 'column' }}
      justifyContent='space-between'
      alignItems='center'>
      <Stack width={{ md: '60%', xs: '100%' }}>
        <Typography
          sx={{
            color: theme.palette.primary.main,
            fontSize: theme.typography.h1.fontSize,
            [theme.breakpoints.down('sm')]: {
              fontSize: theme.typography.titleText.fontSize,
            },
          }}>
          ¿Aún no has completado tu registro?
        </Typography>
        <Typography
          sx={{
            mt: 2,
            fontSize: theme.typography.availableBalance.fontSize,
            color: theme.palette.primary.dark,
          }}>
          Antes de realizar tu primera inversión necesitamos conocer un poco más de ti. Completa tu
          registro y empieza a invertir ahora.
        </Typography>
      </Stack>

      <Stack width={{ md: '20%', xs: '100%' }}>
        <ButtonsPrimary
          name='Completar registro'
          onClick={() => navigate('/completeregistration')}
        />
      </Stack>
    </Stack>
  );
};

export default Onboarding;
