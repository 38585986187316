import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import React from 'react';

const Customselect = props => {
  return (
    <>
      <FormControl fullWidth style={{ maxWidth: 450 }} variant='standard' className='custom-select'>
        <InputLabel id={props.Labelid}>{props.placholder}</InputLabel>
        <Select
          labelId={props.Selectid}
          id={props.id}
          label={props.label}
          onChange={e => props.onChange(e.target.value)}
          value={props.menuItems.length > 0 && props.value ? props.value : ''}>
          {props.menuItems?.map((item, index) => (
            <MenuItem key={index} value={item}>
              {item.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
};

export default Customselect;
