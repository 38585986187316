import { Autoplay, Navigation, Pagination } from 'swiper/modules';
import CarouselItemDashboard from './CarouselItemDashboard';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Box } from '@mui/material';
import Spinner from '../Spinner';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css';

const CarouselDashboard = ({ carouselImg, isLoadingCarouselImg, errorCarousel }) => {
  if (isLoadingCarouselImg) {
    return <Spinner />;
  }

  if (errorCarousel) {
    throw new Error('Error al cargar las imágenes del carrusel');
  }

  return (
    <Box width='100%' height='100%'>
      <Swiper
        className='swipper-dashboard'
        modules={[Autoplay, Pagination, Navigation]}
        effect='fade'
        spaceBetween={50}
        slidesPerView={1}
        loop={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
          pauseOnMouseEnter: true,
        }}
        navigation
        pagination={{ clickable: true }}
        scrollbar={{ draggable: true }}>
        {carouselImg.map(item => (
          <SwiperSlide key={item.buttonText2}>
            <CarouselItemDashboard item={item} />
          </SwiperSlide>
        ))}
      </Swiper>
    </Box>
  );
};

export default CarouselDashboard;
