/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "Auth": {
        "region": "us-east-1",
        "identityPoolRegion": "us-east-1",
        "userPoolId": "us-east-1_W85muw4w8",
        "userPoolWebClientId": "1anuaaistmjmql6m03fa95u41u"
    }
};


export default awsmobile;
