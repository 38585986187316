import { Box, Stack, Typography, useTheme } from '@mui/material';
import React from 'react';

function Footer() {
  const theme = useTheme();
  const date = new Date();
  const currentYear = date.getFullYear();

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '16px 20px',
        bottom: 0,
        height: '106px',
        width: '100%',
        backgroundColor: theme.palette.primary.main,
      }}>
      <Stack
        direction='row'
        justifyContent='center'
        alignItems='center'
        spacing={2}
        sx={{
          margin: '14px 0',
        }}>
        <img src='/assets/images/footer-logo.svg' alt='footer-logo' />
        <Stack direction='row' justifyContent='center' alignItems='center' spacing={2}>
          <Typography
            sx={{
              marginTop: '10px',
              textAlign: 'center',
              color: theme.palette.white.main,
              fontWeight: theme.typography.h1.fontWeight,
              verticalAlign: 'center',
            }}>
            © {currentYear}
          </Typography>
        </Stack>
      </Stack>
    </Box>
  );
}

export default Footer;
