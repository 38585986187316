import { Button, Stack, Typography, styled } from '@mui/material';
import React from 'react';

const TitleWallet = styled(Stack)({
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'colunm',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '0px',
  boxSizing: 'border-box',
  alignSelf: 'stretch',
  height: '100%',
  width: '100%',
  maxHeight: '20px',
  maxWidth: '100%',
});

const AvailableBalance = styled(Typography)({
  textAlign: 'center',
  whiteSpace: 'pre-wrap',
  fontSynthesis: 'none',
  color: 'rgba(30, 34, 170, 1)',
  fontWeight: '600',
  fontSize: '20px',
  letterSpacing: '0px',
  textDecoration: 'none',
  lineHeight: '126.49999856948853%',
  textTransform: 'none',
  margin: '0px',
});

const ContainerWalletActions = styled(Stack)({
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '0px',
  boxSizing: 'border-box',
  alignSelf: 'stretch',
  margin: '0px',
  height: '90px',
  width: '100%',
});

const BtnLoadWallet = styled(Button)({
  backgroundColor: 'rgba(30, 34, 170, 1)',
  color: '#fff',
  borderRadius: '4px',
  position: 'relative',
  isolation: 'isolate',
  padding: '12px 8px',
  boxSizing: 'border-box',
  height: '31px',
  width: '120px',
  textTransform: 'none',
  '&:hover': {
    backgroundColor: '#4449D8',
  },
  fontStyle: 'normal',
  fontFamily: 'Poppins',
  fontWeight: '400',
  fontSize: '10px',
  letterSpacing: '0px',
  textDecoration: 'none',
  textTransform: 'none',
  margin: '0px',
});

const IconsNotification = styled('img')({
  height: '25px',
  width: '25px',
  backgroundColor: '#3B82f6',
  borderRadius: '100%',
  padding: '5px',
});

const TitleAmount = styled(Typography)({
  textAlign: 'center',
  whiteSpace: 'pre-wrap',
  fontSynthesis: 'none',
  color: 'rgba(0, 0, 0, 1)',
  fontStyle: 'normal',
  fontFamily: 'Poppins',
  fontWeight: '400',
  letterSpacing: '-0.04px',
  textDecoration: 'none',
  textTransform: 'none',
  alignSelf: 'stretch',
  margin: '0px',
});

const WalletNoAmountCard = ({ handleRedirect }) => {
  return (
    <>
      <TitleWallet>
        <AvailableBalance sx={{ marginTop: '12px' }}>
          <IconsNotification
            src='/assets/images/dashboard/iconNotification.svg'
            alt='Notification'
          />
        </AvailableBalance>

        <TitleAmount fontSize={'clamp(12px, 2vw, 14px)'}>¡Carga tu caja para invertir!</TitleAmount>
      </TitleWallet>
      <ContainerWalletActions sx={{ marginTop: '16px' }}>
        <BtnLoadWallet onClick={() => handleRedirect()}>Carga aquí tu caja</BtnLoadWallet>
      </ContainerWalletActions>
    </>
  );
};

export default WalletNoAmountCard;
