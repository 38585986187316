import { useTheme } from '@mui/material';
import React, { useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { formatChileanDate } from '../../utils/dates';
import { numberWithPoint } from '../../utils/numbers';

const Charts = ({ data, dividends, productdetail, serieName, showLabels }) => {
  const theme = useTheme();
  const [state, setState] = useState({
    series: [
      {
        name: 'Actual',
        data: [],
      },
    ],
    options: {
      chart: {
        height: 350,
        type: 'bar',
      },
      stroke: { width: 1 },
      colors: [theme.palette.primary.main],
      dataLabels: {
        enabled: true,
        hideOverflowingLabels: true,
        textAnchor: 'start',
        style: {
          fontSize: '20px',
          fontWeight: 'bold',
          colors: [theme.palette.secondary.text],
        },
        background: {
          enabled: true,
          foreColor: 'white',
          borderRadius: '50%',
          padding: 10,
          opacity: 0.9,
          borderWidth: 1,
          borderColor: theme.palette.white.main,
        },
        formatter: (val, opt) => {
          const goals = opt.w.config.series[opt.seriesIndex].data[opt.dataPointIndex].goals;
          if (goals?.length) {
            return '$';
          }
          return '';
        },
      },
      xaxis: {
        tickAmount: 10,
        labels: {
          show: showLabels,
        },
        axisTicks: {
          show: false,
        },
        axisBorder: {
          show: true,
        },
      },
      yaxis: {
        show: showLabels,
        labels: {
          formatter: value => {
            if (Number.parseInt(value) > 50) {
              if (typeof value === 'string') {
                return `${numberWithPoint(Number.parseInt(value))}`;
                // biome-ignore lint/style/noUselessElse: <explanation>
              } else {
                return `$${numberWithPoint(Number.parseInt(value))}`;
              }
              // biome-ignore lint/style/noUselessElse: <explanation>
            } else {
              let valueParse = 0;
              if (typeof value === 'string') {
                valueParse = `${Number.parseFloat(value).toFixed(2)}`;
              } else {
                valueParse = `$${value.toFixed(2)}`;
              }
              return valueParse;
            }
          },
        },
      },
      grid: {
        show: true,
        borderColor: '#90A4AE',
        strokeDashArray: 0,
        position: 'back',
        xaxis: {
          lines: {
            show: false,
          },
        },
        yaxis: {
          lines: {
            show: false,
          },
        },
      },
    },
  });

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  React.useEffect(() => {
    if (data.length > 0 && dividends) {
      const dataSerie1 = data
        .sort((a, b) => new Date(a.EffectiveDate) - new Date(b.EffectiveDate))
        .map(item => {
          const index = dividends.findIndex(
            dividend =>
              formatChileanDate(dividend.provisionDate) === formatChileanDate(item.EffectiveDate),
          );
          const goals = [];
          if (index >= 0) {
            const goal = {
              name: 'Dividendo: ',
              value: `${dividends[index].quotaFactor}`,
              strokeWidth: 0,
              strokeDashArray: 0,
              strokeColor: null,
            };
            goals.push(goal);
          }
          return {
            x: formatChileanDate(item.EffectiveDate),
            y:
              item.ClosePrice > 50
                ? Number.parseFloat(item.ClosePrice).toFixed(0)
                : Number.parseFloat(item.ClosePrice).toFixed(2),
            goals: goals,
          };
        });
      setState({
        ...state,
        series: [
          {
            name: serieName || 'Valor Cuota',
            data: dataSerie1,
          },
        ],
      });
    }
  }, [JSON.stringify(data), JSON.stringify(dividends)]);

  return (
    <ReactApexChart
      options={state.options}
      series={state.series}
      type='area'
      height={productdetail === 'productdetail' ? 400 : 180}
    />
  );
};

export default Charts;
