import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Spinner from '../../../components/Spinner';
import { HeaderProducts } from '../../../components/XcalaComponents';
import { NotificationsXcala } from '../../../components/XcalaComponents/Notifications/NotificationsXcala';
import BodyInvertedPortfolio from '../../../components/portfolio/portolioInvestment/BodyInvertedPortfolio';
import useModal from '../../../hooks/custom/useModal';
import { useGetClientInvestmentPortfoliosById } from '../../../hooks/useGetClientInvestmentPortfoliosById';
import useGetModelsPortfolio from '../../../hooks/useGetModelsPortfolio';
import { useGetTransactionsPortfolioInvestmentDetails } from '../../../hooks/useGetTransactionsPortfolioInvestmentDetails';
import { setMyInvestmentSelected } from '../../../redux/investments';
import { setPortfolioSelected } from '../../../redux/portfolio';
import { portfolioNameAndDetails } from '../../../utils/portfolioNameAndDetails';
import { onRefresh } from '../../../utils/refreshPage';

const PortfolioInvestmentDetails = () => {
  const portfolioSelectedById = useSelector(state => state.portfolio.portfolioSelected);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isShowing, toggle } = useModal();

  const {
    data: dataPortfolioInvestmentId,
    loading: loadingGetPortfolioById,
    error: errorGetPortfolioById,
  } = useGetClientInvestmentPortfoliosById({
    accountId: portfolioSelectedById,
  });
  const successGetPortfolioById = dataPortfolioInvestmentId?.getClientInvestmentsPortfoliosById;

  const {
    data: dataPortfolioTransaction,
    error: errorPortfolioTransaction,
    loading: loadingPortfolioTransaction,
  } = useGetTransactionsPortfolioInvestmentDetails({
    accountId: portfolioSelectedById,
  });
  const successGetPortfolioTransaction =
    dataPortfolioTransaction?.getClientTransactionDetailPortfolio;

  const handleInvestMore = id => {
    navigate(`/portfolio-simulation/${id}/investment`);
  };

  const handleRescueInvestment = id => {
    dispatch(setMyInvestmentSelected(id));
    navigate('/rescue-portfolio');
  };

  const {
    data: dataModelsPortfolio,
    loading: loadingModelsPortfolio,
    error: errorModelsPortfolio,
  } = useGetModelsPortfolio();

  const successDataModelsPortfolio = dataModelsPortfolio?.getModels;

  if (loadingGetPortfolioById || loadingModelsPortfolio) {
    return <Spinner />;
  }
  if (errorGetPortfolioById || errorPortfolioTransaction) {
    return (
      <NotificationsXcala
        activation={!isShowing}
        message='Lo sentimos, ha ocurrido un error inesperado....'
        nameBtnTwo='Enviar error'
        toggle={toggle}
        refresh={onRefresh}
        typeNotification='fetch'
        error={errorGetPortfolioById || errorPortfolioTransaction}
      />
    );
  }

  const porfolioDetailHeader = successGetPortfolioById.find(
    portfolio => portfolio?.security.toLowerCase() === 'caja',
  );
  const fileAs = portfolioNameAndDetails(porfolioDetailHeader?.fileAs);
  const ModelId = successDataModelsPortfolio.find(
    item => item.fileAs === porfolioDetailHeader?.fileAs,
  );

  const gotoPortfolioDetails = () => {
    dispatch(setPortfolioSelected(ModelId.id));
    navigate('/portfolio-details');
  };

  return (
    <>
      <HeaderProducts
        handleInvestMore={handleInvestMore}
        handleRescueInvestment={handleRescueInvestment}
        idSelected={portfolioSelectedById}
        ModelId={ModelId.id}
        fileAs={fileAs}
        goToProductDetail={gotoPortfolioDetails}
        details={true}
      />
      <BodyInvertedPortfolio
        successGetPortfolioById={successGetPortfolioById}
        loadingPortfolioTransaction={loadingPortfolioTransaction}
        successGetPortfolioTransaction={successGetPortfolioTransaction}
      />
    </>
  );
};

export default PortfolioInvestmentDetails;
