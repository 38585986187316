import React from 'react';
import Spinner from '../../Spinner';
import { CardsFound } from './CardsFound';

export const CardFoundWrapper = ({
  loadingGetSecurityList,
  errorGetSecurityList,
  addCostEffectiveness,
}) => {
  if (loadingGetSecurityList) {
    return <Spinner />;
  }

  if (errorGetSecurityList) {
    throw new Error('Error cardFound');
  }

  return (
    <>
      {addCostEffectiveness.map((security, index) => {
        const { Name, currency, profit } = security;
        return (
          <CardsFound
            loadingGetSecurityList={loadingGetSecurityList}
            errorGetSecurityList={errorGetSecurityList}
            addCostEffectiveness={addCostEffectiveness}
            key={security.ID}
            security={security}
            fundName={Name.slice(4)}
            rentabilityFound={profit}
            typeCurrency={currency}
          />
        );
      })}
    </>
  );
};
