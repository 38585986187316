import { Grid, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ErrorBoundaryXcala } from '../../../components/ErrorBoundaryXcala';
import {
  BackgroundDashboard,
  CarouselContent,
  GreetingDashboard,
  SectionHeader,
  WalletSection,
} from '../../../components/XcalaComponents';
import CarouselDashboard from '../../../components/carouselDashboard/CarouselDashboard';
import { WalletDashboard } from '../../../components/dashboard/WalletDashboard';
import { CardFoundViewWrapper } from '../../../components/products/newProducts/CardFoundViewWrapper';
import { SectionFilter } from '../../../components/products/newProducts/SectionFilter';
import { useGetCarousel } from '../../../hooks/useCarousel';
import { useGetCashBalanceWithFallback } from '../../../hooks/useGetCashBalanceWithFallback';
import useGetSecurityList from '../../../hooks/useGetSecurityList';
import { addCostEffectivenessFn } from '../../../utils/addCostEffectivemess';
import { sendDataToTagManager } from '../../../utils/sendDataToTagManager';

const NewProducts = () => {
  const { fundName } = useParams();
  const [filteredFundsByParams, setFilteredFundsByParams] = useState([]);
  const matchNameSecurity = {
    'deuda-privada': [
      'HMC Deuda Privada Global',
      'HMC Deuda Privada Pesos',
      'HMC Rendimiento Estratégico Dólar',
      'HMC Rendimiento Estratégico Pesos',
      'HMC Renta Global USD',
      'HMC Renta Global Pesos',
    ],
    'real-estate': ['HMC Global Real Estate Income'],
    'hedge-funds': ['HMC Portafolio Óptimo'],
    'venture-capital': ['HMC US Venture Opportunities'],
    criptoactivos: ['HMC Bitcoin'],
  };

  const [fundsFilteredToShow, setFundsFilteredToShow] = useState([]);

  const [riskProfile, setRiskProfile] = useState('');

  const [motivationsFilters, setMotivationsFilters] = useState([]);

  const CustomMotivaciones = {
    'Hedge funds': 'Retorno absoluto',
  };

  const handleChangeProfileRisk = event => {
    const {
      target: { value },
    } = event;
    setRiskProfile([value]);
  };

  const handleChangeMotivations = event => {
    const {
      target: { value },
    } = event;

    setMotivationsFilters(value);
  };

  const {
    data: carouselImg,
    error: errorCarousel,
    isError: isErrorCarousel,
    isLoading: isLoadingCarouselImg,
  } = useGetCarousel();

  const {
    data: balanceData,
    loading: loadingBalance,
    error: errorBalance,
  } = useGetCashBalanceWithFallback();

  const {
    data: dataSecurityList,
    error: errorGetSecurityList,
    loading: loadingGetSecurityList,
    refetch,
  } = useGetSecurityList();

  const successGetSecurityList = dataSecurityList?.getSecurityList || [];

  const filterFromCarousel = () => {
    if (!successGetSecurityList || successGetSecurityList.length === 0) {
      return;
    }
    const list = successGetSecurityList?.filter(item =>
      matchNameSecurity[fundName].includes(item.Name),
    );

    setFilteredFundsByParams(list);
  };

  const handleCleanFilters = () => {
    setMotivationsFilters([]);
    setRiskProfile([]);
    setFilteredFundsByParams(successGetSecurityList);
    setFundsFilteredToShow(addCostEffectiveness);
  };

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    sendDataToTagManager('products', '/products');
    //doGetProfitability();
  }, []);

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    if (errorGetSecurityList) {
      return;
    }
    if (successGetSecurityList && !loadingGetSecurityList) {
      fundName ? filterFromCarousel() : setFilteredFundsByParams(successGetSecurityList);
    }
  }, [fundName, successGetSecurityList]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    setFundsFilteredToShow(addCostEffectiveness);
  }, [filteredFundsByParams, riskProfile, motivationsFilters]);

  useEffect(() => {
    if (riskProfile.length === 0 && motivationsFilters.length === 0) {
      setFundsFilteredToShow(addCostEffectiveness);
    }

    const originalData = addCostEffectiveness;

    let filteredData = originalData;

    if (riskProfile.length === 1) {
      filteredData = filteredData.filter(fund => {
        const json = JSON.parse(fund.Custom);
        return riskProfile.some(
          risk => json.preferences.riesgo.description.toLowerCase() === risk.toLowerCase(),
        );
      });
    }

    if (motivationsFilters.length > 0) {
      // biome-ignore lint/complexity/noForEach: <explanation>
      motivationsFilters.forEach(motivations => {
        if (motivations) {
          filteredData = filteredData.filter(fund => {
            const json = JSON.parse(fund.Custom);
            return (
              json.preferences.intereses[0].description
                ?.toLowerCase()
                .includes(CustomMotivaciones[motivations]?.toLowerCase()) ||
              json.preferences.intereses[0].description
                ?.toLowerCase()
                .includes(motivations?.toLowerCase())
            );
          });
        }
      });
    }

    setFundsFilteredToShow(filteredData);
  }, [riskProfile, motivationsFilters]);

  const addCostEffectiveness = addCostEffectivenessFn(filteredFundsByParams);

  return (
    <BackgroundDashboard>
      <Stack
        width={'90%'}
        alignItems={'center'}
        gap={2}
        justifyContent={{
          xs: 'center',
          ms: 'center',
          md: 'flex-start',
          lg: 'flex-start',
        }}>
        <GreetingDashboard />

        <SectionHeader
          gap={'8px'}
          flexWrap={{ md: 'no-wrap', xs: 'wrap' }}
          width={'100%'}
          justifyContent={'space-between'}
          flexDirection={'row'}>
          <CarouselContent
            m={0}
            justifyContent={'center'}
            alignItems={'center'}
            minWidth={{ lg: '78%', md: '100%', sm: '100%', xs: '100%' }}
            height={'100%'}
            sx={{
              '@media (max-width: 1493px)': {
                flexDirection: 'column',
                alignItems: 'center',
                width: '100%',
              },
            }}>
            <Stack width={'100%'} justifyContent={'center'} alignItems={'center'}>
              <CarouselDashboard
                carouselImg={carouselImg}
                isLoadingCarouselImg={isLoadingCarouselImg}
              />
            </Stack>
          </CarouselContent>

          <WalletSection
            flexBasis={'1'}
            minWidth={{ lg: '21%', md: '100%', sm: '100%', xs: '100%' }}
            style={{ marginTop: 0 }}
            sx={{
              '@media (max-width: 1493px)': {
                flexDirection: 'column',
                alignItems: 'center',
                width: '100%',
              },
            }}>
            <ErrorBoundaryXcala>
              <WalletDashboard
                dataSecurityList={dataSecurityList}
                balanceData={balanceData}
                errorBalance={errorBalance}
                loadingBalance={loadingBalance}
              />
            </ErrorBoundaryXcala>
          </WalletSection>
        </SectionHeader>

        <SectionFilter
          riskProfile={riskProfile}
          motivationsFilters={motivationsFilters}
          handleChangeProfileRisk={handleChangeProfileRisk}
          handleChangeMotivations={handleChangeMotivations}
          handleCleanFilters={handleCleanFilters}
        />

        <Grid
          container
          width={'100%'}
          rowSpacing={2}
          columnSpacing={2}
          columns={{ xs: 4, sm: 12, md: 12, lg: 12 }}
          marginBottom={8}
          marginTop={2}
          sx={{
            '@media (max-width: 1724px)': {
              '& > *': {
                flexBasis: '33.33% !important',
                width: '33.33% !important',
              },
            },
            '@media (max-width: 1284px)': {
              '& > *': {
                flexBasis: '50% !important',
                width: '50% !important',
              },
            },
            '@media (max-width: 1064px)': {
              '& > *': {
                flexBasis: '100% !important',
                width: '100% !important',
              },
            },
          }}>
          <ErrorBoundaryXcala>
            <CardFoundViewWrapper
              fundsFilteredToShow={fundsFilteredToShow}
              loadingGetSecurityList={loadingGetSecurityList}
              errorGetSecurityList={errorGetSecurityList}
            />
          </ErrorBoundaryXcala>
        </Grid>
      </Stack>
    </BackgroundDashboard>
  );
};

export default NewProducts;
