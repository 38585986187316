import { Stack, Typography, styled, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { numberWithPoint } from '../../utils/numbers';
import { ButtonsPrimary } from '../ComponentApp/buttoms';
import Spinner from '../Spinner';
import AvatarDashboard from './AvatarDashboard';
import WalletNoAmountCard from './WalletNoAmountCard';

export const ContainerWallet = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.white.main,
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'column',
  height: '100%',
  width: '100%',
  minHeight: '315px',
  maxWidth: '100%',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '8px 16px',
  boxSizing: 'border-box',
}));

const MenuHeader = styled(Stack)({
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  alignItems: 'center',
  padding: '0px',
  boxSizing: 'border-box',
  alignSelf: 'stretch',
  margin: '0px',
  width: '100%',
});

const TitleWallet = styled(Stack)({
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'colunm',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '0px',
  boxSizing: 'border-box',
  alignSelf: 'stretch',
  height: '100%',
  width: '100%',
  maxHeight: '20px',
  maxWidth: '100%',
});

const AvailableBalanceTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.dark,
  fontSize: theme.typography.fontSize,
  fontWeight: theme.typography.h1.fontWeight,
  textAlign: 'center',
  whiteSpace: 'pre-wrap',
  lineHeight: '126.5%',
  margin: '0px',
}));

const AvailableBalance = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: theme.typography.availableBalance.fontSize,
  fontWeight: theme.typography.h1.fontWeight,
  textAlign: 'center',
  whiteSpace: 'pre-wrap',
  lineHeight: '126.5%',
  margin: '0px',
}));

const ContainerWalletActions = styled(Stack)({
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '0px',
  boxSizing: 'border-box',
  alignSelf: 'stretch',
  margin: '0px',
  height: '90px',
  width: '100%',
});

export const WalletDashboard = ({ balanceData, loadingBalance, errorBalance }) => {
  const { user } = useSelector(state => state.auth);

  const [accessWallet, setAccessWallet] = useState(false);
  const navigate = useNavigate();
  const theme = useTheme();

  const handleRedirect = () => {
    if (!accessWallet) {
      return navigate('/completeregistration');
    }
    if (cashBalance?.marketValue === 0) {
      return navigate('/payments');
    }
    if (cashBalance?.marketValue >= 1) {
      return navigate('/wallet');
    }

    return navigate('/payments');
  };

  const handleRedirectToWallet = () => {
    if (!accessWallet) {
      return navigate('/completeregistration');
    }

    return navigate('/wallet');
  };

  useEffect(() => {
    if (
      user?.['custom:checkid-sign-doc'] &&
      user['custom:checkid-sign-doc'] === 'signature_success'
    ) {
      setAccessWallet(true);
    }
  }, [user]);

  if (loadingBalance) {
    return <Spinner />;
  }

  if (errorBalance) {
    throw new Error('Error component wallet dashboard');
  }
  const cashBalance = balanceData?.totalAmount;

  return (
    <ContainerWallet onClick={handleRedirectToWallet} sx={{ cursor: 'pointer' }}>
      <MenuHeader sx={{ marginTop: '20px' }} />
      <AvatarDashboard riskProfile={user['custom:profile'].toLowerCase()} />

      {cashBalance && cashBalance?.marketValue !== null ? (
        <>
          <TitleWallet>
            <AvailableBalanceTitle>Caja Total</AvailableBalanceTitle>
            <AvailableBalance>{`$ ${numberWithPoint(Math.floor(cashBalance))}`}</AvailableBalance>
          </TitleWallet>
          <ContainerWalletActions>
            <ButtonsPrimary
              name='Carga aquí tu caja'
              onClick={() => handleRedirect()}
              size='xs'
              sx={{
                '& .MuiTypography-root': {
                  fontSize: theme.typography.customButton.fontSize,
                },
              }}
            />
          </ContainerWalletActions>
        </>
      ) : cashBalance && cashBalance.marketValue === 0 ? (
        <WalletNoAmountCard handleRedirect={handleRedirect} />
      ) : (
        <WalletNoAmountCard handleRedirect={handleRedirect} />
      )}
    </ContainerWallet>
  );
};
