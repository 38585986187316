import { Box, Button, Stack, Typography, styled, useTheme } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import useModal from '../../../hooks/custom/useModal';
import { numberWithPoint } from '../../../utils/utils';
import { ButtonsPrimary } from '../../ComponentApp/buttoms';
import ModalRescueDuplicate from '../../UI/ModalRescueDuplicate';
import { RescueContentContent } from '../../portfolio/rescuePortfolio/RescueComponent';

export const ModalContainer = styled(Stack)({
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  padding: '0px',
  boxSizing: 'border-box',
  flex: '1',
  margin: '0px',
  width: '100%',
});

export const MuntCashContiner = styled(Stack)(({ theme }) => ({
  background: theme.palette.primary.gradient,
  borderRadius: '8px',
  display: 'flex',
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  padding: '16px',
  boxSizing: 'border-box',
  alignSelf: 'stretch',
  margin: '4px 0px 0px 0px',
  width: '100%',
}));
export const MuntContainer = styled(Stack)({
  display: 'flex',
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '0px',
  boxSizing: 'border-box',
  alignSelf: 'stretch',
  margin: '0px',
  height: '17px',
  width: '100%',
});
export const MuntContextRescue = styled(Typography)(({ theme }) => ({
  textAlign: 'left',
  whiteSpace: 'pre-wrap',
  fontSynthesis: 'none',
  color: theme.palette.white.main,
  fontWeight: theme.typography.littleText.fontWeight,
  fontSize: theme.typography.littleText.fontSize,
  letterSpacing: '0px',
  textDecoration: 'none',
  textTransform: 'none',
  margin: '0px',
}));
export const MuntContext = styled(Typography)(({ theme }) => ({
  textAlign: 'left',
  whiteSpace: 'pre-wrap',
  fontSynthesis: 'none',
  color: theme.palette.white.main,
  fontWeight: theme.typography.littleText.fontWeight,
  fontSize: theme.typography.mediumText.fontSize,
  letterSpacing: '0px',
  textDecoration: 'none',
  textTransform: 'none',
  margin: '0px',
}));
export const ValueMuntRescue = styled(Typography)(({ theme }) => ({
  textAlign: 'left',
  whiteSpace: 'pre-wrap',
  fontSynthesis: 'none',
  color: theme.palette.white.main,
  fontWeight: theme.typography.littleText.fontWeight,
  letterSpacing: '0px',
  textDecoration: 'none',
  textTransform: 'none',
  margin: '0px',
}));

export const ValueMunt = styled(Typography)(({ theme }) => ({
  textAlign: 'left',
  whiteSpace: 'pre-wrap',
  fontSynthesis: 'none',
  color: theme.palette.primary.dark,
  fontWeight: theme.typography.littleText.fontWeight,
  letterSpacing: '0px',
  textDecoration: 'none',
  textTransform: 'none',
  margin: '0px',
}));
export const TypeChange = styled(Typography)({
  textAlign: 'left',
  whiteSpace: 'pre-wrap',
  fontSynthesis: 'none',
  color: '#D9D9D9',
  fontStyle: 'normal',
  fontFamily: 'Poppins',
  fontWeight: '400',
  letterSpacing: '0px',
  textDecoration: 'none',
  textTransform: 'none',
  margin: '5px 0px 0px 0px',
});

export const BtnContainer = styled(Stack)({
  position: 'relative',
  isolation: 'isolate',
  justifyContent: 'center',
  padding: '0px',
  boxSizing: 'border-box',
  margin: '8px 0px 0px 0px',
  width: '80%',
});

export const MsnAlert = styled(Stack)(({ theme }) => ({
  border: 'red',
  boxSizing: 'border-box',
  borderRadius: '8px',
  display: 'flex',
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'column',
  width: '100%',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  padding: '10px 8px',
  gap: '16px',
  backgroundColor: theme.palette.primary.textLightBlue,
}));

export const TitleAlert = styled(Stack)({
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  padding: '0px',
  boxSizing: 'border-box',
  alignSelf: 'stretch',
  margin: '0px',
  height: '24px',
  width: '100%',
});

export const WarningAmber = styled(Box)({
  width: '24px',
  height: '24px',
  margin: '0px',
});

export const TextImportante = styled(Typography)(({ theme }) => ({
  textAlign: 'left',
  whiteSpace: 'pre-wrap',
  fontSynthesis: 'none',
  color: theme.palette.secondary.green,
  fontWeight: theme.typography.h1.fontWeight,
  letterSpacing: '0px',
  textDecoration: 'none',
  textTransform: 'none',
  width: '115.5px',
  margin: '0px 0px 0px 16px',
}));

export const IconsWarnning = () => {
  return (
    // biome-ignore lint/a11y/noSvgWithoutTitle: <explanation>
    <svg width='25' height='24' viewBox='0 0 25 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g id='warning_amber'>
        <g id='Vector'>
          <path
            d='M12.3044 6.49L19.8344 19.5H4.77444L12.3044 6.49ZM12.3044 2.5L1.30444 21.5H23.3044L12.3044 2.5Z'
            fill='rgb(1, 67, 97)'
          />
          <path d='M13.3044 16.5H11.3044V18.5H13.3044V16.5Z' fill='rgb(1, 67, 97)' />
          <path d='M13.3044 10.5H11.3044V15.5H13.3044V10.5Z' fill='rgb(1, 67, 97)' />
        </g>
      </g>
    </svg>
  );
};

export const ParrafoAlert = styled(Typography)(({ theme }) => ({
  textAlign: 'left',
  whiteSpace: 'pre-wrap',
  fontSynthesis: 'none',
  color: theme.palette.primary.dark,
  fontWeight: theme.typography.littleText.fontWeight,
  fontSize: theme.typography.button.fontSize,
  [theme.breakpoints.down('sm')]: {
    fontSize: theme.typography.customButton.fontSize,
  },
  letterSpacing: '0px',
  textDecoration: 'none',
  textTransform: 'none',
  alignSelf: 'stretch',
  margin: '0px auto',
}));

const ReceivedRescueFunds = ({ responseErrorRescue, cuotas, rescueAmount, days, date }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { isShowing, toggle } = useModal();
  return (
    <>
      {responseErrorRescue.includes('error') ? (
        <>
          <ModalRescueDuplicate open={!isShowing} onClose={toggle} />
          <Stack
            sx={{
              width: '100%',
              maxWidth: '1200px',
              margin: '0 auto',
              alignItems: 'center',
              mb: '25px',
            }}>
            <RescueContentContent
              width={{ md: '100%', sx: '80%' }}
              height={{ md: '340px', sx: '400' }}
              minWidth={{ md: '740px', sx: '' }}
              mb={9}>
              <ModalContainer>
                <MuntCashContiner>
                  <MuntContainer>
                    <MuntContext>Monto solicitado</MuntContext>
                    <ValueMunt
                      sx={{
                        fontSize: 'clamp(0.85rem, 0.0313rem + 1.5vw, 2.75rem)',
                      }}>
                      $ {numberWithPoint(rescueAmount)}
                    </ValueMunt>
                  </MuntContainer>

                  <TypeChange
                    sx={{
                      fontSize: 'clamp(0.625rem, 0.5357rem + 0.381vw, 1.75rem)',
                      marginTop: 1,
                    }}>
                    {`Equivalente en cuotas $${cuotas}`}
                  </TypeChange>
                </MuntCashContiner>

                <MsnAlert sx={{ marginTop: 3, marginBottom: 3 }}>
                  <TitleAlert>
                    <WarningAmber>
                      <IconsWarnning />
                    </WarningAmber>
                    <TextImportante>Importante</TextImportante>
                  </TitleAlert>
                  <ParrafoAlert
                    sx={{
                      fontSize: 'clamp(0.75rem, 0.5673rem + 0.7692vw, 1rem)',
                      width: '100%',
                      margin: '0 auto',
                    }}>
                    Te informaremos por correo cuando el rescate esté disponible en tu caja.
                  </ParrafoAlert>
                </MsnAlert>
                <BtnContainer
                  gap={2}
                  mt={50}
                  sx={{
                    flexDirection: { xs: 'column', md: 'row' },
                  }}>
                  <Button
                    sx={{
                      width: { md: '55%' },
                      padding: '16px',
                      textTransform: 'none',
                    }}
                    variant='contained'
                    disableElevation
                    onClick={() => navigate('/recurring-dash')}>
                    Volver al inicio
                  </Button>
                </BtnContainer>
              </ModalContainer>
            </RescueContentContent>
          </Stack>
        </>
      ) : (
        <Stack
          sx={{
            width: '100%',
            maxWidth: '1200px',
            margin: '0 auto',
            alignItems: 'center',
            mb: '25px',
          }}>
          <RescueContentContent
            width={{ md: '100%', sx: '80%' }}
            height={{ md: 'auto', sx: 'auto' }}
            minWidth={{ md: '740px', sx: '' }}
            mb={9}>
            <ModalContainer>
              <Stack direction={'row'} alignItems={'center'} alignSelf={'center'}>
                <Stack width={'70px'} height={'70px'}>
                  <img
                    src='/assets/images/sucess-gif.gif'
                    alt='success'
                    style={{ width: '100%', height: '100%', display: 'block' }}
                  />
                </Stack>
                <Typography sx={{ color: '#49a197', fontSize: '1.3rem', fontWeight: 600 }}>
                  ¡Hemos recibido tu solicitud!
                </Typography>
              </Stack>

              <MuntCashContiner>
                <MuntContainer>
                  <MuntContext>Monto solicitado</MuntContext>
                  <ValueMunt
                    sx={{
                      fontSize: 'clamp(0.85rem, 0.0313rem + 1.5vw, 1.1rem)',
                      color: theme.palette.white.main,
                      fontWeight: theme.typography.button.fontWeight,
                    }}>
                    $ {numberWithPoint(rescueAmount)}
                  </ValueMunt>
                </MuntContainer>

                <MuntContainer sx={{ marginTop: 2 }}>
                  <MuntContext>Equivalente en cuotas:</MuntContext>
                  <ValueMunt
                    sx={{
                      fontSize: 'clamp(0.85rem, 0.0313rem + 1.5vw, 1.1rem)',
                      color: theme.palette.white.main,
                      fontWeight: theme.typography.button.fontWeight,
                    }}>
                    {cuotas}
                  </ValueMunt>
                </MuntContainer>
              </MuntCashContiner>

              <MuntCashContiner sx={{ background: theme.palette.white.main }}>
                <MuntContainer sx={{ marginTop: 2 }}>
                  <MuntContext
                    sx={{
                      fontSize: 'clamp(0.85rem, 0.0313rem + 1.5vw, 1.1rem)',
                      fontWeight: theme.typography.smallText.fontWeight,
                      color: theme.palette.primary.dark,
                    }}>
                    Plazo de rescate:
                  </MuntContext>
                  <ValueMunt
                    sx={{
                      fontSize: 'clamp(0.85rem, 0.0313rem + 1.5vw, 1.1rem)',
                      fontWeight: 300,
                      color: theme.palette.primary.dark,
                    }}>
                    {days}
                  </ValueMunt>
                </MuntContainer>
                <MuntContainer sx={{ marginTop: 2 }}>
                  <MuntContext
                    sx={{
                      fontSize: 'clamp(0.85rem, 0.0313rem + 1.5vw, 1.1rem)',
                      fontWeight: 300,
                      color: theme.palette.primary.dark,
                    }}>
                    Fecha y hora de solicitud:
                  </MuntContext>
                  <ValueMunt
                    sx={{
                      fontSize: 'clamp(0.85rem, 0.0313rem + 1.5vw, 1.1rem)',
                      fontWeight: 300,
                      color: '#182849',
                    }}>
                    {date}
                  </ValueMunt>
                </MuntContainer>
              </MuntCashContiner>

              <MsnAlert sx={{ marginTop: 3, marginBottom: 3 }}>
                <TitleAlert>
                  <WarningAmber>
                    <IconsWarnning />
                  </WarningAmber>
                  <TextImportante>Importante</TextImportante>
                </TitleAlert>
                <ParrafoAlert
                  sx={{
                    width: '90%',
                    margin: '0 auto',
                  }}>
                  Te informaremos por correo cuando el rescate esté disponible en tu caja.
                </ParrafoAlert>
              </MsnAlert>
              <BtnContainer
                gap={2}
                mt={50}
                sx={{
                  flexDirection: { xs: 'column', md: 'row' },
                }}>
                <ButtonsPrimary
                  name='Volver al inicio'
                  disableElevation
                  onClick={() => navigate('/myprofilerisk')}
                  sx={{ md: '55%' }}
                />
              </BtnContainer>
            </ModalContainer>
          </RescueContentContent>
        </Stack>
      )}
    </>
  );
};

export default ReceivedRescueFunds;
