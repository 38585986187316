import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import MoodOutlinedIcon from '@mui/icons-material/MoodOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import { useMediaQuery, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { resetSignOut } from '../../redux/auth';

const productBtn = {
  name: 'Productos',
  subMenu: [
    { id: 'product-1', btnNameSub: 'Fondos', url: '/products' },
    { id: 'product-2', btnNameSub: 'Portfolios', url: '/portfolio' },
  ],
};

const myAccountBtn = {
  name: 'Mi cuenta',
  subMenu: [
    { id: 'my account-1', btnNameSub: 'Mi perfil de riesgo', url: '/profileselect' },
    { id: 'my account-2', btnNameSub: 'Mis objetivos e intereses', url: '/myobjectivesinterests' },
    { id: 'my account-3', btnNameSub: 'Mis datos', url: '/myaccount' },
    { id: 'my account-4', btnNameSub: 'Cambiar contraseña', url: '/changepassword' },
  ],
};

export const useMenuHandlerDesktop = () => {
  const theme = useTheme();
  const [open, setOpen] = useState(true);
  const [expanded, setExpanded] = useState(false);
  const [access, setAccess] = useState(false);
  const [userNotOB, setUserNotOB] = useState(false);
  const [activeNavbar, setActiveNavbar] = useState(1);
  const [accessWallet, setAccessWallet] = useState(false);
  const [copyText, setCopyText] = useState('Copiar mi link para referir');
  const [current, setCurrent] = useState('home');
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElDashboard, setAnchorElDashboard] = useState(null);
  const [anchorElTransactions, setAnchorElTransactions] = useState(null);
  const [openMenu, setOpenMenu] = useState(null);
  const { user } = useSelector(state => state.auth);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { successSignOut, loadingSignOut } = useSelector(state => state.auth);
  const querys = useMediaQuery('(max-width:790px)');

  const walletBtn = {
    name: 'Caja',
    subMenu: [
      { id: 'wallet-1', btnNameSub: 'Caja Disponible' },
      { id: 'wallet-2', btnNameSub: 'Dinero en Tránsito' },
    ],
  };

  const menuItems = [
    {
      id: 'menu-1',
      name: 'Productos',
      icon: <ShoppingCartOutlinedIcon sx={{ color: theme.palette.white.main }} />,
      subMenu: productBtn.subMenu,
    },
    {
      id: 'menu-2',
      name: 'Referidos',
      icon: <MoodOutlinedIcon sx={{ color: theme.palette.white.main }} />,
      subMenu: [{ btnNameSub: 'Próximamente', url: '#' }],
    },
    {
      id: 'menu-3',
      name: 'Mi cuenta',
      icon: <SettingsOutlinedIcon sx={{ color: theme.palette.white.main }} />,
      subMenu: myAccountBtn.subMenu,
    },
    {
      id: 'menu-4',
      name: 'Caja',
      icon: <AccountBalanceWalletOutlinedIcon sx={{ color: theme.palette.white.main }} />,
      subMenu: walletBtn.subMenu,
    },
  ];

  const handleClick = (event, menuName) => {
    if (!open) {
      setAnchorEl(event.currentTarget);
      setOpenMenu(menuName);
    }
  };

  const handleClickDashboard = event => {
    if (!open) {
      setAnchorElDashboard(event.currentTarget);
      setOpenMenu('dashboard');
    }
  };

  const handleClickTransactions = event => {
    if (!open) {
      setAnchorElTransactions(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpenMenu(null);
    setAnchorElDashboard(null);
    setAnchorElTransactions(null);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleAccordionChange = panel => (event, isExpanded) => {
    if (open) {
      setExpanded(isExpanded ? panel : false);
    }
  };

  const handleClickHome = () => {
    navigate(access ? '/myprofilerisk' : '/profile');
  };

  const handleNavigateDashboard = val => {
    navigate(access ? '/myprofilerisk' : '/profile');
    setActiveNavbar(val);
    handleClose();
    setAnchorElDashboard(null);
  };

  const handleNavigateTransactions = val => {
    const path = userNotOB ? '/mytransactions' : '/completeregistration';
    navigate(path);
    setActiveNavbar(val);
    handleClose();
  };

  const handleNavigate = url => {
    navigate(url);
  };

  const handleNavigateWallet = () => {
    navigate(accessWallet ? '/wallet' : '/completeregistration');
  };

  useEffect(() => {
    if (
      user?.['custom:checkid-sign-doc'] &&
      user['custom:checkid-sign-doc'] === 'signature_success'
    ) {
      setAccessWallet(true);
      setUserNotOB(true);
    } else {
      setAccessWallet(false);
      setUserNotOB(false);
    }
  }, [user]);

  useEffect(() => {
    if (user?.['custom:profile']) {
      setAccess(true);
    }
  }, [user]);

  useEffect(() => {
    const pathname = location.pathname.replace('/', '');
    setCurrent(pathname ? pathname : 'home');
  }, [location.pathname]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    if (successSignOut && !loadingSignOut) {
      dispatch(resetSignOut());
      navigate('/signin');
    }
  }, [successSignOut, loadingSignOut]);

  return {
    menuItems,
    theme,
    open,
    expanded,
    access,
    userNotOB,
    activeNavbar,
    accessWallet,
    copyText,
    current,
    anchorEl,
    anchorElDashboard,
    anchorElTransactions,
    openMenu,
    user,
    navigate,
    location,
    dispatch,
    successSignOut,
    querys,
    handleClick,
    handleClickDashboard,
    handleClickTransactions,
    handleClose,
    handleDrawerOpen,
    handleDrawerClose,
    handleAccordionChange,
    handleClickHome,
    handleNavigateDashboard,
    handleNavigateTransactions,
    handleNavigate,
    handleNavigateWallet,
  };
};
