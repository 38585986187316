import { Stack, Typography, styled } from '@mui/material';
import { ButtonsPrimary, ButtonsSecondary } from '../../../ComponentApp/buttoms';

const BodyNotification = styled(Stack)({
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignContent: 'space-between',
  padding: '0px 16px',
  boxSizing: 'border-box',
  height: '100%',
});

const ContentTextBody = styled(Stack)({
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignContent: 'space-between',
  boxSizing: 'border-box',
  height: '100%',
  margin: '0px',
});

const TextTitleDestacado = styled(Typography)(({ theme }) => ({
  textAlign: 'left',
  whiteSpace: 'pre-wrap',
  fontSynthesis: 'none',
  color: theme.palette.primary.dark,
  fontWeight: theme.typography.h4.fontWeight,
  letterSpacing: '0px',
  textDecoration: 'none',
  lineHeight: '111.00000143051147%',
  textTransform: 'none',
  alignSelf: 'stretch',
  margin: '0px',
}));
const DescriptionText = styled(Typography)(({ theme }) => ({
  textAlign: 'left',
  whiteSpace: 'pre-wrap',
  fontSynthesis: 'none',
  color: theme.palette.textLight.main,
  fontWeight: theme.typography.littleText.fontWeight,
  letterSpacing: '0px',
  textDecoration: 'none',
  textTransform: 'none',
  alignSelf: 'stretch',
  margin: '16px 0px 0px 0px',
}));

const SetTowBotton = styled(Stack)({
  display: 'flex',
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  padding: '16px 0px',
  boxSizing: 'border-box',
  alignSelf: 'stretch',
  gap: '16px',
  flexWrap: 'wrap',
});

export const TextBodyNotification = ({ title, textContent, btn1, btn2 }) => {
  return (
    <BodyNotification>
      <ContentTextBody>
        {title !== '' && (
          <TextTitleDestacado sx={{ fontSize: 'clamp(1.875rem, 0.0529rem + 3.4615vw, 2.8rem)' }}>
            {title}
          </TextTitleDestacado>
        )}
        <DescriptionText sx={{ fontSize: 'clamp(1.175rem, 0.7837rem + 0.3846vw, 2.2rem)' }}>
          {textContent}
        </DescriptionText>
      </ContentTextBody>
      <SetTowBotton>
        {btn2.text && <ButtonsSecondary name={btn2.text} onClick={btn2.handleSubmitReport} />}
        <ButtonsPrimary name={btn1.text} onClick={btn1.refresh} />
      </SetTowBotton>
    </BodyNotification>
  );
};
