import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import {
  Box,
  FormControl,
  FormControlLabel,
  InputAdornment,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { numberWithPoint } from '../../../utils/utils';

const SelectRescueItemFund = ({
  rescueType,
  radiobtnChange,
  investmentSelected,
  amount2,
  addamount2,
  mountEqual,
  cuotas,
}) => {
  const theme = useTheme();
  return (
    <form style={{ width: '100%' }}>
      <FormControl sx={{ width: '100%' }} variant='standard'>
        <RadioGroup
          sx={{
            color: theme.palette.textLight.main,
            '& .MuiSvgIcon-root': {
              color: theme.palette.primary.main,
            },
          }}
          aria-labelledby='demo-error-radios'
          name='quiz'
          value={rescueType}
          onChange={radiobtnChange}>
          <FormControlLabel
            sx={{ color: theme.palette.textLight.main }}
            value='Rescatartodo'
            label='Rescatar toda la inversión'
            control={<Radio />}
          />
          <FormControlLabel
            sx={{ color: theme.palette.textLight.main }}
            value='Rescatarsólounaparte'
            control={
              <Radio
                disabled={Number.parseFloat(investmentSelected.marketValue).toFixed(0) <= 10000}
                sx={{
                  '&, &.Mui-checked': {
                    color: theme.palette.primary.main,
                  },
                }}
              />
            }
            label='Rescate parcial'
          />
        </RadioGroup>

        <TextField
          sx={{ marginTop: 3 }}
          InputProps={{
            startAdornment: <InputAdornment position='start'>$</InputAdornment>,
          }}
          hiddenLabel
          id='filled-hidden-label-normal'
          variant='filled'
          value={
            rescueType === 'Rescatarsólounaparte'
              ? amount2
              : numberWithPoint(Number.parseFloat(investmentSelected.marketValue).toFixed(0))
          }
          error={
            (Number(amount2) * 1000 < 10000 && amount2 !== '') ||
            Number(amount2.replace(/\./g, '')) > Number(investmentSelected.marketValue)
          }
          helperText={
            Number(amount2) * 1000 < 10000 && amount2 !== ''
              ? 'El monto debe ser mayor a 10.000'
              : Number(amount2.replace(/\./g, '')) > Number(investmentSelected.marketValue)
                ? 'El valor ingresado supera el monto disponible'
                : null
          }
          onChange={addamount2}
          disabled={
            rescueType === 'Rescatartodo'
              ? numberWithPoint(Number.parseFloat(investmentSelected.marketValue).toFixed(0))
              : null
          }
        />
      </FormControl>

      <Stack>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'wrap',
            color: theme.palette.secondary.text,
            marginTop: '15px',
          }}>
          <AccountBalanceWalletIcon />
          <Typography
            component={'span'}
            sx={{
              ml: '4px',
              fontSize: theme.typography.customButton.fontSize,
              fontWeight: theme.typography.titleText.fontWeight,
            }}>
            Equivalente a{' '}
            {mountEqual
              ? numberWithPoint(
                  (Math.round(Number(investmentSelected.unitBalance) * 10000) / 10000).toFixed(4),
                )
              : typeof cuotas === 'number'
                ? numberWithPoint((Math.round(Number(cuotas) * 10000) / 10000).toFixed(4))
                : cuotas}{' '}
            cuotas
          </Typography>
        </Box>
      </Stack>
    </form>
  );
};

export default SelectRescueItemFund;
